import React from "react";
import { Typography } from "@mui/material";
import { Button, TextButton, Dialog } from "ui/components";

const ConfirmationDialog = ({ open, onClose, onConfirm, domainName }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      title="Delete domain"
      actionButtons={
        <>
          <TextButton underline onClick={onClose} autoFocus>
            <Typography sx={{ fontSize: "14px", marginLeft: "5px" }}>
              No, I&apos;m not sure
            </Typography>
          </TextButton>
          <Button size="small" onClick={onConfirm}>
            Yes, delete my domain
          </Button>
        </>
      }
    >
      <Typography gutterBottom>
        You are about to delete your sending domain:
        <Typography>{domainName}</Typography>
      </Typography>
      <Typography sx={{ fontWeight: "bold", marginTop: "1rem" }}>Are you sure?</Typography>
    </Dialog>
  );
};

export default ConfirmationDialog;
