import { setSelectedFileTextContent } from "core/store/entities/ImportFlow/importFlow.actions";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useFileReader = () => {
  const dispatch = useDispatch();
  const [readingProgress, setReadingProgress] = useState(100);
  const [readingError, setReadingError] = useState();

  const readFile = (file: File) => {
    setReadingProgress(0);
    let reader = new FileReader();

    setReadingProgress(0);
    reader.readAsText(file);

    reader.onloadend = function () {
      if (typeof reader.result !== "string") return;
      dispatch(setSelectedFileTextContent(reader.result));
    }

    reader.onprogress = function (e) {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        setReadingProgress(progress);
      }
    };

    reader.onerror = function () {
      setReadingError(reader.error);
    };
  }

  return {
    readFile,
    readingError,
    readingProgress,
  }
}