import { GridSortDirection } from "@mui/x-data-grid";
import { urlParamChange } from "helpers/urlParamChange";

type UrlParam = "search" | "page" | "status" | "ordering";

const urlParamsHandlers = (urlParams: URLSearchParams, paramName: UrlParam) => ({
  paramValue: urlParams.get(paramName),
  onParamChange: urlParamChange(paramName)
});
export const useUrlParams = (param: UrlParam) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParamsHandlers(urlParams, param);
};

export const usePageUrlParam = () => {
  const { paramValue, onParamChange } = useUrlParams("page");
  return { page: paramValue, onPageChange: onParamChange };
};
export const useSearchUrlParam = () => {
  const { paramValue, onParamChange } = useUrlParams("search");
  const { onParamChange: onPageChange } = useUrlParams("page");

  const handleSearchChange = (value?: string | null) => {
    onParamChange(value);
    onPageChange();
  }
  return { searchTerm: paramValue, onSearchChange: handleSearchChange };
};
export const useOrderingUrlParam = () => {
  const { paramValue, onParamChange } = useUrlParams("ordering");
  const { onParamChange: onPageChange } = useUrlParams("page");

  const handleOrderingChange = (value?: string | null) => {
    onParamChange(value);
    onPageChange();
  }
  return { ordering: paramValue as GridSortDirection, onOrderingChange: handleOrderingChange };
};
export const useStatusUrlParam = () => {
  const { paramValue, onParamChange } = useUrlParams("status");
  const { onParamChange: onPageChange } = useUrlParams("page");

  const handleStatusChange = (value?: string | null) => {
    onParamChange(value);
    onPageChange();
  }
  return { status: paramValue, onStatusChange: handleStatusChange };
};
