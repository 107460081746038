import { Grid } from "@mui/material";
import { CampaignsCount } from "../CampaignsCount";
import { CampaignsPagination } from "../CampaignsPagination";
import { CampaignsSearchInput } from "../CampaignsSearchInput";
import { CampaignsTable } from "../CampaignsTable";
import { generalContentPadding } from "ui/components/Layout";
import { FilteredByStatusButton } from "../FilteredByStatusButton";
import { ErrorMessage } from "../ErrorMessage";

export const CampaignsDataDisplay = () => {
  return (
    <>
      <ErrorMessage />
      <Grid container rowSpacing="2.5rem" sx={{ padding: generalContentPadding }}>
        <Grid item xs={12}>
          <CampaignsCount />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={11}>
            <CampaignsSearchInput />
          </Grid>
          <Grid item xs={1}>
            <FilteredByStatusButton />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CampaignsTable />
        </Grid>
        <Grid item xs={12}>
          <CampaignsPagination />
        </Grid>
      </Grid>
    </>
  );
};
