import { Dialog, Input } from "ui/components";
import { Alert, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useEditPersonalInfo } from "./useEditPersonalInfo";
import { ActionButtons } from "../../../../components/Modals/ActionButtons";

export const EditPersonalInfoModal = () => {
  const { control, onSubmit, onClose, open, errors } = useEditPersonalInfo();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      paperStyles={{ width: "476px" }}
      title="Edit account information"
      actionButtons={<ActionButtons onCancel={onClose} isConfirmSubmit onConfirm={onSubmit} />}
    >
      <Grid container component="form" onSubmit={onSubmit} spacing={2.5}>
        {!!errors?.nonFieldErrors && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="error">
              {errors?.nonFieldErrors}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={6} sx={{ mt: 0.75 }}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                autoFocus
                fullWidth
                id="firstName"
                label="First name"
                name="firstName"
                error={!!errors?.firstName?.message}
                helperText={errors?.firstName?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: { xs: 0, sm: 0.75 } }}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                id="lastName"
                label="Last name"
                name="lastName"
                error={!!errors?.lastName?.message}
                helperText={errors?.lastName?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                id="company"
                label="Company name"
                name="company"
                error={!!errors?.company?.message}
                helperText={errors?.company?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
