import {
  HiddenInputLabel,
  StyledFormHelperText,
  StyledMenuItem,
  StyledSelect,
  IconContainer
} from "./styled";
import { FormControl, Typography } from "@mui/material";
import { Divider } from "ui/components";

export const Select = ({
  options = [],
  value,
  label,
  error,
  helperText,
  fullWidth,
  children,
  showEmptyOption = false,
  defaultValue,
  ...props
}) => {
  const selectedValue = showEmptyOption
    ? (value ?? defaultValue) || "empty_option"
    : value ?? defaultValue;

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <HiddenInputLabel htmlFor="select">{label}</HiddenInputLabel>
      <StyledSelect {...props} id="select" value={selectedValue} error={!!error}>
        {showEmptyOption && (
          <StyledMenuItem value="empty_option">
            <Typography variant="inherit" color={theme => theme.palette.gray[250]}>
              {label}...
            </Typography>
          </StyledMenuItem>
        )}
        {children ||
          options.flatMap((option, index) => {
            return [option.divider && <Divider />, (
              <StyledMenuItem
                {...option}
                onClick={option.onClick}
                key={index}
                value={option.value}
                disabled={option.disabled}
                divider={false} // divider is above
              >
                <IconContainer>{option.icon}</IconContainer>
                {option.label}
              </StyledMenuItem>

            )];
          }).filter(Boolean)}
      </StyledSelect>
      {error && helperText && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
    </FormControl>
  );
};
