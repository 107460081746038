import { getRequest, postRequest } from "core/api/base";

export class DomainService {
  static get = () => getRequest({ url: "/rest/domains" });
  static remove = data => postRequest({ url: `/rest/remove_domain`, data });
  static add = data => postRequest({ url: "/rest/add_domain", data });
  static getDnsRecords = id => getRequest({ url: `/rest/domain/${id}/` });
  static getVmtaPools = () => getRequest({ url: "/rest/vmtapools" });
  static verify = data => postRequest({ url: `/rest/validate_domain`, data });
}
