import { Grid, Stack } from "@mui/material";
import { formatPercentage } from "helpers/percentage";
import React from "react";
import { Divider, Indicator } from "ui/components";
import { BorderedBlock } from "../BorderedBlock";

export const OverallBlock = ({
  totalSent,
  totalSentDiff,
  percentDelivered,
  percentDeliveredDiff,
  percentBounced,
  totalBouncedDiff
}) => {
  return (
    <BorderedBlock>
      <Grid container gap={{ xs: 2, lg: 0 }} alignItems="center">
        <Grid item xs={12} md={4}>
          <Indicator value={totalSent} label="Deliveries" diff={totalSentDiff} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={{ xs: 0, lg: 4 }} direction="row">
            <Divider
              flexItem
              orientation="vertical"
              sx={{ display: { xs: "none", lg: "block" } }}
            />
            <Indicator
              value={`${formatPercentage(percentDelivered)}%`}
              label="Delivery rate"
              diff={percentDeliveredDiff}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={{ xs: 0, lg: 4 }} direction="row">
            <Divider
              flexItem
              orientation="vertical"
              sx={{ display: { xs: "none", lg: "block" } }}
            />
            <Indicator
              inverted
              value={`${formatPercentage(percentBounced)}%`}
              label="Bounce rate"
              diff={totalBouncedDiff}
            />
          </Stack>
        </Grid>
      </Grid>
    </BorderedBlock>
  );
};
