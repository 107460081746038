import { Box, Drawer as MuiDrawer, IconButton, Stack, Typography } from "@mui/material";
import { CONTACT_FORM_MODAL, SHOW_LIST_FORM } from "core/constants";
import { Close } from "@mui/icons-material";
import { Button, RegularButton } from "ui/components";
import { useSelector, useDispatch } from "react-redux";
import { hideFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";
import { StyledFooter, StyledWrapper } from "./styled";
import { ReactNode } from "react";

type Props = {
  title: string;
  modalKey: string;
  children: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
};

export const Drawer = ({ title, modalKey, children, footer, onClose }: Props) => {
  const isExpanded: boolean = useSelector(modalSelectors[modalKey]);
  return (
    <MuiDrawer anchor="right" open={isExpanded} onClose={onClose}>
      <StyledWrapper>
        <IconButton
          aria-label="Close side modal"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10
          }}
        >
          <Close sx={{ fontSize: 18 }} />
        </IconButton>
        <Typography variant="h2" sx={{ p: 5, pb: 3 }}>
          {title}
        </Typography>
        <Box sx={{ flex: 1, overflowY: "scroll" }}>{children}</Box>
      </StyledWrapper>
      {footer && <StyledFooter>{footer}</StyledFooter>}
    </MuiDrawer>
  );
};
