import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: "100%",
  height: 8,
  borderRadius: "0.125rem",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D6D6D6"
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "black"
  }
}));
