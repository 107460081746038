import React from "react";

import { InfoBlock, Spinner } from "ui/components";
import { Grid, Link, Typography } from "@mui/material";
import { useConnectAPI } from "./useConnectAPI";

export const ConnectAPI = () => {
  const { webInfo, isWebInfoLoading, footer } = useConnectAPI();

  if (isWebInfoLoading || !webInfo) return <Spinner />;

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} lg={8} xl={8}>
          <Typography variant="bodyLBolder">Connect with the Web API to send emails</Typography>
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <InfoBlock item={webInfo} footerContent={footer} />
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <Link
            href="https://inboxroad.com/documentation/api-reference/web-api/"
            target="_blank"
            sx={{
              fontFamily: "Geist"
            }}
          >
            Link to the docs about how to setup the inboxroad API
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
