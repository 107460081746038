import { Alert, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { Input, ShowPasswordIcon } from "ui/components";
import React from "react";
import { useChangePasswordForm } from "./useChangePasswordForm";
import { ActionButtons } from "../../Modals/ActionButtons";

export const ChangePasswordForm = ({ onClose }) => {
  const {
    control,
    errors,
    onSubmit,
    showConfirmPassword,
    showNewPassword,
    toggleShowConfirmPassword,
    toggleShowNewPassword
  } = useChangePasswordForm();

  return (
    <Grid container justifyContent="center" rowSpacing={2.5} component="form">
      {!!errors?.nonFieldErrors && (
        <Grid item xs={12}>
          <Alert variant="filled" severity="error">
            {errors?.nonFieldErrors}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mt: 0.75 }}>
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <Input
              autoFocus
              fullWidth
              id="newPassword"
              label="New password"
              name="newPassword"
              error={!!errors?.newPassword?.message}
              helperText={errors?.newPassword?.message}
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <ShowPasswordIcon show={showNewPassword} toggle={toggleShowNewPassword} />
                )
              }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Input
              fullWidth
              id="confirmPassword"
              label="Confirm new password"
              name="confirmPassword"
              error={!!errors?.confirmPassword?.message}
              helperText={errors?.confirmPassword?.message}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <ShowPasswordIcon show={showConfirmPassword} toggle={toggleShowConfirmPassword} />
                )
              }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ActionButtons onCancel={onClose} onConfirm={onSubmit} confirmTitle="Change password" />
      </Grid>
    </Grid>
  );
};
