import { Box, Tabs } from "@mui/material";
import { Tab } from "ui/components";
import { useLocation, useNavigate } from "react-router-dom";
import { useTabsItems } from "./useTabsItems";

export const TabNavigation = () => {
  const { items: settingsItems, getIsTabDisabled } = useTabsItems();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tabValue = settingsItems.find(tab => pathname.startsWith(tab.to))?.to || '';

  const handleChange = (_, newValue) => {
    navigate(newValue);
  };


  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        allowScrollButtonsMobile
        onChange={handleChange}
        value={tabValue}
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: "black",
            height: "0.25rem"
          }
        }}
      >
        {settingsItems.map(({ id, name, to: href }) => (
          <Tab label={name} key={id} value={href} disabled={getIsTabDisabled(href)} disableRipple />
        ))}
      </Tabs>
    </Box>
  );
};
