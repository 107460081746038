import { Menu } from "../Menu";
import { MoreIcon, type MoreIconProps } from "../../Icons/MoreIcon";
import { type MouseEvent, useState } from "react";
import type { MenuItem } from "core/types";

type Props<MenuItemType extends string> = MoreIconProps & {
  menuFor: {
    id: string;
    name: string;
  };
  items: MenuItem<MenuItemType>[];
};

export const MoreMenu = <MenuItemType extends string>({
  menuFor,
  items,
  ...props
}: Props<MenuItemType>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MoreIcon {...props} id="more-icon" onClick={handleClick} active={open} />
      <Menu items={items} open={open} onClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};
