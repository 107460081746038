import { campaignsActions } from "./campaigns.slice";

export const {
  fetchCampaigns,
  fetchCampaignsSuccess,
  fetchCampaignsError,

  setCurrentPage,
  setSearchTerm,

  duplicateCampaign,
  duplicateCampaignSuccess,
  duplicateCampaignError,

  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignError,
} = campaignsActions;
