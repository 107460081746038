import { listActions } from "./list.slice";

export const {
  addNewContactsField,
  addNewContactsFieldSuccess,
  addNewContactsFieldFailed,

  editContactsField,
  editContactsFieldSuccess,
  editContactsFieldFailed,

  removeContactsField,
  removeContactsFieldSuccess,
  removeContactsFieldFailed,

  createList,
  createListFailed,
  createListSuccess,

  fetchList,
  fetchListSuccess,
  fetchListFailed,

  resetList,

  updateList,
  updateListSuccess,
  updateListFailed,
} = listActions;
