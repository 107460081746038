import { MoreMenu } from "ui/components/Menus/MoreMenu";
import { type ListActionsItem } from "../useListActionsItems";

type Props = {
  actionItems: ListActionsItem[];
  menuFor: {
    id: string;
    name: string;
  };
};

export const ListActionsMobile = ({ actionItems, menuFor }: Props) => {
  return (
    <MoreMenu
      aria-label={`View more actions for ${menuFor.name}`}
      items={actionItems}
      sx={{
        p: 0,
        display: { xs: "flex", lg: "none" }
      }}
      menuFor={menuFor}
    />
  );
};
