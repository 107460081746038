import { createContact, updateContact } from "core/store/entities/Contact/contact.actions";
import { modalFormSelectors } from "core/store/entities/ModalForm/modalForm.selectors";
import dayjs from "dayjs";
import { useContactFields } from "hooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

export const useContactForm = () => {
  const contactId = useSelector(modalFormSelectors.entityId);
  const { isDateType, contactEditableFields, defaultValues, contactFieldsError } =
    useContactFields(contactId);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (Array.isArray(contactFieldsError?.detail)) {
      contactFieldsError.detail.forEach((err) => {
        const [fieldName] = err.loc;
        setError(fieldName, { type: "custom", message: err.msg });
      })
    }
  }, [contactFieldsError?.detail])

  const onSubmit = (values: any) => {
    const data = Object.fromEntries(
      Object.entries(values).map(([fieldName, value]) => [
        fieldName,
        isDateType(fieldName) && fieldName !== "timestamp"
          ? dayjs(value as string | number | Date).format("YYYY-MM-DD")
          : value
      ])
    );

    dispatch(contactId ? updateContact({ id: contactId, data }) : createContact(data));
  };
  const onReset = () => {
    reset(defaultValues);
  };

  return {
    control,
    errors,
    onSubmit: handleSubmit(onSubmit),
    onReset,
    editableFields: contactEditableFields,
    fieldsError: contactFieldsError,
    defaultValues
  };
};
