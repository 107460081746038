import { MenuItem, styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.gray[350],
  borderRadius: theme.remScale[6],
  padding: theme.remScale[10],
  cursor: "pointer",
  "& > *": {
    marginRight: "10px"
  },
  "&:hover": {
    color: theme.palette.black,
    backgroundColor: "#00000010"
  }
}));
