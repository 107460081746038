import { UnfoldMore } from "@mui/icons-material";
import { Spinner, Menu, MenuButton } from "../../.";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { useProfileMenu } from "./useProfileMenu";
import { useStatisticsDetails } from "ui/screens/StatisticsDetails/useStatisticsDetails";
import { ProfileLetter, ProfileLetterWrapper } from "ui/components/Buttons/MenuButton/styled";

export const ProfileMenu = ({ compressed = false }) => {
  const { handleFetchDomainList } = useStatisticsDetails();
  const {
    profileName,
    open,
    menu,
    menuItems,
    menuButtonId,
    menuId,
    handleOpenMenu,
    handleCloseMenu
  } = useProfileMenu({ handleFetchDomainList });
  return (
    <>
      <MenuButton
        sx={{
          alignSelf: "baseline",
          color: "#686764",
          ...(compressed && { padding: 0, minWidth: "1.76rem" })
        }}
        id={menuButtonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleOpenMenu}
        endIcon={compressed ? null : <UnfoldMore sx={{ fontSize: "1.125rem" }} />}
      >
        {profileName ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <ProfileLetterWrapper>
              <ProfileLetter>{profileName[0].toUpperCase()}</ProfileLetter>
            </ProfileLetterWrapper>
            {!compressed && <Typography fontSize="1.085rem">{profileName}</Typography>}
          </Stack>
        ) : (
          <Spinner />
        )}
      </MenuButton>
      <Menu
        id={menuId}
        MenuListProps={{
          "aria-labelledby": menuButtonId
        }}
        anchorEl={menu}
        open={open}
        onClose={handleCloseMenu}
        items={menuItems}
      />
    </>
  );
};
