import { CloseIcon } from "ui/components";
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle
} from "./styled";
import { Stack, Typography } from "@mui/material";

export const Dialog = ({ children, title, subtitle, open, onClose, actionButtons, ...props }) => {
  if (!open) return null;

  return (
    <StyledDialog open={open} onClose={onClose} {...props}>
      <Stack>
        <StyledDialogTitle component={Stack} gap={1}>
          <Typography variant="h3" component="h2">
            {title}
          </Typography>
          <CloseIcon onClose={onClose} />
          {subtitle && (
            <Typography variant="body2" fontWeight={360}>
              {subtitle}
            </Typography>
          )}
        </StyledDialogTitle>
      </Stack>
      <StyledDialogContent>{children}</StyledDialogContent>
      {actionButtons && <StyledDialogActions>{actionButtons}</StyledDialogActions>}
    </StyledDialog>
  );
};
