import { useLocation } from "react-router-dom";
import { Slide, Typography } from "@mui/material";
import { useEffect } from "react";
import { FileBrowseUploader } from "./components/FileBrowseUploader";
import { FullscreenSpinner } from "ui/components";
import {
  StyledButtonsWrapper,
  StyledContainer,
  StyledStackWrapper,
  StyledTextButton,
  StyledWrapper
} from "./styled";
import { OpenInNew } from "@mui/icons-material";
import { useListRefetch } from "hooks";
import { ContinueButton } from "./components/ContinueButton";
import { useDispatch } from "react-redux";
import { setSelectedFile } from "core/store/entities/ImportFlow/importFlow.actions";
import { DownloadExampleButton } from "./components/DownloadExampleButton";
import { ReplaceContactsCheckbox } from "./components/ReplaceContactsCheckbox";

const importTutorialUrl = "https://www.inboxroad.com/import-contacts/";

export const ContactsFileUpload = () => {
  const dispatch = useDispatch();
  const { error, isLoading } = useListRefetch();
  const location = useLocation();
  const direction = location?.state?.routedFromList ? "left" : "right";

  // clear the location state and clear file
  useEffect(() => {
    window.history.replaceState({}, "");
    dispatch(setSelectedFile(null));
  }, []);

  if (error) {
    return <>Error occurred</>;
  }

  if (isLoading) {
    return <FullscreenSpinner />;
  }

  return (
    <StyledContainer>
      <Slide direction={direction} in timeout={300}>
        <StyledWrapper>
          <StyledStackWrapper alignItems="center" gap={3}>
            <Typography variant="h1">Import new contacts </Typography>
            <Typography
              variant="body2"
              sx={{ pb: 4, color: theme => theme.palette.gray[600] }}
              fontWeight={360}
            >
              Upload a CSV file with all your contacts and their information.
            </Typography>
            <FileBrowseUploader />
            <StyledButtonsWrapper>
              <ReplaceContactsCheckbox />
              <ContinueButton />
            </StyledButtonsWrapper>
            <StyledButtonsWrapper>
              <DownloadExampleButton />
              <StyledTextButton component="a" startIcon={<OpenInNew />} href={importTutorialUrl}>
                Learn how to import contacts
              </StyledTextButton>
            </StyledButtonsWrapper>
          </StyledStackWrapper>
        </StyledWrapper>
      </Slide>
    </StyledContainer>
  );
};

export default ContactsFileUpload;
