import { inputAdornmentClasses, outlinedInputClasses, styled } from "@mui/material";
import { Input } from "../Input";

export const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.white[100],
  "&:has(input:-internal-autofill-selected)": {
    backgroundColor: "rgb(232, 240, 254)",
  },
  borderRadius: theme.remScale[6],

  [`& .${inputAdornmentClasses.root}`]: {
    color: theme.palette.gray[350]
  },
  [`.${outlinedInputClasses.input}`]: {
    paddingBlock: theme.remScale[10],
    paddingRight: theme.remScale[14],
    "&::placeholder": {
      color: theme.palette.gray[350],
      opacity: 1,
      fontWeight: 400
    }
  },
  [`& .${outlinedInputClasses.notchedOutline}:not(.Mui-focused)`]: {
    borderWidth: 0
  },
  [`& .${outlinedInputClasses.root}.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 1,
    borderColor: theme.palette.gray[250]
  }
}));
