import { contactReducers } from "./contact.reducers";

import { createSlice } from "@reduxjs/toolkit";
import { type Status } from "core/constants/status";

export type ContactState = {
  contact: object | null;
  createContactStatus: Status;
  updateContactStatus: Status;
  error: unknown;
};

const initialState: ContactState = {
  contact: null,
  createContactStatus: "idle",
  updateContactStatus: "idle",
  error: undefined
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: contactReducers
});

const contactActions = contactSlice.actions;
const contactReducer = contactSlice.reducer;

export { contactActions, contactReducer };