import { deleteRequest, postRequest } from "core/api/base";
import { PROFILE_TOKEN } from "core/constants";

const campaignsUrl = "/mailsystem/api/v1/campaigns/";

export class CampaignService {
  private url: string;
  constructor(campaignId: string) {
    this.url = `${campaignsUrl}${campaignId}`;
  }
  duplicateCampaign = () => {
    return postRequest({
      url: this.url + "/duplicate/",
      token: PROFILE_TOKEN,
    });
  };
  deleteCampaign = () => {
    return deleteRequest({
      url: this.url + "/delete/",
      token: PROFILE_TOKEN,
    });
  };
}
