import { type RootState } from "core/store/reducers";
import { listSelectors } from "../List/list.selectors";
import { createSelector } from "reselect";

const selectSelf = (state: RootState) => state.modalForm;
const selectEntityId = createSelector(selectSelf, modalForm => modalForm.entityId);

export const modalFormSelectors = {
  entityId: (state: RootState) => state.modalForm.entityId,
  entityType: createSelector(selectSelf, modalForm => modalForm.entityType),
  listFetched: createSelector(
    selectEntityId,
    listSelectors.listId,
    (entityId, listId) => entityId === listId
  ),
  selectedContactField: createSelector(selectEntityId, listSelectors.listFields, (id, fields) => fields?.find(field => field.id === id))
};
