import { Box, Container, Grid, styled } from "@mui/material";
import { Button } from "ui/components";

export const Wrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  maxHeight: "100%",
  height: "100%",
  backgroundColor: theme.palette.gray[75],
  overflowY: "auto"
}));

export const StyledContainer = styled(Container)(({ theme }) => ({
  paddingBlock: theme.remScale[112],
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingBlock: theme.remScale[80]
  }
}));

export const StyledContinueButtonWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingTop: theme.remScale[16]
}));

export const StyledChevronButton = styled(Button)(() => ({
  width: "min-content",
  minWidth: "min-content",
  aspectRatio: "1 / 1"
}));

export const StyledCenteredGrid = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));
