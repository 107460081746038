import { modalFormReducers } from "./modalForm.reducers";

import { createSlice } from "@reduxjs/toolkit";

export type ModalFormEntity = "list" | "contact" | "customColumn";

export type ModalFormState = {
  entityId: string | null;
  entityType: ModalFormEntity | null;
};

const initialState: ModalFormState = {
  entityId: null,
  entityType: null
};

const modalFormSlice = createSlice({
  name: "modalForm",
  initialState,
  reducers: modalFormReducers
});

const modalFormActions = modalFormSlice.actions;
const modalFormReducer = modalFormSlice.reducer;

export { modalFormActions, modalFormReducer };