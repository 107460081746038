import React from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Outlet, Routes, Route } from "react-router-dom";

import { history } from "core/store";

import { getPrivateRoutes, publicRoutes, unauthorizedRoutes } from "../routes";
import { UnauthorizedOnlyRoute, PrivateRoute } from "../components";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../core/store/entities/User/user.selectors";

const renderRoutes = routes => {
  return routes.map((route, index) => (
    <Route key={index} {...route}>
      {route?.routes?.map(route => {
        if (route.routes) {
          return (
            <Route key={index} {...route}>
              {renderRoutes(route.routes)}
            </Route>
          );
        }
        return <Route key={index} {...route} />;
      })}
    </Route>
  ));
};

export const RouterConfig = () => {
  const isEmailUser = useSelector(userSelectors.isEmailUser)
  return (
    <Router history={history}>
      <Routes>
        <Route element={<PrivateRoute />}>{renderRoutes(getPrivateRoutes(isEmailUser))}</Route>
        <Route element={<Outlet />}>{renderRoutes(publicRoutes)}</Route>
        <Route element={<UnauthorizedOnlyRoute />}>{renderRoutes(unauthorizedRoutes)}</Route>
      </Routes>
    </Router>
  );
};
