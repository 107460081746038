import { SHOW_LIST_FORM } from "core/constants";
import { ListForm } from "ui/components";
import { useSelector } from "react-redux";
import { modalFormSelectors } from "core/store/entities/ModalForm/modalForm.selectors";
import { SideFormModal } from "../SideFormModal";

export const ListFormModal = () => {
  const listId = useSelector(modalFormSelectors.entityId);

  return (
    <SideFormModal
      modalKey={SHOW_LIST_FORM}
      title={listId ? "Edit list" : "Create a new list"}
      form={{
        formName: "list-form",
        component: <ListForm />
      }}
    />
  );
};
