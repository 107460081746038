import { ButtonProps } from "@mui/material";
import { Menu, Button } from "ui/components";
import { MenuItem } from "core/types";
import { useState, MouseEvent, ReactNode } from "react";

export const ButtonWithMenu = <MenuItemAction extends string>({
  ButtonNode = Button,
  menuItems,
  children,
  ...props
}: {
  ButtonNode?: React.ComponentType<ButtonProps>;
  menuItems: Array<MenuItem<MenuItemAction>>;
  children: ReactNode;
} & ButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ButtonNode {...props} onClick={handleClick}>
        {children}
      </ButtonNode>
      <Menu open={open} items={menuItems} onClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};
