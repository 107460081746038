import { useDispatch, useSelector } from "react-redux";
import { Download } from "@mui/icons-material";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { TextButton } from "ui/components";
import { showModal } from "core/store/entities/Modal/modal.actions";
import { DOWNLOAD_CONTACTS_MODAL } from "core/constants";

export const ExportButton = () => {
  const dispatch = useDispatch();

  const contactsTotalCount = useSelector(contactsSelectors.totalCount);

  const handleExport = () => {
    dispatch(showModal(DOWNLOAD_CONTACTS_MODAL));
  };
  return (
    <TextButton
      startIcon={<Download style={{ fontSize: "0.75rem" }} />}
      onClick={handleExport}
      aria-label="Export contacts"
      disabled={contactsTotalCount === 0}
    >
      Export
    </TextButton>
  );
};
