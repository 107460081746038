import { Grid, Slide, Stack, Typography } from "@mui/material";
import { Button, Image } from "ui/components";
import {
  StyledCenteredGrid,
  StyledChevronButton,
  StyledContainer,
  StyledContinueButtonWrapper,
  Wrapper
} from "./styled";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { transformCsvFileFromText } from "helpers/transformCsvFileFromText";
import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { CsvTableData } from "core/types";
import { useScrollTable } from "hooks/useScrollTable";
import { CustomColumnFormModal } from "ui/components/Modals/CustomColumnFormModal";
import { CustomColumnsTableWrapper } from "./components/CustomColumnsTableWrapper";
import { resetColumnsFieldsMaps } from "core/store/entities/ImportFlow/importFlow.actions";
import { AddedFieldsCount } from "./components/AddedFieldsCount";
import { DuplicateMappedColumnsError } from "./components/DuplicateMappedColumnsError";
import { ContinueButton } from "./components/ContinueButton";

export const ContactsFieldMapping = () => {
  const dispatch = useDispatch();
  const { tableRef, scrollLeft, scrollRight } = useScrollTable();
  const { pathname } = useLocation();
  const fileTextContent = useSelector(importFlowSelectors.selectedFileTextContent);
  const [csvJs, setCsvJs] = useState<CsvTableData>({
    headers: [],
    rows: []
  });
  const { headers, rows } = csvJs;

  useEffect(() => {
    const transformCsv = async () => {
      if (!!fileTextContent) {
        const res = await transformCsvFileFromText(fileTextContent);
        setCsvJs(res);
      }
    };
    transformCsv();
  }, [fileTextContent]);

  useEffect(() => {
    dispatch(resetColumnsFieldsMaps());
  }, []);

  if (!fileTextContent || fileTextContent.length === 0) {
    const prevPath = pathname.split("/");
    prevPath.pop();
    return <Navigate to={prevPath.join("/")} replace />;
  }

  return (
    <>
      <CustomColumnFormModal />
      <Slide in direction="left">
        <Wrapper>
          <StyledContainer>
            <Stack alignItems="center" sx={{ height: "100%" }} gap={3}>
              <Image name="csvFileIcon" width={40} height={40} />
              <Typography variant="h1">Add field names</Typography>
              <AddedFieldsCount />
              <DuplicateMappedColumnsError />
              <Grid container sx={{ flex: 1 }} spacing={2.5}>
                <StyledCenteredGrid item xs={1}>
                  <StyledChevronButton onClick={scrollLeft}>
                    <ChevronLeft />
                  </StyledChevronButton>
                </StyledCenteredGrid>
                {headers && (
                  <StyledCenteredGrid item xs={10}>
                    <CustomColumnsTableWrapper ref={tableRef} headerLabels={headers} rows={rows} />
                  </StyledCenteredGrid>
                )}
                <StyledCenteredGrid item xs={1}>
                  <StyledChevronButton onClick={scrollRight}>
                    <ChevronRight />
                  </StyledChevronButton>
                </StyledCenteredGrid>
                <Grid item xs={1} />
                <StyledContinueButtonWrapper item xs={10}>
                  <ContinueButton />
                </StyledContinueButtonWrapper>
                <Grid item xs={1} />
              </Grid>
            </Stack>
          </StyledContainer>
        </Wrapper>
      </Slide>
    </>
  );
};
