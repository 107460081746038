import { CONTACT_FORM_MODAL } from "core/constants";
import { SideFormModal } from "../SideFormModal";
import { ContactForm } from "ui/components/Forms";
import { useSelector } from "react-redux";
import { modalFormSelectors } from "core/store/entities/ModalForm/modalForm.selectors";

const formName = "contact-form";
export const ContactFormModal = () => {
  const contactId = useSelector(modalFormSelectors.entityId);

  return (
    <SideFormModal
      title={contactId ? "Edit contact" : "Add a contact"}
      modalKey={CONTACT_FORM_MODAL}
      form={{
        formName,
        component: <ContactForm contactId={contactId} formName={formName} />
      }}
    />
  );
};
