import { createSelector } from "reselect";
import { customMailSystems } from "core/constants";

const ENTITY = "user";

export const userSelectors = {
  createAccountStep: state => state[ENTITY].createAccountStep,
  email: state => state[ENTITY].email,
  mailingSystemsOptions: state => state[ENTITY].mailingSystemsOptions,
  isMailingSystemsLoading: state => state[ENTITY].isMailingSystemsLoading,
  profiles: state => state[ENTITY].profiles,
  user: state => state[ENTITY].user,
  userEmail: state => state[ENTITY].user.email,
  selectedProfile: state => state[ENTITY].selectedProfile,
  isLoginAsAdminLoading: state => state[ENTITY].isLoginAsAdminLoading,
  isLoginLoading: state => state[ENTITY].isLoginLoading,
  errorMessage: state => state[ENTITY].errorMessage,
  isAdminView: state => state[ENTITY].isAdminView,
  adminToken: state => state[ENTITY].adminToken,
  errors: state => state[ENTITY].errors,
  confirmEmailMessage: state => state[ENTITY].confirmEmailMessage,
  showFeedbackPopup: state => state[ENTITY].showFeedbackPopup,
  feedbackPopupContent: state => state[ENTITY].feedbackPopupContent,
  feedbackPopupOnConfirm: state => state[ENTITY].feedbackPopupOnConfirm,
  smtpInfo: state => state[ENTITY].smtpInfo,
  isSmtpInfoLoading: state => state[ENTITY].isSmtpInfoLoading,
  returnPaths: state => state[ENTITY].returnPaths,
  isReturnPathsLoading: state => state[ENTITY].isReturnPathsLoading,
  webInfo: state => state[ENTITY].webInfo,
  isFreePlanUser: state => state[ENTITY].user.plan.toLowerCase() === "free",
  isWebInfoLoading: state => state[ENTITY].isWebInfoLoading,
  isSignUpSubmitting: state => state[ENTITY].isSignUpSubmitting,
  // TEMP
  // show email user features when not in production
  isEmailUser: () => !(process.env.NODE_ENV === "production" && process.env.REACT_APP_STAGE === "production")
};
