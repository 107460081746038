import { DataGridProps } from "@mui/x-data-grid";
import { StyledDataGrid } from "./styled";
import { Checkbox } from "ui/components/Inputs/Checkbox";
import { KeyboardArrowDown, KeyboardArrowUp, UnfoldMore } from "@mui/icons-material";

type Props = DataGridProps;

export const DataGrid = (props: Props) => {
  return (
    <StyledDataGrid
      {...props}
      rowHeight={64}
      disableRowSelectionOnClick
      slots={{
        columnUnsortedIcon: UnfoldMore,
        columnSortedAscendingIcon: KeyboardArrowUp,
        columnSortedDescendingIcon: KeyboardArrowDown,
        columnFilteredIcon: () => null,
        baseCheckbox: props => <Checkbox {...props} sx={{ m: 0 }} />
      }}
    />
  );
};
