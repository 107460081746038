import { campaignsSelectors } from "core/store/entities/Campaigns/campaigns.selectors";
import { useSelector } from "react-redux";
import { Snackbar } from "ui/components";

export const ErrorMessage = () => {
  const error = useSelector(campaignsSelectors.error);

  if (error) {
    console.error(error);
    return <Snackbar severity="error" message="An error occurred while performing operation" />;
  }
  return null;
};
