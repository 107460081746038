import { type ReactNode, useCallback } from "react";
import { Pencil, TrashBin } from "ui/assets/icons";
import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from "@mui/icons-material";
import { ContactsTableAction, ContactStatus, MenuItem } from "core/types";
import { useDispatch, useSelector } from "react-redux";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { deleteContacts } from "core/store/entities/Contacts/contacts.actions";
import { updateContact } from "core/store/entities/Contact/contact.actions";
import { showFeedbackPopup } from "core/store/entities/User/user.actions";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { urlPageParamGoPrevious } from "helpers/urlPageParamGoPrevious";

const actionsNameMap: Record<ContactsTableAction, string> = {
  edit: "Edit",
  delete: "Delete",
  unsubscribe: "Unsubscribe",
  subscribe: "Subscribe"
};

const getAriaLabel = (labelFor: string, action: ContactsTableAction) => {
  return `${actionsNameMap[action]} ${labelFor}`;
};

const createMenuItem =
  (triggeredByName: string) =>
  (
    action: ContactsTableAction,
    onClick: () => void,
    icon?: ReactNode,
    divider?: boolean
  ): MenuItem<ContactsTableAction> => ({
    id: action,
    icon,
    name: actionsNameMap[action],
    onClick,
    "aria-label": getAriaLabel(triggeredByName, action),
    divider
  });

export const useContactsTableMenuItems = () => {
  const dispatch = useDispatch();
  const totalPages = useSelector(contactsSelectors.totalPages);
  const getMenuHandlers = (triggeredBy: { id: string; name: string }) => ({
    handleDelete: () => {
      dispatch(
        showFeedbackPopup({
          content: [
            `Delete ${triggeredBy.name}?`,
            `Are you sure you want to permanently delete ${triggeredBy.name}?`
          ],
          onConfirm: () => {
            dispatch(deleteContacts([triggeredBy.id]));
            urlPageParamGoPrevious(totalPages);
          }
        })
      );
    },
    handleUnsubscribe: () => {
      dispatch(updateContact({ id: triggeredBy.id, data: { status: "unsubscribed" } }));
    },
    handleSubscribe: () => {
      dispatch(updateContact({ id: triggeredBy.id, data: { status: "subscribed" } }));
    },
    handleEdit: () => {
      dispatch(showFormModal({ id: triggeredBy.id, type: "contact" }));
    }
  });

  const getItems = useCallback(
    (triggeredBy: { id: string; name: string; status: ContactStatus }) => {
      const menuItemGenerator = createMenuItem(triggeredBy.name);
      const menuHandlers = getMenuHandlers(triggeredBy);
      return [
        menuItemGenerator("edit", menuHandlers.handleEdit, <Pencil style={{ fontSize: "1rem" }} />),
        triggeredBy.status === "subscribed"
          ? menuItemGenerator(
              "unsubscribe",
              menuHandlers.handleUnsubscribe,
              <IndeterminateCheckBoxOutlined style={{ fontSize: "1rem" }} />
            )
          : menuItemGenerator(
              "subscribe",
              menuHandlers.handleSubscribe,
              <AddBoxOutlined style={{ fontSize: "1rem" }} />
            ),
        menuItemGenerator(
          "delete",
          menuHandlers.handleDelete,
          <TrashBin style={{ fontSize: "1rem" }} />,
          true
        )
      ];
    },
    []
  );
  return {
    getItems
  };
};
