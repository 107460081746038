import React from "react";
import { Grid } from "@mui/material";
import { AddDomain } from "./AddDomain";
import { SendingDomainsTable } from "./SendingDomainsTable";
import { Illustration } from "./Illustration";
import { BorderedBlock } from "ui/components";

export const SendingDomains = () => {
  return (
    <Grid container spacing="2.5rem">
      <Grid item xs={12}>
        <BorderedBlock>
          <Grid item container spacing="2.5rem">
            <Grid item xs={12} lg={6}>
              <AddDomain />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Illustration />
            </Grid>
          </Grid>
        </BorderedBlock>
      </Grid>
      <Grid item xs={12}>
        <BorderedBlock>
          <SendingDomainsTable />
        </BorderedBlock>
      </Grid>
    </Grid>
  );
};
