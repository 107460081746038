import { Stack, Typography } from "@mui/material";
import { ListLabel } from "../ListLabel";

import { SelectListFields } from "../SelectListFields";
import { useFormEmbedContext } from "../../context/FormEmbedContextProvider";
import { Controller } from "react-hook-form";
import { CopyButton } from "../CopyButton";

export const FieldsSelector = () => {
  const { control, selectableListFields } = useFormEmbedContext();

  return (
    <Stack justifyContent="flex-start" gap={7}>
      <ListLabel />
      <Stack
        gap={3}
        component="form"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Stack gap={1.5}>
          <Typography
            variant="h1"
            fontSize={theme => theme.remScale[38]}
            lineHeight={theme => theme.remScale[48]}
          >
            Embed form to your website
          </Typography>
          <Typography variant="body2" fontWeight={360} lineHeight={theme => theme.remScale[24]}>
            Embed this form to grow your list.
          </Typography>
        </Stack>
        <Controller
          name="selectedFields"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SelectListFields onChange={onChange} value={value} fields={selectableListFields} />
          )}
        />
        <CopyButton />
      </Stack>
    </Stack>
  );
};
