import { Select } from "ui/components";
import { type ControllerRenderProps } from "react-hook-form";
import { useSelector } from "react-redux";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";
import { VmtaPool } from "core/types";

type Props = ControllerRenderProps;

export const VmtaPoolSelect = ({ ...field }: Props) => {
  const vmtaPools: VmtaPool[] = useSelector(domainSelectors.vmtaPools);
  const options = (vmtaPools ?? []).map(({ id, name }) => ({ value: id, label: name }));

  if (!vmtaPools || vmtaPools?.length <= 1) return null;

  return (
    <Select
      {...field}
      value={field.value}
      fullWidth
      options={options}
      label="Select VMTA pool"
      showEmptyOption
    />
  );
};
