import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import React from "react";
import { Header } from "../../.";
import { TabNavigation } from "./TabNavigation";
import { generalContentPadding, generalHeaderPadding } from "../AppLayout";

export const SettingsLayout = () => {
  return (
    <Stack>
      <Header title="Settings" />
      <Box sx={{ padding: generalHeaderPadding }}>
        <TabNavigation />
      </Box>
      <Box
        sx={{
          width: "100%",
          maxHeight: "100vh",
          overflow: "auto",
          padding: generalContentPadding
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
};
