import { StyledMenu, StyledMenuItem } from "./styled";
import { Divider, Submenu } from "ui/components";
import { Stack } from "@mui/material";

const renderMenuItems = (items, onClick, onClose) => {
  return items
    .flatMap(menuItem =>
      menuItem.submenu
        ? [
          menuItem.divider && <Divider key={`${menuItem.id}-divider`} />,
          <Submenu key={menuItem.id} item={menuItem} onClose={onClose} />
        ]
        : [
          menuItem.divider && <Divider key={`${menuItem.id}-divider`} />,
          <StyledMenuItem
            {...menuItem}
            key={menuItem.id}
            onClick={onClick(menuItem.onClick)}
            disableRipple
            divider={false} // don't show divider here, it's already shown above
          >
            {menuItem.icon}
            {menuItem.name}
          </StyledMenuItem>
        ]
    )
    .filter(Boolean); // filter out false divider
};

export const Menu = ({ items, onClose, ...props }) => {
  const handleMenuItemClick = menuItemClick => () => {
    menuItemClick();
    onClose();
  };

  return (
    <StyledMenu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      onClose={onClose}
      {...props}
    >
      {renderMenuItems(items, handleMenuItemClick, onClose)}
    </StyledMenu>
  );
};
