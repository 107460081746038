import { campaignsSelectors, pageSize } from "core/store/entities/Campaigns/campaigns.selectors";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "ui/components";
import { useCampaignsTable } from "./useCampaignsTable";
import { useCallback } from "react";
import { GridSortModel } from "@mui/x-data-grid";
import { useOrderingUrlParam } from "hooks/useUrlParams";
import { fetchCampaigns } from "core/store/entities/Campaigns/campaigns.actions";

export const CampaignsTable = () => {
  const dispatch = useDispatch();
  const { rows, columns } = useCampaignsTable();
  const campaignsLoading = useSelector(campaignsSelectors.campaignsLoading);
  const { ordering, onOrderingChange } = useOrderingUrlParam();

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const order = sortModel[0]?.sort;
    onOrderingChange(order);
    dispatch(fetchCampaigns());
  }, []);

  return (
    <DataGrid
      loading={campaignsLoading}
      rows={rows}
      columns={columns}
      disableColumnMenu
      onSortModelChange={handleSortModelChange}
      sortModel={[
        {
          field: "edited",
          sort: ordering
        }
      ]}
      initialPaginationModel={{
        page: 1,
        pageSize
      }}
    />
  );
};
