import { SearchInput } from "ui/components";
import { useCampaignsSearchInput } from "./useCampaignsSearchInput";

export const CampaignsSearchInput = () => {
  const { searchTerm, onChange } = useCampaignsSearchInput();

  return (
    <SearchInput
      onSearch={onChange}
      value={searchTerm}
      fullWidth
      aria-label="Search campaigns by name"
      placeholder="Search campaigns by name"
    />
  );
};
