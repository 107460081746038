import { Button as MUIButton, styled } from "@mui/material";

export const StyledEditButton = styled(MUIButton)(({ theme }) => ({
  color: theme.palette.grey[800],
  textDecoration: "underline",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline"
  }
}));
