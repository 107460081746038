import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MUIDialog,
  dialogClasses,
  styled
} from "@mui/material";

export const StyledDialog = styled(MUIDialog)(({ paperStyles }) => ({
  [`& .${dialogClasses.paper}`]: {
    ...paperStyles
  }
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.remScale[24],
  fontSize: theme.remScale[18.75]
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  paddingInline: theme.remScale[24],
  paddingTop: 0
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.remScale[24],
  paddingTop: 0
}));
