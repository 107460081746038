import { ContactField } from "core/types";
import { classes, scriptId } from "./classes";
import { htmlInput } from "./htmlInputs";

const stylesheetUrl =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_STAGE === "production"
    ? "https://inboxroad.com/static/embed/styles.min.css"
    : "https://staging.inboxroad.com/static/embed/styles.min.css";
const scriptUrl =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_STAGE === "production"
    ? "https://inboxroad.com/static/embed/script.min.js"
    : "https://staging.inboxroad.com/static/embed/script.min.js";


function generateInputField(contactField: ContactField) {
  return `${htmlInput(contactField)}
<div class="${classes.message} ${classes.error}" > </div>
  `;
}

export function generateEmbedForm(contactFields: ContactField[], listId: string) {
  return `<div id="${classes.root}">
  <link rel="stylesheet" href="${stylesheetUrl}" />
  <style type="text/css">
      /* Add your own Inboxroad form style overrides in your site stylesheet or in this style block.
      We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      #${classes.wrapper} {
        background: #fff;
        font-family: Helvetica, Arial, sans-serif;
        max-width: 480px;
      }
    </style>
    <div id="${classes.wrapper}">
      <h2 class="${classes.header}">Newsletter sign up</h2>
      <form id="${classes.subscribeFormClass}" class="ibr-show">
      ${contactFields
      .map(contactField =>
        contactField.data_type === "boolean"
          ? ` <div class="${classes.fieldControl}">
          <div class="${classes.labelWrapper}">
            ${generateInputField(contactField)}
          </div>
      </div>`
          : ` <div class="${classes.fieldControl}">
        ${generateInputField(contactField)}
      </div>`
      ).join("")}
      <!--Real people should not fill this. Do not remove this to avoid a risk from bot sign-ups-->
      <div aria-hidden="true" style="position: absolute; left: -9999px; height: 1px">
        <input type="email" name="_email" tabindex="-1" value="" autocomplete="off" />
      </div>
      <button type="submit" class="${classes.submitButton}">Subscribe</button>
    </form>
    <div id="${classes.responseMessage}" class="message"></div>
  </div>
  <script src="${scriptUrl}" data-listId="${listId}" id="${scriptId}"></script>
</div>
`
}