import { Button, styled, buttonClasses, Box, Typography } from "@mui/material";

export const StyledMenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.black,
  borderRadius: theme.shape.borderRadius,
  padding: "10px 10px 10px 0",
  textTransform: "none",
  height: "32px",
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: 2
  }
}));

export const ProfileLetterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.white.main,
  backgroundColor: theme.palette.green[400],
  width: "1.76rem",
  height: "1.76rem",
  borderRadius: theme.shape.borderRadius,
  textAlign: "center"
}));
export const ProfileLetter = styled(Typography)(() => ({
  transform: "scale(2.5, 1.5)",
  textAlign: "center"
}));
