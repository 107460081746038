import { styled } from "@mui/material";
import { NavLink as NavLinkRR } from "react-router-dom";

export const StyledNavItem = styled(NavLinkRR)(({ isActive, isDisabled, hasSubmenu, theme }) => ({
  borderRadius: "0.375rem",
  color: "rgba(0,0,0, 50%)",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  paddingBlock: "0.68em",
  paddingInline: "0.54em",
  textDecoration: "none",
  fontWeight: 460,
  "&:hover": {
    color: theme.palette.black,
    backgroundColor: hasSubmenu && theme.palette.white[100],
  },
  "& svg": {
    color: theme.palette.gray[250]
  },
  ...(isDisabled && {
    opacity: "20%",
    pointerEvents: "none",
    userSelect: "none"
  }),
  ...(isActive && {
    backgroundColor: !hasSubmenu && theme.palette.white[100],
    color: theme.palette.black,
    "& svg": {
      color: theme.palette.black
    }
  })
}));

export const StyledSubNavItem = styled(NavLinkRR)(({ isActive, isDisabled, theme }) => ({
  width: "100%",
  borderRadius: "0.375rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textDecoration: "none",
  paddingBlock: "0.68em",
  paddingInline: "1.35em",
  color: "rgba(0,0,0, 50%)",
  "&:hover": {
    backgroundColor: "rgba(0,0,0, 5%)",
    color: theme.palette.black
  },
  ...(isActive && {
    color: theme.palette.black,
    backgroundColor: theme.palette.white[100]
  }),
  ...(isDisabled && {
    opacity: 0.2,
    pointerEvents: "none"
  })
}));
