import { Box, styled } from "@mui/material";
import { Button } from "../../Buttons";

export const StyledConfirmButton = styled(Button)(({ theme }) => ({
  paddingInline: theme.remScale[16],
  paddingBlock: theme.remScale[12]
}));
export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.remScale[16],
  justifyContent: "flex-end",
  marginTop: theme.remScale[16]
}));
