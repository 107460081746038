import React from "react";
import { Grid } from "@mui/material";

import { Divider } from "ui/components";
import { CurrentBillingCycle } from "./CurrentBillingCycle";
import { BillingAccount } from "./BillingAccount";
import { BillingInformation } from "./BillingInformation";
import { InvoicesTable } from "./InvoicesTable";
import { useBilling } from "./useBilling";
import { PaymentMethods } from "./PaymentMethods";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../core/store/entities/User/user.selectors";

export const Billing = () => {
  useBilling();
  const isFreePlanUser = useSelector(userSelectors.isFreePlanUser);
  return (
    <>
      <Grid container rowSpacing={6.5}>
        <CurrentBillingCycle />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <BillingAccount />
        {!isFreePlanUser && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <PaymentMethods />
          </>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <BillingInformation />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <InvoicesTable />
      </Grid>
    </>
  );
};
