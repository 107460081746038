import { Typography, styled } from "@mui/material";

interface CustomTypographyProps {
  font: string;
  bg: string;
  border?: string;
}

export const StyledStatusCell = styled(Typography)<CustomTypographyProps>(({ theme, font, bg, border }) => ({
  color: font,
  backgroundColor: bg,
  paddingBlock: theme.remScale[2],
  paddingInline: theme.remScale[8],
  border: "1px solid",
  borderColor: border ?? bg,
  borderRadius: theme.remScale[4],
  textTransform: "capitalize"
}))