import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDomainsList } from "core/store/entities/Statistics/statistics.actions";
import { getSendingDomains } from "core/store/entities/Domain/domain.actions";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const useStatisticsDetails = () => {
  const { domainId } = useParams();
  const dispatch = useDispatch();
  const filterValue = useSelector(statisticsSelectors.filterValue);
  const error = useSelector(statisticsSelectors.error);

  const handleFetchDomainList = () => {
    dispatch(fetchDomainsList(domainId, filterValue));
    dispatch(getSendingDomains());
  };

  return { handleFetchDomainList, error };
};
