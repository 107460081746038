import { styled, Button } from "@mui/material";
import { type UsePaginationItem } from "@mui/material/usePagination";

export const StyledList = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "li:first-child": {
    marginRight: "auto"
  },
  "li:last-child": {
    marginLeft: "auto"
  }
});

export const StyledNav = styled("nav")({
  width: "100%"
});

type StyledPageButtonType = {
  selected: UsePaginationItem["selected"];
};

export const StyledPageButton = styled(Button)<StyledPageButtonType>(({ theme, selected }) => ({
  borderRadius: theme.remScale[6],
  color: "#282725",
  padding: theme.remScale[8],
  minWidth: 40,
  height: 40,
  ...(selected && { backgroundColor: theme.palette.gray[50] })
}));
