import { updateList } from "core/store/entities/List/list.actions";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { createList } from "core/store/entities/List/list.actions";
import type { ListUpdateFields } from "core/types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "ui/components/Inputs/ColorSwatchPick/colors";

const defaultValues: ListUpdateFields = {
  color: colors[0],
  label: "",
  description: ""
};

export const useListForm = () => {
  const list = useSelector(listSelectors.list);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    reset(list);
  }, [list])

  const onSubmit = (values: ListUpdateFields) => {
    if (list?.id) {
      dispatch(updateList(values));
    } else {
      dispatch(createList(values));
    }
  };
  const onReset = () => {
    reset(defaultValues);
  };

  return {
    control,
    errors,
    onSubmit: handleSubmit(onSubmit),
    onReset
  };
};
