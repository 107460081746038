import { TableCell, styled } from "@mui/material";

export const TableBodyCell = styled(TableCell)(({ theme }) => ({
  paddingInline: theme.remScale[12],
  paddingBlock: theme.remScale[8],
  lineHeight: 1,
  color: "inherit",
  fontSize: theme.remScale[14.25]
}));

export const TableBodyCellBordered = styled(TableCell)(({ alert, theme }) => ({
  border: "1px solid #D6D6D6",
  borderRadius: 2,
  padding: "15px 10px",
  ...(alert && {
    border: `1px solid ${theme.palette.error}`
  })
}));
