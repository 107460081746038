import { AnyAction, combineReducers } from "redux";
import { billingReducer } from "../entities/Billing/billing.slice";
import { contactReducer } from "../entities/Contact/contact.slice";
import { contactsReducer } from "../entities/Contacts/contacts.slice";
import { domainReducer } from "../entities/Domain/domain.slice";
import { listReducer } from "../entities/List/list.slice";
import { listsReducer } from "../entities/Lists/lists.slice";
import { logoutSuccess } from "../entities/User/user.actions";
import { messagesReducer } from "../entities/Messages/messages.slice";
import { modalFormReducer } from "../entities/ModalForm/modalForm.slice";
import { modalReducer } from "../entities/Modal/modal.slice";
import { routerReducer } from "../utils/history";
import { statisticsReducer } from "../entities/Statistics/statistics.slice";
import { upgradePlanReducer } from "../entities/UpgradePlan/upgradePlan.slice";
import { userReducer } from "../entities/User/user.slice";
import { importFlowReducer } from "../entities/ImportFlow/importFlow.slice";
import { campaignsReducer } from "../entities/Campaigns/campaigns.slice";

const reducers = {
  messages: messagesReducer,
  statistics: statisticsReducer,
  user: userReducer,
  domain: domainReducer,
  billing: billingReducer,
  router: routerReducer,
  modal: modalReducer,
  upgradePlan: upgradePlanReducer,
  lists: listsReducer,
  list: listReducer,
  modalForm: modalFormReducer,
  contact: contactReducer,
  contacts: contactsReducer,
  importFlow: importFlowReducer,
  campaigns: campaignsReducer
};

const combinedReducer = combineReducers(reducers);
export type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logoutSuccess.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};
