import { Box } from "@mui/material";
import { Wrapper } from "./styled";
import { useFormEmbedContext } from "../../context/FormEmbedContextProvider";

type Props = {
  visible: boolean;
};

export const ExampleTab = ({ visible }: Props) => {
  const { generatedCode } = useFormEmbedContext();
  return (
    <Wrapper
      sx={{
        display: visible ? "block" : "none"
      }}
      dangerouslySetInnerHTML={{
        __html: `<div style="pointer-events: none;">${generatedCode}</div>`
      }}
    />
  );
};
