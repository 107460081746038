import {
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  SETTINGS_LOG_FILES_SCREEN,
  SETUP_DOMAIN_SCREEN
} from "core/constants";

export type SettingRoute = CONNECT_API_SCREEN | CONNECT_SMTP_SCREEN | DASHBOARD_BILLING_SCREEN | DASHBOARD_SENDING_DOMAINS_SCREEN | SETTINGS_LOG_FILES_SCREEN;

export const getSettingsRoutes = (isEmailUser: boolean) => [
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  CONNECT_SMTP_SCREEN,
  CONNECT_API_SCREEN,
  SETUP_DOMAIN_SCREEN,
  isEmailUser && SETTINGS_LOG_FILES_SCREEN
].filter(Boolean);

export const getSettingsItems = (isEmailUser: boolean) => [
  {
    id: "billing",
    name: "Account and billing",
    to: DASHBOARD_BILLING_SCREEN
  },
  {
    id: "sending-domains",
    name: "Sending domains",
    to: DASHBOARD_SENDING_DOMAINS_SCREEN
  },
  {
    id: "smtp-setup",
    name: "SMTP user",
    to: CONNECT_SMTP_SCREEN
  },
  {
    id: "api-setup",
    name: "API key",
    to: CONNECT_API_SCREEN
  },
  isEmailUser && {
    id: "log-files",
    name: "Log files",
    to: SETTINGS_LOG_FILES_SCREEN
  }
].filter(Boolean);