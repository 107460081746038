import { status } from "core/constants/status";
import { ContactsState } from "./contacts.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContactsApi } from "core/types";

export const contactsReducers = {
  exportContactsStart: (state: ContactsState) => {
    state.exportContactsStatus = status.loading;
  },
  exportContactsCancel: (state: ContactsState) => {
    state.exportContactsStatus = status.idle;
    delete state.exportProgress;
    delete state.csvFileUrl;
  },
  exportContactsStartSuccess: (state: ContactsState) => {
    state.exportProgress = 0;
  },
  exportContactsStartFailed: (state: ContactsState, { payload }: PayloadAction<unknown>) => {
    delete state.exportProgress;
    state.exportContactsStatus = status.error;
    state.error = payload;
  },
  deleteContacts: (state: ContactsState) => {
    state.deleteContactsStatus = status.loading;
  },
  deleteContactsSuccess: (state: ContactsState) => {
    state.deleteContactsStatus = status.success;
  },
  deleteContactsFailed: (state: ContactsState, { payload }: PayloadAction<unknown>) => {
    state.deleteContactsStatus = status.error;
    state.error = payload;
  },
  fetchContacts: (state: ContactsState) => {
    state.fetchContactsStatus = status.loading;
    state.contacts = null;
  },
  fetchContactsSuccess: (state: ContactsState, { payload }: PayloadAction<ContactsApi>) => {
    state.fetchContactsStatus = status.success;
    state.contacts = payload.contacts;
    state.pagination = payload.pagination;
  },
  fetchContactsFailed: (state: ContactsState, { payload }: PayloadAction<unknown>) => {
    state.fetchContactsStatus = status.error;
    state.error = payload;
  },
  triggerUpdateExportContactsProgress: () => { },
  updateExportContactsProgress: (state: ContactsState, { payload }: PayloadAction<number>) => {
    state.exportProgress = payload;
    if (payload === 100) {
      state.exportContactsStatus = status.success;
    }
  },
  updateExportContactsProgressFailed: (
    state: ContactsState,
    { payload }: PayloadAction<unknown>
  ) => {
    delete state.exportProgress;
    state.error = payload;
    state.exportContactsStatus = status.error;
  },
  csvFilePreparedSuccess: (state: ContactsState, { payload }: PayloadAction<string>) => {
    state.csvFileUrl = payload;
  },
  resetContacts: (state: ContactsState) => {
    state.contacts = null;
    state.pagination = null;
    state.deleteContactsStatus = status.idle;
    state.exportContactsStatus = status.idle;
    state.fetchContactsStatus = status.idle;
    state.searchTerm = "";
    delete state.error;
    delete state.csvFileUrl;
    delete state.exportContactsStarted;
    delete state.exportProgress;
  }
};
