export const handleFileDrop = (onFileDrop: (file: File | null) => void) => (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
    const file = [...event.dataTransfer.items]
      .filter(item => item.type === "text/csv" && item.kind === "file")[0];
    if (file) {
      onFileDrop(file.getAsFile())
    }
  } else {
    console.warn("File drop: no files have been dropped");
  }
}