import { ChangeEvent } from "react";
import { Stack, FormControl, FormLabel, Typography, FormGroup } from "@mui/material";
import { StyledCheckboxGroupWrapper } from "./styled";
import { Checkbox } from "ui/components";
import { ContactField } from "core/types";

type Props = {
  onChange: (newValues: ContactField[]) => void;
  value: ContactField[];
  fields: ContactField[];
};

export const SelectListFields = ({ value, onChange, fields }: Props) => {
  const handleCheckboxToggle =
    (contactField: ContactField) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const index = fields.findIndex(v => v.column_name === contactField.column_name);
        onChange(value.toSpliced(index, 0, contactField));
      } else {
        onChange(value.filter(v => v.column_name !== contactField.column_name));
      }
    };

  const isChecked = (column_name: string) => {
    return !!value.find(v => v.column_name === column_name);
  };

  return (
    <Stack gap={3} onSubmit={e => e.preventDefault()} id="list_fields_select">
      <FormControl component="fieldset">
        <Stack gap={1.65}>
          <FormLabel component="legend">
            <Typography fontWeight={560} variant="body2" color={theme => theme.palette.black}>
              Choose fields
            </Typography>
          </FormLabel>
          <FormGroup>
            <StyledCheckboxGroupWrapper>
              {fields.map(field => (
                <Checkbox
                  key={field.column_name}
                  label={field.label}
                  value={field.column_name}
                  checked={isChecked(field.column_name)}
                  onChange={handleCheckboxToggle(field)}
                />
              ))}
            </StyledCheckboxGroupWrapper>
          </FormGroup>
        </Stack>
      </FormControl>
    </Stack>
  );
};
