import { ContactField, ContactFieldDataType } from "core/types";
import { isChecked } from "helpers/isChecked";
import { InputHTMLAttributes } from "react";
import { classes } from "./classes";
import dayjs from "dayjs";

type HTMLInputType = InputHTMLAttributes<HTMLInputElement>["type"];

const formatAttributes = (attributes: Record<string, any>) =>
  Object.entries(attributes)
    .map(([key, value]) => `${key}="${value}"`)
    .join(" ");

const fieldsWithoutPlaceholder: ContactFieldDataType[] = [
  "datetime",
  "date",
  "boolean",
  "choice",
  "float",
  "integer"
];

const generateLabel = (contactField: Omit<ContactField, "choices">) => `<label for="${contactField.column_name
  }" class="${classes.label}">
${contactField.label}${contactField.required ? `<span class="${classes.requiredAsterisk}">*</span>` : ""
  }:
</label>`;

const generateInput = (
  contactField: Omit<ContactField, "choices">,
  htmlInputProps?: { type?: HTMLInputType; class?: string; step?: string | number }
) => {
  const { type = "text", class: className, step } = htmlInputProps || {};

  const {
    column_name,
    label,
    pattern,
    length,
    default: defaultValue,
    required,
    data_type
  } = contactField;

  const formatDefaultValue =
    type === "number"
      ? defaultValue ?? 0
      : type === "date" || type === "datetime-local"
        ? defaultValue ?? dayjs(new Date()).format("YYYY-MM-DD")
        : defaultValue ?? "";

  const inputProps = {
    name: column_name,
    id: column_name,
    type,
    class: classes.input + " " + classes.fullWidth,
    ...(!fieldsWithoutPlaceholder.includes(data_type) && { placeholder: label })
  };

  const optionalAttributes = {
    ...(pattern && { pattern }),
    ...(length && { min: length }),
    ...(data_type !== "boolean" && { value: formatDefaultValue }),
    ...(className && { class: className }),
    ...(step && { step })
  };

  const booleanAttributes = [
    required && "required",
    data_type === "boolean" && isChecked(formatDefaultValue) && "checked"
  ].filter(Boolean);

  return `<div class="${classes.labelWrapper}">
        ${generateLabel(contactField)}
      </div>
  <input ${formatAttributes(inputProps)} ${formatAttributes(
    optionalAttributes
  )} ${booleanAttributes.join(" ")} />`;
};

const generateBoolean = (props: ContactField & any): string =>
  `<div class="${classes.fieldControl}">
            <div class="${classes.labelWrapper}">
              ${generateInput(props, { type: "checkbox" })}
            </div>
        </div>`;

const generateSelect = (props: ContactField) => {
  const selectProps = {
    value: props.default,
    class: classes.input + " " + classes.fullWidth
  };
  const options = Object.entries(props.choices)
    .map(
      ([key, value]) =>
        `     <option value="${key}">${value}</option>
        `
    )
    .join("");
  return `<div class="${classes.labelWrapper}">
          ${generateLabel(props)}
        </div>
  <select ${formatAttributes(selectProps)}>
      ${options}   
    </select>`;
};
const generateMultichoice = (props: ContactField) => {
  const checked = (id: string) => props.default?.includes(id);
  const options = Object.entries(props.choices)
    .map(
      ([id, label]) =>
        `<div>
  <input type="checkbox" id="${id}" name="${id}" ${checked(id)}/>
  <label for="${id}">${label}</label>
</div>
    `
    )
    .join("");
  return `<fieldset name="${props.column_name}">
  <legend class="${classes.legend}">${props.label}:</legend>
 ${options}
</fieldset>`;
};

const mapper = {
  boolean: generateBoolean,
  character: generateInput,
  choice: generateSelect,
  date: (props: ContactField) => generateInput(props, { type: "date" }),
  datetime: (props: ContactField) => generateInput(props, { type: "datetime-local" }),
  email: (props: ContactField) => generateInput(props, { type: "email" }),
  float: (props: ContactField) => generateInput(props, { type: "number", step: "any" }),
  integer: (props: ContactField) => generateInput(props, { type: "number" }),
  multichoice: generateMultichoice,
  pattern: generateInput
};

export const htmlInput = (props: ContactField) => {
  return mapper[props.data_type](props);
};
