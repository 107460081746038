import { FormControl, FormLabel, Stack, Typography } from "@mui/material";
import { StyledInput } from "./styled";
import { ColorSwatchPick } from "ui/components/Inputs/ColorSwatchPick";
import { useListForm } from "./useListForm";
import { Controller } from "react-hook-form";

export const ListForm = () => {
  const { control, onSubmit, onReset, errors } = useListForm();
  return (
    <Stack
      id="list-form"
      component="form"
      gap={4}
      sx={{ py: 3, pb: 6, px: { xs: 2, sm: 5 } }}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <Controller
        name="label"
        control={control}
        rules={{
          required: "This field is required"
        }}
        render={({ field }) => (
          <FormControl>
            <Typography id="list-name-label" component={FormLabel} sx={{ pb: "0.75rem" }}>
              List name
            </Typography>
            <StyledInput
              {...field}
              aria-labelledby="list-name-label"
              autoFocus
              error={!!errors?.label?.message}
              helperText={errors?.label?.message}
            />
          </FormControl>
        )}
      />
      <Controller
        name="color"
        control={control}
        render={({ field }) => <ColorSwatchPick {...field} />}
      />
    </Stack>
  );
};
