import { CUSTOM_COLUMNS_MODAL } from "core/constants";
import { useDispatch, useSelector } from "react-redux";
import { contactSelectors } from "core/store/entities/Contact/contact.selectors";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { TextButton, RegularButton, Button, Drawer } from "ui/components";
import { Stack, useTheme } from "@mui/material";
import { ColumnContainer } from "./components/ColumnContainer";
import { Add } from "@mui/icons-material";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";

export const CustomColumnsModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const contactFields = useSelector(contactSelectors.contactFields);

  const handleClose = () => {
    dispatch(hideModal(CUSTOM_COLUMNS_MODAL));
  };
  const handleAddNewColumn = () => {
    dispatch(showFormModal({ id: null, type: "customColumn" }));
  };

  return (
    <Drawer title={"List contact fields"} modalKey={CUSTOM_COLUMNS_MODAL} onClose={handleClose}>
      <Stack sx={{ px: 4 }} gap={2}>
        <Stack
          sx={{
            borderRadius: "6px",
            overflow: "hidden",
            border: `1px solid ${theme.palette.gray[100]}`
          }}
        >
          {contactFields?.map(field => (
            <ColumnContainer
              label={field.label}
              id={field.id}
              key={field.id}
              required={
                field.required ||
                field.column_name === "status" ||
                field.column_name === "timestamp" ||
                field.column_name === "ip" ||
                field.column_name === "created"
              }
            />
          ))}
        </Stack>
        <TextButton
          startIcon={<Add />}
          sx={{ alignSelf: "flex-start" }}
          onClick={handleAddNewColumn}
        >
          Add a new custom field
        </TextButton>
      </Stack>
    </Drawer>
  );
};
