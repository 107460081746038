import { useSendingDomainsTable } from "./useSendingDomainsTable";
import { Table } from "ui/components";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Spinner } from "ui/components";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";

export const SendingDomainsTable = () => {
  const isSendingDomainsLoading = useSelector(domainSelectors.isSendingDomainsLoading);
  const { headers, rows } = useSendingDomainsTable();

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="bodyXL">Your sending domains</Typography>
        {isSendingDomainsLoading ? <Spinner /> : <Table headers={headers} data={rows} />}
      </Stack>
      <Box style={{ height: "50px" }}></Box>
    </>
  );
};
