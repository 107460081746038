import { SupportAgent } from "@mui/icons-material";
import { useCustomerSupport } from "hooks";
import { StyledSupportAgentIcon } from "./styled";

export const SupportAgentIcon = () => {
  const { openCustomerSupport } = useCustomerSupport();
  return (
    <StyledSupportAgentIcon
      onClick={openCustomerSupport}
      variant="outlined"
      aria-label="Ask for support"
    >
      <SupportAgent sx={{ fontSize: "16px" }} />
    </StyledSupportAgentIcon>
  );
};
