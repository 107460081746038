import { CampaignApiRecord, PaginationApi } from "core/types";
import { campaignsReducers } from "./campaigns.reducers";

import { createSlice } from "@reduxjs/toolkit";
import { Status } from "core/constants/status";

export type CampaignsState = {
  campaigns: CampaignApiRecord[];
  error?: unknown;
  fetchCampaignsStatus: Status;
  duplicateCampaignStatus: Status;
  deleteCampaignStatus: Status;
  searchTerm: string;
  pagination: PaginationApi | null;

};

const initialState: CampaignsState = {
  campaigns: [],
  fetchCampaignsStatus: "idle",
  duplicateCampaignStatus: "idle",
  deleteCampaignStatus: "idle",
  searchTerm: "",
  pagination: null
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: campaignsReducers
});

const campaignsActions = campaignsSlice.actions;
const campaignsReducer = campaignsSlice.reducer;

export { campaignsActions, campaignsReducer };