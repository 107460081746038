import { outlinedInputClasses, styled } from "@mui/material";
import { Select } from "../../../../Selects/Select";
import { Input } from "../../../../Inputs/Input";

export const StyledSelect = styled(Select)(({ theme }) => ({
  paddingInline: theme.remScale[12],
  paddingBlock: theme.remScale[4],
  borderRadius: theme.remScale[6],

  "& .MuiInputBase-input": {
    color: theme.palette.gray[600],
    fontSize: theme.remScale[14.25]
  },

  [`.${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.black
  },
}))
export const StyledInput = styled(Input)(({ theme }) => ({
  borderRadius: theme.remScale[6],
}))