import { useCopyToClipboard } from "hooks";
import { ContentCopy } from "ui/assets/icons";
import { Tooltip } from "ui/components";
import { StyledCopyButton } from "./styled";
import { useFormEmbedContext } from "../../context/FormEmbedContextProvider";

export const CopyButton = () => {
  const { generatedCode } = useFormEmbedContext();
  const { isCopiedToClipboard, setIsCopiedToClipboard, handleCopyToClipboard } =
    useCopyToClipboard();

  return (
    <Tooltip
      open={isCopiedToClipboard}
      title="Copied to clipboard!"
      leaveDelay={500}
      onClose={() => setIsCopiedToClipboard(false)}
      placement="right"
    >
      <StyledCopyButton
        type="button"
        startIcon={<ContentCopy />}
        onClick={() => handleCopyToClipboard(generatedCode)}
      >
        Copy
      </StyledCopyButton>
    </Tooltip>
  );
};
