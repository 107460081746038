import { ListApi, PaginationApi } from "core/types";
import { listsReducers } from "./lists.reducers";

import { createSlice } from "@reduxjs/toolkit";

export type ListsState = {
  errors: null;
  lists: ListApi[];
  pagination: PaginationApi | null;
  listsLoading: boolean;
  listsLoadingFailed: boolean;
  updateListSuccess: boolean;
}

const initialState: ListsState = {
  errors: null,
  lists: [],
  listsLoading: false,
  listsLoadingFailed: false,
  pagination: null,
  updateListSuccess: false
}

const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: listsReducers
});

const listsActions = listsSlice.actions;
const listsReducer = listsSlice.reducer;

export { listsActions, listsReducer };