import { CampaignStatus } from "core/types";
import { StyledMoreMenu } from "./styled";
import { theme } from "ui/theme";
import { useCampaignsMoreMenu } from "./useCampaignsMoreMenu";

type Props = {
  name: string;
  id: string;
  status: CampaignStatus;
};

export const CampaignMoreMenu = ({ name, id, status }: Props) => {
  const menuItems = useCampaignsMoreMenu(id, name, status);
  return (
    <StyledMoreMenu
      iconStyle={{
        fontSize: theme.remScale[18]
      }}
      menuFor={{
        id,
        name
      }}
      items={menuItems}
    />
  );
};
