import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { useSelector } from "react-redux";
import { Button } from "ui/components";
import { theme } from "ui/theme";

export const ContinueButton = () => {
  const hasDuplicatedMappedColumns = useSelector(importFlowSelectors.hasDuplicatedMappedColumns);
  const shouldAddNewField = useSelector(importFlowSelectors.shouldAddNewField);
  return (
    <Button
      sx={{
        color: theme.palette.white.main
      }}
      disabled={hasDuplicatedMappedColumns || shouldAddNewField}
    >
      Continue
    </Button>
  );
};
