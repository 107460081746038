export const EDIT_BILLING_INFO_MODAL = "editBillingInfoModal";
export const EDIT_PERSONAL_INFO_MODAL = "editPersonalInfoModal";
export const CHANGE_PASSWORD_MODAL = "changePasswordModal";
export const ADD_CREDIT_CARD_MODAL = "addCreditCardModal";
export const SHOW_PAID_PLAN_BLOCK = "showPaidPlanBlock";
export const SHOW_NAVIGATION = "showNavigation";
export const SHOW_LIST_FORM = "showListsForm";
export const CONTACT_FORM_MODAL = "contactFormModal";
export const CUSTOM_COLUMNS_MODAL = "customColumnsModal";
export const ADD_CUSTOM_COLUMN_MODAL = "addCustomColumnModal";
export const DOWNLOAD_CONTACTS_MODAL = "downloadContactsModal";
