import { styled, Tab as MuiTab } from "@mui/material";

export const Tab = styled(MuiTab)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.gray[350],
  lineHeight: theme.remScale[20],
  marginInline: theme.remScale[6],
  paddingBottom: theme.remScale[24],
  paddingInline: theme.remScale[4],
  textTransform: "none",
  minWidth: "fit-content",
  "&:first-child": {
    marginLeft: 0
  },
  "&:last-child": {
    marginRight: 0
  },
  "&:focus-visible": {
    textDecoration: "underline",
    textDecorationThickness: 2
  },
  "&.Mui-selected": {
    color: theme.palette.black,
    fontWeight: 560
  }
}));
