import { ListsState } from "./lists.slice";

export const listsReducers = {
  deleteList: () => { },
  deleteListSuccess: () => {
  },
  deleteListError: (state: ListsState, { payload }) => {
    state.errors = payload;
  },
  duplicateList: () => { },
  duplicateListSuccess: () => {
  },
  duplicateListError: (state: ListsState, { payload }) => {
    state.errors = payload;
  },
  fetchLists: (state: ListsState) => {
    state.listsLoading = true;
  },
  fetchListsFailed: (state: ListsState, { payload }) => {
    state.errors = payload;
    state.listsLoading = false;
  },
  fetchListsSuccess: (state: ListsState, { payload }) => {
    state.lists = payload.items;
    state.pagination = payload.pagination;
    state.listsLoading = false;
  }
};
