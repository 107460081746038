import { listsActions } from "./lists.slice";

export const {
  deleteList,
  deleteListSuccess,
  deleteListError,
  duplicateList,
  duplicateListSuccess,
  duplicateListError,
  fetchLists,
  fetchListsSuccess,
  fetchListsFailed
} = listsActions;
