import { Typography } from "@mui/material";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { useSelector } from "react-redux";

export const AddedFieldsCount = () => {
  const addedFieldsCount = useSelector(importFlowSelectors.addedFieldsCount);
  const initFieldsCount = useSelector(importFlowSelectors.initFieldsCount);
  return (
    <Typography variant="body2" fontWeight={360} sx={{ color: "#3C3C3A" }}>
      {addedFieldsCount} of {initFieldsCount} fields added
    </Typography>
  );
};
