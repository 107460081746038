import { Stack, styled } from "@mui/material";

export const BillingInformationContainer = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  border: `1px solid ${theme.palette.gray[200]}`,
  padding: "1.5rem 1.25rem",
  [theme.breakpoints.down("sm")]: {
    padding: "14px 10px"
  }
}));
