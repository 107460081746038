import { StyledIconButton } from "./styled";
import { ArrowBack } from "@mui/icons-material";

type Props = {
  onClick: () => void;
};

export const BackIcon = ({ onClick }: Props) => {
  return (
    <StyledIconButton onClick={onClick} variant="outlined" aria-label="Ask for support">
      <ArrowBack sx={{ fontSize: theme => theme.remScale[16] }} />
    </StyledIconButton>
  );
};
