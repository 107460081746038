import { styled, Theme } from "@mui/material";

type DashedCircleIconProps = {
  borderColor?: ((theme: Theme) => string) | string;
}

export const DashedCircleIcon = styled("span")<DashedCircleIconProps>(({ theme, borderColor }) => ({
  display: "inline-block",
  width: "1.25em",
  height: "1.25em",
  border: "2px dashed",
  borderColor: typeof borderColor === "string" ? borderColor : borderColor?.(theme) ?? "#000000",
  borderRadius: "0.625em"
}));