import { call, put, takeLatest } from "redux-saga/effects";
import { contactsActions } from "./contacts.slice";
import { contactActions } from "../Contact/contact.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ListContactsServiceInstance } from "../List/list.saga";
import { ExportContactsProgress } from "core/types";

let taskId: string;

function* fetchContactsHandler() {
  try {
    if (ListContactsServiceInstance) {
      const data = yield call(ListContactsServiceInstance.getContacts);
      yield put(contactsActions.fetchContactsSuccess(data));
    }
  } catch (error) {
    yield put(contactsActions.fetchContactsFailed(error));
  }
}

function* deleteContactsHandler({ payload }: PayloadAction<string[]>) {
  try {
    if (ListContactsServiceInstance) {
      yield call(ListContactsServiceInstance.deleteContacts, payload);
      yield put(contactsActions.deleteContactsSuccess());
    }
  } catch (error) {
    yield put(contactsActions.deleteContactsFailed(error));
  }
}

function* exportContactsStartHandler() {
  try {
    if (ListContactsServiceInstance) {
      const { task_id } = yield call(ListContactsServiceInstance.startExportContacts);
      taskId = task_id;
      yield put(contactsActions.exportContactsStartSuccess());
    }
  } catch (error) {
    yield put(contactsActions.exportContactsStartFailed(error));
  }
}
function* updateExportContactsProgressHandler() {
  try {
    if (ListContactsServiceInstance && taskId) {
      const data: ExportContactsProgress = yield call(ListContactsServiceInstance.getExportContactsProgress, taskId);
      yield put(contactsActions.updateExportContactsProgress(data.progress_percentage));
      if (data.progress_percentage === 100 && data.file_url) {
        yield put(contactsActions.csvFilePreparedSuccess(data.file_url));
      }
    }
  } catch (error) {
    yield put(contactsActions.updateExportContactsProgressFailed(error));
  }
}

function* triggerContactsRefetch() {
  yield put(contactsActions.fetchContacts());
}


export default function* contactsSaga() {
  yield takeLatest(
    contactsActions.fetchContacts.type,
    fetchContactsHandler
  );
  yield takeLatest(
    [
      contactActions.createContactSuccess.type,
      contactActions.updateContactSuccess.type,
      contactsActions.deleteContactsSuccess.type,
    ],
    triggerContactsRefetch
  );
  yield takeLatest(contactsActions.deleteContacts.type, deleteContactsHandler);
  yield takeLatest(contactsActions.exportContactsStart.type, exportContactsStartHandler);
  yield takeLatest(contactsActions.triggerUpdateExportContactsProgress.type, updateExportContactsProgressHandler);
}
