import { Stack } from "@mui/material";
import { RegularButton, SubmenuButton } from "ui/components";
import { ListActionsItem } from "./useListActionsItems";

type Props = {
  actionItems: ListActionsItem[];
};

export const ListActions = ({ actionItems }: Props) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        display: { xs: "none", lg: "flex" }
      }}
    >
      {actionItems.map(item => {
        if ("submenu" in item) {
          return <SubmenuButton key={item.id} {...item} sx={{ minWidth: "fit-content" }} />;
        }
        return (
          <RegularButton
            startIcon={item.icon}
            key={item.id}
            onClick={item.onClick}
            aria-label={item["aria-label"]}
            sx={{ minWidth: "fit-content" }}
          >
            {item.name}
          </RegularButton>
        );
      })}
    </Stack>
  );
};
