import { CONTACT_FORM_MODAL, SHOW_LIST_FORM } from "core/constants";
import { Button, RegularButton, Drawer } from "ui/components";
import { useDispatch } from "react-redux";
import { hideFormModal } from "core/store/entities/ModalForm/modalForm.actions";

type Props = {
  title: string;
  modalKey: CONTACT_FORM_MODAL | SHOW_LIST_FORM;
  form: {
    formName: string;
    component: React.ReactNode;
  };
};

export const SideFormModal = ({ title, modalKey, form }: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideFormModal());
  };

  return (
    <Drawer
      title={title}
      modalKey={modalKey}
      onClose={handleClose}
      footer={
        <>
          <RegularButton
            form={form.formName}
            type="reset"
            sx={{ ml: "auto" }}
            onClick={handleClose}
          >
            Cancel
          </RegularButton>
          <Button form={form.formName} type="submit">
            Save
          </Button>
        </>
      }
    >
      {form.component}
    </Drawer>
  );
};
