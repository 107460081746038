import React from "react";
import { Typography } from "@mui/material";
import { RegularButton } from "ui/components";
import { StyledConfirmButton, Wrapper } from "./styled";

export const ActionButtons = ({
  onCancel,
  onConfirm,
  cancelTitle = "Cancel",
  confirmTitle = "Confirm",
  isConfirmSubmit = false,
  confirmDisabled = false,
  cancelDisabled = false,
}) => {
  return (
    <Wrapper>
      <RegularButton
        disabled={cancelDisabled}
        type="button"
        onClick={onCancel}
        sx={{ px: theme => theme.remScale[16], py: theme => theme.remScale[12] }}
      >
        <Typography lineHeight={1}>{cancelTitle}</Typography>
      </RegularButton>
      <StyledConfirmButton
        disabled={confirmDisabled}
        type={isConfirmSubmit ? "submit" : "button"}
        onClick={onConfirm}
      >
        <Typography>{confirmTitle}</Typography>
      </StyledConfirmButton>
    </Wrapper>
  );
};
