import { useSelector } from "react-redux";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";
import { getDayString } from "helpers/string";

const periodMapping = {
  1: "Yesterday",
  0: "Today"
};

const comparedPeriodMapping = {
  1: "Yesterday (compared to the day before)",
  0: "Today (compared to yesterday)"
};

export const useCurrentPeriodText = ({ compareToPrevious = false, filterValue } = {}) => {
  const filterDetailsValue =
    filterValue === 0
      ? filterValue
      : filterValue || useSelector(statisticsSelectors.filterDetailsValue);

  const daysString = getDayString(filterDetailsValue);
  const defaultPeriodText = `Last ${filterDetailsValue} ${daysString}`;

  const currentPeriodText =
    (compareToPrevious
      ? comparedPeriodMapping[filterDetailsValue]
      : periodMapping[filterDetailsValue]) || defaultPeriodText;

  return { currentPeriodText };
};
