import React from "react";
import { Stack, Typography } from "@mui/material";

import { DeliveriesChart } from "./DeliveriesChart";
import { useDeliveriesBounces } from "./useDeliveriesBounces";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";
import { BorderedBlock } from "ui/components/Blocks/BorderedBlock";
import { DeliveriesChartLoading } from "./DeliveriesChart/Loading";

export const DeliveriesBounces = () => {
  const { deliveriesAndBounces, isLoading } = useDeliveriesBounces();
  const { currentPeriodText } = useCurrentPeriodText();

  return (
    <BorderedBlock>
      <Stack gap={6}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h5" component="h2" fontWeight={560}>
            Deliveries and bounces
          </Typography>
          <Typography variant="small">{currentPeriodText}</Typography>
        </Stack>
        {isLoading ? <DeliveriesChartLoading /> : <DeliveriesChart data={deliveriesAndBounces} />}
      </Stack>
    </BorderedBlock>
  );
};
