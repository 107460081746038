import React from "react";
import { CircularProgress, Stack } from "@mui/material";

export const Spinner = ({ size = 20 }) => {
  return (
    <Stack alignItems="center" justifyContent="center">
      <CircularProgress size={size} color="black" id="spinner" />
    </Stack>
  );
};
