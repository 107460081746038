import React from "react";
import { StyledEditButton } from "./styled";
import { Typography } from "@mui/material";

export const EditButton = ({ onClick }) => {
  return (
    <StyledEditButton onClick={onClick} variant="text">
      <Typography>Edit</Typography>
    </StyledEditButton>
  );
};
