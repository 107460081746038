import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(() => ({
  border: "none",
  fontSize: "14.25px",
  fontWeight: 460,
  minHeight: 150,
  "& .MuiDataGrid-row, MuiDataGrid-row--lastVisible, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover,& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered":
  {
    backgroundColor: "transparent"
  },
  ".MuiDataGrid-cellCheckbox:focus-within, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus-within":
  {
    outline: "none"
  },
  ".MuiDataGrid-footerContainer": {
    display: "none"
  },
  ".MuiDataGrid-columnHeaderTitle": {
    color: "#686764",
    fontWeight: 560,
    fontSize: "12.75px"
  },
  ".MuiDataGrid-sortIcon": {
    opacity: "1 !important",
    fontSize: "14px",
    color: "#000000"
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "auto"
  },
  ".MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator, .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator--resizing": {
    visibility: "hidden"
  }
}));
