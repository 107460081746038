import { Stack, Typography } from "@mui/material";
import { Button, Input } from "ui/components";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { addSendingDomain } from "core/store/entities/Domain/domain.actions";

const schema = yup.object({
  url: yup
    .string()
    .required("This field may not be blank")
    .matches(/^[0-9a-z.-]+$/, "This field should not contain spaces and capitals")
});

export const AddDomain = () => {
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(addSendingDomain({ ...data, is_initial: true }));
  };

  return (
    <Stack spacing={2}>
      <Typography variant="bodyM">
        Enter a domain that you own, as you will be required to update your DNS records.
      </Typography>
      <Stack
        justifyContent="flex-start"
        component="form"
        sx={{ flexDirection: { xs: "column", sm: "row" } }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <Input
              id="url"
              name="url"
              placeholder="example.com"
              error={!!errors?.url?.message}
              helperText={errors?.url?.message}
              {...field}
              sx={{ width: 220 }}
            />
          )}
        />
        <Button
          sx={{
            margin: { xs: "20px 0 0 20px", sm: "0 0 0 -5px" },
            height: "52px",
            minWidth: "144px"
          }}
          type="submit"
        >
          Add domain
        </Button>
      </Stack>
    </Stack>
  );
};
