import { Box, styled, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  padding: theme.remScale[20],
  paddingBottom: 0,
  borderBottom: `1px solid ${theme.palette.gray[225]}`,
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.white.main
}));
export const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 6,
  minHeight: 464,
  height: 464,
  overflowY: "hidden"
}));
