import { Box, Stack, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  "--tabs-height": "77px",
  fontFamily: "GeistMono",
  overflowY: "auto",
  maxHeight: "calc(100% - var(--tabs-height))",
  fontSize: theme.remScale[12.75],
  lineHeight: theme.remScale[18],
}))

export const StyledPre = styled("pre")(() => ({
  margin: 0,
  maxWidth: "100%",
  overflowX: "auto"
}));

export const LinesNumberColumn = styled(Stack)(({ theme }) => ({
  color: theme.palette.gray[650],
  padding: theme.remScale[24],
  paddingRight: theme.remScale[10]
}));

export const LineNumber = styled("span")(() => ({
  "user-select": "none"
}));

export const StyledCodeSpan = styled("span")(({ theme }) => ({
  color: theme.palette.gray[700]
}));

export const StyledCode = styled("code")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.remScale[24],
  width: "max-content"
}));

export const StyledCodeContainer = styled(Box)(() => ({
  display: "flex",
  maxWidth: "100%",
  overflowX: "hidden"
}))
