import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.gray[100]}`
  },
  paddingInline: theme.remScale[16],
  paddingBlock: theme.remScale[8]
}))