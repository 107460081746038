import { StyledTabs, Wrapper } from "./styled";
import { Tab as TabComponent } from "ui/components";
import { useState } from "react";
import { CodeTab } from "../CodeTab";
import { ExampleTab } from "../ExampleTab";

const tabs = { example: "Example", code: "Code" } as const;
type Tab = keyof typeof tabs;

export const TabsWindow = () => {
  const [currentTab, setCurrentTab] = useState<Tab>("example");

  return (
    <Wrapper>
      <StyledTabs
        onChange={(_, newValue: Tab) => {
          setCurrentTab(newValue);
        }}
        allowScrollButtonsMobile
        value={currentTab}
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: "black",
            height: "0.25rem"
          }
        }}
      >
        {Object.keys(tabs).map(tab => (
          <TabComponent label={tabs[tab as Tab]} value={tab} key={tab} />
        ))}
      </StyledTabs>
      <CodeTab visible={currentTab === "code"} />
      <ExampleTab visible={currentTab === "example"} />
    </Wrapper>
  );
};
