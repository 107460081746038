import { RootState } from "core/store/reducers";
import { createSelector } from "reselect";

const selectSelf = (state: RootState) => state.contacts;

const selectContacts = createSelector(selectSelf, contactsState => contactsState.contacts);
const selectPagination = createSelector(selectSelf, contactsState => contactsState.pagination);
const selectFetchContactsStatus = createSelector(selectSelf, contactsState => contactsState.fetchContactsStatus);

export const contactsSelectors = {
  contacts: selectContacts,
  totalCount: createSelector(selectPagination, pagination => pagination?.total_count ?? 0),
  totalPages: createSelector(selectPagination, pagination =>
    pagination?.total_pages ?? 1
  ),
  fetchContactsStatus: selectFetchContactsStatus,
  fetchContactsLoading: createSelector(
    selectFetchContactsStatus,
    status => status === "loading"
  ),
  fetchContactsSuccess: createSelector(
    selectFetchContactsStatus,
    status => status === "success"
  ),
  fetchContactsError: createSelector(
    selectFetchContactsStatus,
    status => status === "error"
  ),
  error: createSelector(selectSelf, contactsState => contactsState.error),
  exportProgress: createSelector(selectSelf, contactsState => contactsState.exportProgress),
  exportInProgress: createSelector(
    selectSelf,
    contactsState => contactsState.exportProgress !== undefined && contactsState.exportProgress >= 0
  ),
  canDownloadFile: createSelector(
    selectSelf,
    contactsState =>
      contactsState.exportProgress === 100 &&
      contactsState.exportContactsStatus === "success"
  ),
  exportContactsError: createSelector(
    selectSelf,
    contactsState => contactsState.exportContactsStatus === "error"
  ),
  csvFileUrl: createSelector(
    selectSelf,
    contactsState => contactsState.csvFileUrl
  )
};
