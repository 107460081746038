import { forwardRef } from "react";
import { Table } from "ui/components";
import { TableRow } from "core/types";
import { useColumnsTableHeaders } from "./useColumnsTableHeaders";
import { useSelector } from "react-redux";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";

type Props = {
  rows: TableRow[];
};

export const CustomColumnsTable = forwardRef(({ rows }: Props, ref) => {
  const columns = useSelector(importFlowSelectors.initColumnsFieldsMap);
  const headers = useColumnsTableHeaders(columns);

  return (
    <Table ref={ref} headers={headers} data={rows} wrapperStyles={{ scrollBehavior: "smooth" }} />
  );
});
