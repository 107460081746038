import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { FilterSelect } from "ui/components";
import { useOverall } from "./useOverall";
import { OverallBlock } from "ui/components";
import { OverallBlockSkeleton } from "ui/components/Blocks/OverallBlock/Skeleton";

export const Overall = () => {
  const {
    isGlobalStatsLoading,
    totalSent,
    totalSentDiff,
    filterOptions,
    filterValue,
    percentDelivered,
    percentDeliveredDiff,
    percentBounced,
    totalBouncedDiff,
    currentPeriodText,
    filterChangeHandler
  } = useOverall();

  return (
    <Grid item xs={12}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h4" component="h2">
              Overall
            </Typography>
            <Typography variant="small">{currentPeriodText}</Typography>
          </Stack>
          <FilterSelect
            options={filterOptions}
            value={filterValue}
            onChange={filterChangeHandler}
            style={{ marginLeft: "auto" }}
          />
        </Stack>
        {isGlobalStatsLoading ? (
          <OverallBlockSkeleton />
        ) : (
          <OverallBlock
            totalSent={totalSent}
            totalSentDiff={totalSentDiff}
            percentDelivered={percentDelivered}
            percentDeliveredDiff={percentDeliveredDiff}
            percentBounced={percentBounced}
            totalBouncedDiff={totalBouncedDiff}
          />
        )}
      </Stack>
    </Grid>
  );
};
