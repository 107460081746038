import { Download } from "ui/assets/icons";
import { StyledTextButton } from "../../styled";
import { useDownloadExampleButton } from "./useDownloadExampleButton";

export const DownloadExampleButton = () => {
  const onClick = useDownloadExampleButton();
  return (
    <StyledTextButton
      component="a"
      startIcon={<Download style={{ fontSize: "inherit" }} />}
      onClick={onClick}
    >
      Download example file (.csv)
    </StyledTextButton>
  );
};
