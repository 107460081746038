import { MoreVert } from "@mui/icons-material";
import { ButtonProps, IconProps } from "@mui/material";

import { StyledButton } from "./styled";

export type MoreIconProps = ButtonProps & {
  active?: boolean;
  iconStyle?: IconProps["sx"];
};

export const MoreIcon = ({ onClick, iconStyle, ...props }: MoreIconProps) => {
  return (
    <StyledButton onClick={onClick} aria-label="Show more" {...props}>
      <MoreVert sx={iconStyle} />
    </StyledButton>
  );
};
