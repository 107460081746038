import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import { Divider } from "ui/components";
import { BorderedBlock } from "../BorderedBlock";

export const OverallBlockSkeleton = () => {
  return (
    <BorderedBlock>
      <Grid container gap={{ xs: 2, lg: 0 }} alignItems="center">
        <Grid item xs={12} md={4} lg={4}>
          <Stack>
            <Skeleton width={80} height={24} />
            <Skeleton width={20} height={32} />
            <Skeleton width={80} height={32} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Stack spacing={{ xs: 0, lg: 4 }} direction="row">
            <Divider flexItem orientation="vertical" />
            <Stack>
              <Skeleton width={80} height={24} />
              <Skeleton width={20} height={32} />
              <Skeleton width={80} height={32} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Stack spacing={{ xs: 0, lg: 4 }} direction="row">
            <Divider flexItem orientation="vertical" />
            <Stack>
              <Skeleton width={80} height={24} />
              <Skeleton width={20} height={32} />
              <Skeleton width={80} height={32} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </BorderedBlock>
  );
};
