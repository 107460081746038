import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BackIcon } from "ui/components/Icons/BackIcon";
import { LIST_CONTACTS_IMPORT, LISTS_SCREEN } from "core/constants";

export const BackButton = () => {
  const { list_id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (pathname.endsWith(LIST_CONTACTS_IMPORT)) {
      const newPathname = `${LISTS_SCREEN}/${list_id}`;
      navigate(newPathname);
      return;
    }
    navigate(-1);
  };
  return <BackIcon onClick={handleClick} />;
};
