import { useDispatch } from "react-redux";
import { useSearchUrlParam } from "hooks/useUrlParams";
import { fetchCampaigns } from "core/store/entities/Campaigns/campaigns.actions";

export const useCampaignsSearchInput = () => {
  const dispatch = useDispatch();
  const { searchTerm, onSearchChange } = useSearchUrlParam();

  const handleSearchChange = (searchValue: string) => {
    onSearchChange(searchValue);
    dispatch(fetchCampaigns());
  }

  return {
    searchTerm,
    onChange: handleSearchChange,
  }
}