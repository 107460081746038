import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import React from "react";
import { Alert, Grid } from "@mui/material";
import { Input, Spinner, StripeInput } from "ui/components";
import { useAddCreditCardForm } from "./useAddCreditCardForm";
import { Controller } from "react-hook-form";
import { ActionButtons } from "../../../../components/Modals/ActionButtons";

export const AddCreditCardForm = ({ onClose }) => {
  const { control, stripe, onSubmit, errors, addPaymentMethodError, isAddingLoading } =
    useAddCreditCardForm();

  return (
    <Grid container component="form" rowGap={2.5} maxWidth="100%">
      {!!addPaymentMethodError?.message && (
        <Grid item xs={12}>
          <Alert variant="filled" severity="error">
            {addPaymentMethodError.message}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mt: 0.75 }}>
        <Controller
          name="cardholderName"
          control={control}
          render={({ field }) => (
            <Input
              autoFocus
              id="cardholderName"
              label="Cardholder Name"
              name="cardholderName"
              fullWidth
              error={!!errors?.cardholderName?.message}
              helperText={errors?.cardholderName?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          id="cardNumber"
          label="Credit Card Number"
          placeholder="Credit Card Number"
          name="cardNumber"
          error={addPaymentMethodError?.code?.includes("number")}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement
            }
          }}
        />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <Input
            fullWidth
            id="cvc"
            label="CVC code"
            name="cvc"
            error={addPaymentMethodError?.code?.includes("cvc")}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            fullWidth
            id="expiry"
            label="Expiration date"
            name="expiry"
            error={addPaymentMethodError?.code?.includes("expiry")}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ActionButtons
          confirmDisabled={!stripe}
          confirmTitle={isAddingLoading ? <Spinner /> : "Add credit card"}
          isConfirmSubmit
          onCancel={onClose}
          onConfirm={onSubmit}
        />
      </Grid>
    </Grid>
  );
};
