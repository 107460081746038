import { listSelectors } from "core/store/entities/List/list.selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useFormEmbed = () => {
  const list = useSelector(listSelectors.list);
  const fetchedSuccessful = useSelector(listSelectors.success);
  const error = useSelector(listSelectors.error);
  const { list_id } = useParams();
  const isListInStore = list && list.id === list_id;

  return {
    list,
    fetchedSuccessful,
    error,
    isListInStore,
    listParamId: list_id
  }
}