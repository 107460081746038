import React from "react";

import { numberWithDots } from "helpers";

import { Box, Stack, Typography } from "@mui/material";
import { DiffStatus } from "../../index";

export const Indicator = ({ value, label, diff, inverted }) => {
  return (
    <Box>
      <Stack spacing={1}>
        <Typography>{label}</Typography>
        <Stack spacing={0.5}>
          <Typography lineHeight="2rem" fontSize="1.5rem">
            {numberWithDots(value)}
          </Typography>
          <DiffStatus inverted={inverted} diff={diff} />
        </Stack>
      </Stack>
    </Box>
  );
};
