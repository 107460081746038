import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  "--tabs-height": "77px",
  fontFamily: "Aria, sans-serif",
  paddingInline: theme.remScale[80],
  paddingBlock: theme.remScale[28],
  maxHeight: "calc(100% - var(--tabs-height))",
  height: "calc(100% - var(--tabs-height))",
  overflowY: "auto",
  width: "100%"
}));
