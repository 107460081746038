import { CircularProgress, Typography } from "@mui/material";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { useSelector } from "react-redux";

export const ContactsCount = () => {
  const contactsTotalCount = useSelector(contactsSelectors.totalCount);
  const success = useSelector(contactsSelectors.fetchContactsSuccess);
  return (
    <Typography fontWeight={360}>
      {success ? (
        contactsTotalCount.toLocaleString("en-US")
      ) : (
        <CircularProgress size="1em" color="inherit" />
      )}{" "}
      contacts
    </Typography>
  );
};
