import React from "react";
import { Box, Typography } from "@mui/material";
import { FullscreenSpinner, Header, Stepper } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { ARROW_RIGHT_SYMBOL } from "core/constants";
import { useSetupDomain } from "./useSetupDomain";

export const SetupDomain = () => {
  const { steps, domainName } = useSetupDomain();

  if (!domainName) return <FullscreenSpinner />;

  return (
    <>
      <Typography
        variant="h2"
        sx={{
          flexDirection: { xs: "column-reverse", sm: "row" },
          wordBreak: { xs: "break-word", sm: "normal" },
          gap: { xs: 2, sm: 0 }
        }}
      >Okay, let's now set up your domain {ARROW_RIGHT_SYMBOL} {domainName}</Typography>
      <Box sx={{ padding: generalContentPadding }}>
        <Stepper steps={steps} firstStepDivider />
      </Box>
    </>
  );
};
