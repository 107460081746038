import { createTheme } from "@mui/material";
import { typographyTheme } from "./typography";
import { paletteTheme } from "./palette";
import { remScaleTheme } from "./remScale";

export const theme = createTheme({
  typography: { fontFamily: "Geist", ...typographyTheme },
  palette: { ...paletteTheme },
  remScale: { ...remScaleTheme },

});
