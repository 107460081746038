import { useDispatch, useSelector } from "react-redux"
import { hideModal, showModal } from "../core/store/entities/Modal/modal.actions";
import { modalSelectors } from "../core/store/entities/Modal/modal.selectors";
import { SHOW_LIST_FORM, SHOW_NAVIGATION } from "core/constants";

const sidebars = [SHOW_LIST_FORM, SHOW_NAVIGATION] as const;

export const useSidebar = (sidebar: typeof sidebars[number]) => {
  const dispatch = useDispatch();
  const isExpanded: boolean = useSelector(modalSelectors[sidebar]);

  const handleExpand = (show: boolean) => {
    dispatch(show ? showModal(sidebar) : hideModal(sidebar));
  };

  return {
    onExpand: handleExpand,
    isExpanded
  }
}