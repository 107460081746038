import { getRequest, deleteRequest, postRequest, patchRequest } from "core/api/base";
import { PROFILE_TOKEN } from "core/constants";
import type { ContactField, ListUpdateFields, ListsRecordsResponse } from "core/types";

const contactsListsUrl = "/mailsystem/api/v1/contacts-lists";

export class ListsService {
  static fetchLists = (): ListsRecordsResponse => {
    const params = new URLSearchParams(window.location.search);
    return getRequest({
      url: contactsListsUrl,
      params,
      token: PROFILE_TOKEN
    });
  };

  static fetchList = (listId: string) => {
    return getRequest({ url: `${contactsListsUrl}/${listId}` });

  }
  static deleteList = (listId: string) => {
    return deleteRequest({ url: `${contactsListsUrl}/${listId}` });
  };
  static duplicateList = (listId: string) => {
    return postRequest({ url: `${contactsListsUrl}/${listId}/duplicate/` });
  };
  static createList = (data: ListUpdateFields) => {
    return postRequest({ url: `${contactsListsUrl}/`, data })
  }

  static updateList = (listId: string, data: ListUpdateFields) => {
    return patchRequest({ url: `${contactsListsUrl}/${listId}/`, data })
  }

  static addNewContactsField = (listId: string, data: ContactField) => {
    return postRequest({ url: `${contactsListsUrl}/${listId}/add-field/`, data })
  }

  static removeContactsField = (listId: string, contactId: string) => {
    return deleteRequest({ url: `${contactsListsUrl}/${listId}/delete-field/${contactId}` })
  }

  static editContactsField = (listId: string, contactId: string, data: ContactField) => {
    return patchRequest({ url: `${contactsListsUrl}/${listId}/update-field/${contactId}/`, data })
  }
}
