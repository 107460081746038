import { ColoredBlock } from "ui/screens/Lists/ColoredBlock";
import { ListLabelBox, StyledLink } from "./styled";
import { useSelector } from "react-redux";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { Link } from "react-router-dom";
import { LISTS_SCREEN } from "core/constants";

export const ListLabel = () => {
  const color = useSelector(listSelectors.listColor);
  const label = useSelector(listSelectors.listLabel);
  const listId = useSelector(listSelectors.listId);
  return (
    <ListLabelBox>
      <ColoredBlock
        backgroundColor={color}
        sx={{
          width: 20,
          height: 20
        }}
      />
      <StyledLink to={`${LISTS_SCREEN}/${listId}`}>{label}</StyledLink>
    </ListLabelBox>
  );
};
