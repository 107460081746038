import { PayloadAction } from "@reduxjs/toolkit";
import { CampaignsState } from "./campaigns.slice";
import { CampaignApiRecord, PaginationApi } from "core/types";

export const campaignsReducers = {
  fetchCampaigns: (state: CampaignsState) => {
    state.fetchCampaignsStatus = "loading";
    delete state.error;

  },
  fetchCampaignsSuccess: (state: CampaignsState, { payload }: PayloadAction<{ items: CampaignApiRecord[], pagination: PaginationApi }>) => {
    state.campaigns = payload.items;
    state.pagination = payload.pagination;
    state.fetchCampaignsStatus = "success";
    delete state.error;

  },
  fetchCampaignsError: (state: CampaignsState, { payload }: PayloadAction<unknown>) => {
    state.error = payload;
    state.fetchCampaignsStatus = "error";
  },
  setCurrentPage: (state: CampaignsState, { payload }: PayloadAction<number>) => {
    state.currentPage = payload;
  },
  setSearchTerm: (state: CampaignsState, { payload }: PayloadAction<string>) => {
    state.searchTerm = payload;
  },
  duplicateCampaign: (state: CampaignsState, _: PayloadAction<string>) => {
    state.duplicateCampaignStatus = "loading";
    delete state.error;

  },
  duplicateCampaignSuccess: (state: CampaignsState) => {
    state.duplicateCampaignStatus = "success";
    delete state.error;

  },
  duplicateCampaignError: (state: CampaignsState, { payload }: PayloadAction<unknown>) => {
    state.duplicateCampaignStatus = "error";
    state.error = payload;
  },
  deleteCampaign: (state: CampaignsState, _: PayloadAction<string>) => {
    state.deleteCampaignStatus = "loading";
    delete state.error;

  },
  deleteCampaignSuccess: (state: CampaignsState) => {
    state.deleteCampaignStatus = "success";
    delete state.error;

  },
  deleteCampaignError: (state: CampaignsState, { payload }: PayloadAction<unknown>) => {
    state.deleteCampaignStatus = "error";
    state.error = payload;
  },
};
