import { ButtonProps } from "@mui/material";
import { StyledButton } from "./styled";
import { ContentCopy } from "ui/assets/icons";
import { CSSProperties } from "react";

type Props = {
  iconStyle?: CSSProperties;
} & ButtonProps;

export const DuplicateIcon = ({ iconStyle, ...props }: Props) => {
  return (
    <StyledButton {...props}>
      <ContentCopy style={iconStyle} />
    </StyledButton>
  );
};
