import { Button as MUIButton, styled } from "@mui/material";

export const StyledButton = styled(MUIButton)(({ size = "default", bgColor, theme }) => ({
  ...theme.typography.body1,
  backgroundColor: theme.palette.gray[950],
  borderRadius: "0.375rem",
  boxShadow: "0 2px 2px 0 rgb(0 0 0 / 8%), 0 0 4px 0 rgb(0 0 0 / 20%)",
  color: theme.palette.white.main,
  lineHeight: theme.remScale[20],
  textTransform: "none",
  ...(size === "default" && {
    paddingBlock: theme.remScale[10],
    paddingInline: theme.remScale[14],
    letterSpacing: 0.2
  }),
  ...(size === "small" && {
    fontSize: "0.890625rem",
    letterSpacing: "0.12px",
    paddingBlock: "0.75rem",
    paddingInline: "1rem"
  }),
  "&:hover": {
    backgroundColor: theme.palette.gray[950],
    opacity: 0.8
  },
  "&:active": {
    backgroundColor: theme.palette.gray[400],
    boxShadow: "none"
  },
  "&:disabled": {
    opacity: 0.5,
    color: theme.palette.white.main,
    "&:after": {
      boxShadow: "none",
    }
  },
  ...(bgColor === "primary" && {
    backgroundColor: theme.palette.blue[500],
    transform: "skewX(-5deg)",
    "&:after": {
      backgroundColor: theme.palette.blue[500]
    },
    "&:hover": {
      backgroundColor: theme.palette.blue[400]
    },
    "&:active": {
      backgroundColor: theme.palette.blue[400]
    }
  })
}));
