import React from "react";
import { Stack } from "@mui/material";
import { Spinner } from "../../../../components";

export const DeliveriesChartLoading = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height={520} sx={{ marginTop: -12 }}>
      <Spinner />
    </Stack>
  );
};
