import { getRequest } from "core/api/base";
import { PROFILE_TOKEN } from "core/constants";

const campaignsUrl = "/mailsystem/api/v1/campaigns/";

export class CampaignsService {
  static fetchCampaigns = () => {
    const params = new URLSearchParams(window.location.search);
    return getRequest({
      url: campaignsUrl,
      params,
      token: PROFILE_TOKEN
    });
  };
}
