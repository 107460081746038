import { FormControl, InputLabel, Grid, Stack, Typography } from "@mui/material";
import { Button, VmtaPoolSelect } from "ui/components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DomainStyledInput, StyledInputContainer, StyledInputPrepend } from "./styled";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";
import { addSendingDomain } from "core/store/entities/Domain/domain.actions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getVmtaPools } from "../../../../core/store/entities/Domain/domain.actions";

const schema = yup.object({
  url: yup
    .string()
    .required("Specify domain")
    .matches(/^[0-9a-z.-]+$/, "This field should not contain spaces and capitals"),
  vmta_pool_id: yup
    .string()
    .test(
      "not-empty-option",
      "Make a selection",
      value => value !== "empty_option" && !!value
    )
});

export const AddDomain = () => {
  const dispatch = useDispatch();
  const defaultVmtaPool = useSelector(domainSelectors.defaultVmtaPool);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    reset({ vmta_pool_id: defaultVmtaPool?.id });
  }, [defaultVmtaPool?.id]);

  useEffect(() => {
    dispatch(getVmtaPools());
  }, []);

  const onSubmit = values => {
    const data = {
      ...values,
      vmta_pool_id: values.vmta_pool_id ?? defaultVmtaPool?.id,
      is_initial: false
    };
    dispatch(addSendingDomain(data));
  };

  return (
    <Stack>
      <Stack>
        <Stack gap={2.5}>
          <Typography variant="h2">Add a sending domain</Typography>
          <Typography variant="body2">
            First, enter your sending domain (a domain that you own). Next, you will be required to
            update your DNS records.
          </Typography>
        </Stack>
        <Stack
          spacing={1.5}
          mt={4.1}
          alignItems="flex-start"
          sx={{ maxWidth: { xs: "100%", sm: 320 } }}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Controller
                name="vmta_pool_id"
                control={control}
                render={({ field }) => (
                  <VmtaPoolSelect
                    {...field}
                    id="vmta_pool_id"
                    error={errors?.vmta_pool_id?.message}
                    helperText={errors?.vmta_pool_id?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInputContainer>
                <StyledInputPrepend>
                  <Typography variant="body2" lineHeight={1}>
                    http://
                  </Typography>
                </StyledInputPrepend>
                <Controller
                  name="url"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        component="label"
                        for="domain-url"
                        sx={{ visibility: "hidden", height: 0, width: 0 }}
                      >
                        Domain name
                      </InputLabel>
                      <DomainStyledInput
                        {...field}
                        error={errors?.url?.message}
                        helperText={errors?.url?.message}
                        id="domain-url"
                        placeholder="example.com"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  )}
                />
              </StyledInputContainer>
            </Grid>
          </Grid>
          <Button onClick={handleSubmit(onSubmit)}>Add domain</Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
