import { Box } from "@mui/material";
import { Header } from "ui/components";
import { AddCampaignButton } from "./components/AddCampaignButton";
import { CampaignsDataDisplay } from "./components/CampaignsDataDisplay";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchCampaigns } from "core/store/entities/Campaigns/campaigns.actions";

export const Campaigns = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);
  return (
    <Box>
      <Header title="Campaigns" headerRight={<AddCampaignButton />} />
      <CampaignsDataDisplay />
    </Box>
  );
};
