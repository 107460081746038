import { IconButton, Tooltip } from "@mui/material";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { useDispatch } from "react-redux";
import { Pencil, TrashBin } from "ui/assets/icons";
import { theme } from "ui/theme";
import { Wrapper } from "./styled";
import { showFeedbackPopup } from "core/store/entities/User/user.actions";
import { removeContactsField } from "core/store/entities/List/list.actions";

type Props = {
  label: string;
  id: string;
  required?: boolean;
};

export const ColumnContainer = ({ label, id, required }: Props) => {
  const editable = !required;
  const removable = !required;
  const dispatch = useDispatch();
  const handleEdit = () => {
    dispatch(showFormModal({ id, type: "customColumn" }));
  };

  const handleRemove = () => {
    if (!removable) return;
    dispatch(
      showFeedbackPopup({
        content: [
          `Delete ${label} field?`,
          `Are you sure you want to permanently delete ${label} field and all its data?`,
          `The data associated with the field will be deleted permanently.`
        ],
        onConfirm: () => {
          dispatch(removeContactsField(id));
        }
      })
    );
  };
  return (
    <Wrapper>
      {label}
      <span>
        <Tooltip id="rename-tooltip" title={`Edit ${label} column`}>
          <IconButton aria-labelledby="rename-tooltip" onClick={handleEdit} disabled={!editable}>
            <Pencil
              style={{
                fontSize: "1rem",
                color: editable ? theme.palette.black : theme.palette.gray[100]
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip id="remove-tooltip" title={`Remove ${label} column`}>
          <IconButton aria-labelledby="remove-tooltip" disabled={!removable} onClick={handleRemove}>
            <TrashBin
              style={{
                color: removable ? theme.palette.error.main : theme.palette.error[50],
                fontSize: "1rem"
              }}
            />
          </IconButton>
        </Tooltip>
      </span>
    </Wrapper>
  );
};
