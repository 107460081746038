import { forwardRef, useEffect } from "react";
import { Wrapper } from "./styled";
import { type CsvObject } from "core/types";
import { getRowsData } from "./getRowsData";
import { useColumnsTableColumnsMap } from "./useColumnsTableColumnsMap";
import { CustomColumnsTable } from "../CustomColumnsTable";
import { setInitFieldsMap } from "core/store/entities/ImportFlow/importFlow.actions";
import { useDispatch } from "react-redux";

type Props = {
  headerLabels: string[];
  rows: CsvObject[];
};

export const CustomColumnsTableWrapper = forwardRef(({ headerLabels, rows }: Props, ref) => {
  const dispatch = useDispatch();
  const mappedColumns = useColumnsTableColumnsMap(headerLabels);
  const data = getRowsData(mappedColumns, rows);
  useEffect(() => {
    dispatch(setInitFieldsMap(mappedColumns));
  }, [mappedColumns]);

  return (
    <Wrapper>
      <CustomColumnsTable rows={data} ref={ref} />
    </Wrapper>
  );
});
