import React from "react";
import { Stack, Typography } from "@mui/material";
import { BorderedBlock, Spinner, Table } from "ui/components";
import { useISPTable } from "./useISPTable";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";

export const ISPTable = () => {
  const { isLoading, headers, rows } = useISPTable();
  const { currentPeriodText } = useCurrentPeriodText({ compareToPrevious: true });

  return (
    <BorderedBlock>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="end" spacing={2}>
          <Typography variant="h5" component="h2" fontWeight={560}>
            ISP&apos;s sorted on volume
          </Typography>
          <Typography variant="small">{currentPeriodText}</Typography>
        </Stack>
        {isLoading ? <Spinner /> : <Table headers={headers} data={rows} />}
      </Stack>
    </BorderedBlock>
  );
};
