import { Select } from "ui/components";
import { useRenderCustomColumnSelect } from "./useRenderCustomColumnSelect";
import { useDispatch, useSelector } from "react-redux";
import { addColumnsFieldsMapping } from "core/store/entities/ImportFlow/importFlow.actions";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { theme } from "ui/theme";
const reservedColumns = ["email", "status", "ip", "created"];

type Props = {
  columnName: string;
  mappedColumnName?: string;
  value?: string | null;
  disabled?: boolean;
};

export const CustomColumnSelect = ({ columnName }: Props) => {
  const value = useSelector(state => importFlowSelectors.mappedColumn(state, columnName));
  const isDuplicatedMappedColumn = useSelector(state =>
    importFlowSelectors.isDuplicatedMappedColumn(state, columnName)
  );
  const dispatch = useDispatch();
  const { renderSelectedValue, options } = useRenderCustomColumnSelect(columnName);

  const disabled = reservedColumns.includes(columnName);

  const handleChange = (e: PointerEvent) => {
    dispatch(addColumnsFieldsMapping({ columnName, mappedColumn: e.target?.value }));
  };

  return (
    <Select
      value={value ?? ""}
      onChange={handleChange}
      sx={{ minWidth: 200, mx: 1 }}
      options={options}
      disabled={disabled}
      error={!value || isDuplicatedMappedColumn}
      label="Select field name"
      displayEmpty
      renderValue={renderSelectedValue}
    />
  );
};
