import { Typography } from "@mui/material";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { useSelector } from "react-redux";
import { theme } from "ui/theme";

export const DuplicateMappedColumnsError = () => {
  const hasDuplicatedMappedColumns = useSelector(importFlowSelectors.hasDuplicatedMappedColumns);

  if (!hasDuplicatedMappedColumns) {
    return null;
  }

  return (
    <Typography sx={{ color: theme.palette.error.main }}>
      There cannot be two columns with the same name
    </Typography>
  );
};
