import { ContactFieldBase, TableCellAlign, TableHeaderCell } from "core/types";
import { CustomColumnSelect } from "../CustomColumnSelect";

export const useColumnsTableHeaders = (
  columns: Array<ContactFieldBase & { mapped_column?: string }>
) => {
  const headers: TableHeaderCell[] = [
    ...(columns ?? []).map(({ column_name, mapped_column }) => {
      return {
        name: <CustomColumnSelect columnName={column_name} mappedColumnName={mapped_column} />,
        align: "left" as TableCellAlign
      };
    })
  ];

  return headers;
};
