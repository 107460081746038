import { contactSelectors } from "core/store/entities/Contact/contact.selectors";
import { RootState } from "core/store/reducers";
import type { Contact, ContactField } from "core/types";
import { useSelector } from "react-redux";

const generateIdDataTypeMap = (contactFields: ContactField[]) =>
  Object.fromEntries((contactFields ?? []).map(({ column_name, data_type }) => [column_name, data_type]));

const buildDefaultValues = (contactFields: ContactField[], contact?: Contact) =>
  Object.fromEntries(
    (contactFields ?? []).map(({ column_name, default: defaultValue }) => [
      column_name,
      contact?.[column_name] ?? defaultValue
    ])
  );

export const useContactFields = (contactId?: string | null) => {
  const contact = useSelector((state: RootState) => contactSelectors.contact(state, contactId));
  const contactFields = useSelector(contactSelectors.contactFields);
  const contactFieldsIds = contactFields?.map(({ id }) => id);
  const contactEditableFields = useSelector(contactSelectors.contactEditableFields);
  const contactFieldsError = useSelector(contactSelectors.error);

  const idDataTypeMap = generateIdDataTypeMap(contactFields ?? []);
  const defaultValues = buildDefaultValues(contactEditableFields ?? [], contact);
  const isDateType = (id: string) => ["date", "datetime"].includes(idDataTypeMap[id]);

  return {
    contactFields,
    contactEditableFields,
    defaultValues,
    isDateType,
    contactFieldsError,
    contactFieldsIds
  };
};
