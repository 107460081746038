import { call, put, select, takeLatest } from "redux-saga/effects";
import * as domainActions from "./domain.actions";
import { DomainService } from "core/api";
import { getUser, showFeedbackPopup } from "../User/user.actions";
import { push } from "redux-first-history";
import {
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  SETUP_DOMAIN_SCREEN,
  DASHBOARD_SCREEN
} from "core/constants";
import { domainSelectors } from "./domain.selectors";
import { userActions } from "../User/user.slice";

function* addSendingDomainHandler({ payload }) {
  try {
    const { id } = yield call(DomainService.add, payload);
    yield put(domainActions.addSendingDomainSuccess());
    yield put(push(`${SETUP_DOMAIN_SCREEN}/${id}`));
    yield put(getUser());
  } catch (error) {
    const [title, ...content] = error.url.split(".");
    yield put(showFeedbackPopup([title, content.join(".")]));
    yield put(domainActions.addSendingDomainFailed(error));
  }
}

function* getSendingDomainsHandler() {
  try {
    const data = yield call(DomainService.get);
    yield put(domainActions.getSendingDomainsSuccess(data.results));
  } catch (error) {
    yield put(domainActions.addSendingDomainFailed());
  }
}

function* deleteSendingDomainHandler({ payload }) {
  try {
    yield call(DomainService.remove, payload);
    yield put(domainActions.deleteSendingDomainSuccess(payload));
    const domains = yield select(domainSelectors.domains);
    if (!domains.length) {
      yield put(push(DASHBOARD_SCREEN));
    }
  } catch (error) {
    const [title, ...content] = error.url.split(".");
    yield put(showFeedbackPopup([title, content.join(".")]));
    yield put(domainActions.deleteSendingDomainFailed());
  }
}

function* getDnsRecordsHandler({ payload }) {
  try {
    const data = yield call(DomainService.getDnsRecords, payload);
    yield put(domainActions.getDnsRecordsSuccess(data.dns_data));
  } catch (error) {
    yield put(domainActions.getDnsRecordsFailed(error));
  }
}
function* getVmtaPoolsHandler() {
  try {
    const data = yield call(DomainService.getVmtaPools);
    yield put(domainActions.getVmtaPoolsSuccess(data));
  } catch (error) {
    yield put(domainActions.getVmtaPoolsFailed(error));
  }
}

function* verifyDomainHandler({ payload }) {
  try {
    yield call(DomainService.verify, payload);
    yield put(domainActions.verifyDomainSuccess());
    yield put(push(DASHBOARD_SENDING_DOMAINS_SCREEN));
  } catch (error) {
    yield put(showFeedbackPopup(["Could not verify domain", "Please try again"]));
    yield put(domainActions.getDnsRecordsFailed(error));
  }
}

export default function* domainSaga() {
  yield takeLatest(domainActions.addSendingDomain.type, addSendingDomainHandler);
  yield takeLatest(domainActions.getDnsRecords.type, getDnsRecordsHandler);
  yield takeLatest(
    [
      domainActions.addSendingDomainSuccess.type,
      domainActions.getSendingDomains.type,
      userActions.getUserSuccess.type
    ],
    getSendingDomainsHandler
  );
  yield takeLatest(domainActions.getVmtaPools.type, getVmtaPoolsHandler);
  yield takeLatest(domainActions.deleteSendingDomain.type, deleteSendingDomainHandler);
  yield takeLatest(domainActions.verifyDomain.type, verifyDomainHandler);
}
