import { urlParamChange } from "./urlParamChange";

const urlPageParamChange = urlParamChange("page");

export const urlPageParamGoPrevious = (totalPages: number) => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = +(urlParams.get("page") ?? 1);
  const prevPage = currentPage - 1;
  if (totalPages === 1 || prevPage === 0) {
    urlPageParamChange();
  } else if (totalPages === 0) {
    urlPageParamChange("" + prevPage);
  }
}