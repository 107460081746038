import { Typography } from "@mui/material";
import React from "react";
import { Link } from "ui/components";
import { SETUP_DOMAIN_SCREEN } from "core/constants";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";
import { useSelector } from "react-redux";

export const VerifyDomain = () => {
  const domainId = useSelector(domainSelectors.firstDomainId);

  return (
    <Typography variant="bodyM">
      You will need to <Link to={`${SETUP_DOMAIN_SCREEN}/${domainId}`}>verify</Link> your domain in
      order to continue to the next step
    </Typography>
  );
};
