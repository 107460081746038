import React from "react";
import { Link, PlusIcon } from "ui/components";
import { Stack } from "@mui/material";
import { DASHBOARD_SENDING_DOMAINS_SCREEN } from "core/constants/routes";

export const DomainRequest = () => {
  return (
    <Stack direction="row" spacing={1} mt="15px">
      <PlusIcon />
      <Link to={DASHBOARD_SENDING_DOMAINS_SCREEN}>Add a new sending domain</Link>
    </Stack>
  );
};
