import { FileUploadProgress } from "ui/components/Progress/FileUploadProgress";
import { Box } from "@mui/material";

type Props = {
  fileName: string;
  fileSize: number;
  onDelete: () => void;
  progress: number;
  error?: unknown;
};

export const FileReadProgress = ({ fileName, fileSize, onDelete, progress, error }: Props) => {
  if (error) {
    console.error("FileReadProgress error: ", error);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <FileUploadProgress
        progressValue={progress}
        fileName={fileName}
        fileSize={fileSize}
        errorState={!!error}
        onDelete={onDelete}
      />
    </Box>
  );
};
