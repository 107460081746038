import { Stack, styled } from "@mui/material";

export const PaymentCardContainer = styled(Stack)(({ theme, isSelected, isLoading }) => ({
  position: "relative",
  borderRadius: "0.375rem",
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: "1.125rem 1.25rem",
  cursor: "pointer",
  "& img": {
    borderRadius: "0.375rem",
    overflow: "hidden"
  },
  ...(isSelected && {
    border: `2px solid ${theme.palette.black}`,
    cursor: "auto"
  }),
  ...(isLoading && {
    opacity: 0.5,
    cursor: "wait",
    "& > *": {
      userSelect: "none",
      pointerEvents: "none"
    }
  })
}));

export const PaymentCardBody = styled(Stack)(() => ({}));
export const PaymentCardLoader = styled(Stack)(() => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%);"
}));
export const PaymentCardData = styled(Stack)(() => ({}));
export const PaymentCardNumber = styled(Stack)(() => ({
  alignItems: "center",
  marginTop: -8
}));
export const PaymentCardInfo = styled(Stack)(() => ({}));
export const PaymentCardActions = styled(Stack)(() => ({}));
export const PaymentCardSelected = styled(Stack)(() => ({}));
