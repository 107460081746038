import {
  FormControlLabelProps,
  FormControlLabel,
  CheckboxProps as MuiCheckboxProps
} from "@mui/material";
import { CheckboxUnchecked, CheckboxChecked } from "ui/assets/icons";
import { StyledCheckbox } from "./styled";
import { ChangeEvent } from "react";

type Props = Omit<FormControlLabelProps, "control"> & {
  checkBoxProps?: MuiCheckboxProps;
};

export const Checkbox = ({ checkBoxProps, checked, ...props }: Props) => {
  return (
    <FormControlLabel
      {...props}
      control={
        <StyledCheckbox
          size="small"
          {...checkBoxProps}
          checkedIcon={<CheckboxChecked />}
          icon={<CheckboxUnchecked />}
          role="checkbox"
          aria-checked={checked}
          checked={checked}
        />
      }
    />
  );
};

export type CheckboxProps = Props;
