import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BackIcon } from "ui/components/Icons/BackIcon";
import { StyledNavContainer, StyledContent, StyledWrapperGrid } from "./styled";
import { BackButton } from "./BackButton";

export const NestedLayout = () => {
  return (
    <StyledWrapperGrid item xs={12}>
      <StyledContent>
        <StyledNavContainer>
          <BackButton />
        </StyledNavContainer>
        <Outlet />
      </StyledContent>
    </StyledWrapperGrid>
  );
};
