import { SettingRoute } from "ui/navigation/routes/settings";
import {
  ADD_DOMAIN_STEP,
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  SETTINGS_LOG_FILES_SCREEN,
  TOTAL_STEPS
} from "core/constants";

export const disabledSettingsRoutesChecks: Record<SettingRoute, (user: any) => boolean> = ({
  [DASHBOARD_BILLING_SCREEN]: user => user.currentStep < ADD_DOMAIN_STEP,
  [DASHBOARD_SENDING_DOMAINS_SCREEN]: user => !user.hasDomain,
  [CONNECT_SMTP_SCREEN]: user => user.currentStep < TOTAL_STEPS,
  [CONNECT_API_SCREEN]: user => user.currentStep < TOTAL_STEPS,
  [SETTINGS_LOG_FILES_SCREEN]: user => user.currentStep < TOTAL_STEPS
})