import { Box, outlinedInputClasses, styled } from "@mui/material";
import { Input } from "../../../components/Inputs/Input";

export const DomainStyledInput = styled(Input)(({ theme }) => ({
  [`.${outlinedInputClasses.input}`]: {
    paddingInline: "0.75rem",
    paddingBlock: "0.875rem"
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderWidth: 1,
    borderColor: theme.palette.gray[250],
    borderStyle: "solid"
  }
}));

export const StyledInputPrepend = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  borderWidth: 1,
  borderColor: theme.palette.gray[250],
  borderStyle: "solid",
  borderRight: 0,
  paddingInline: "0.75rem",
  paddingBlock: "0.875rem",
  color: theme.palette.gray[350]
}));

export const StyledInputContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
}));
