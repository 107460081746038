import { ContactField, ContactFieldBase, MappedColumn } from "core/types";
import { importFlowReducers } from "./importFlow.reducers";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listActions } from "../List/list.slice";

export type ImportFlowState = {
  selectedFile: File | null;
  selectedFileTextContent?: string;
  shouldReplaceContacts: boolean;
  selectedColumnName?: string;
  initColumnsFieldsMap: Record<string, ContactFieldBase & MappedColumn>;
  addedNewFields: boolean;
  columnsFieldsMap: Record<string, string | null | undefined>;
};

const initialState: ImportFlowState = {
  selectedFile: null,
  shouldReplaceContacts: true,
  initColumnsFieldsMap: {},
  addedNewFields: false,
  columnsFieldsMap: {}
};

const importFlowSlice = createSlice({
  name: "importFlow",
  initialState,
  reducers: importFlowReducers,
  extraReducers: builder =>
    builder.addCase(
      listActions.addNewContactsFieldSuccess,
      (state: ImportFlowState, { payload }: PayloadAction<ContactField>) => {
        const currentSelectedColumnName = state.selectedColumnName;
        if (currentSelectedColumnName) {
          state.columnsFieldsMap[currentSelectedColumnName] = payload.column_name;
        }
        state.addedNewFields = true;
      }
    )
});

const importFlowActions = importFlowSlice.actions;
const importFlowReducer = importFlowSlice.reducer;

export { importFlowActions, importFlowReducer };
