import { RootState } from "core/store/reducers";
import { createSelector } from "reselect";

export const pageSize = 25;

const selectSelf = (state: RootState) => state.campaigns;
const selectFetchStatus = createSelector(
  selectSelf,
  campaignsState => campaignsState.fetchCampaignsStatus
);
const selectCampaigns = createSelector(
  selectSelf,
  campaignsState => campaignsState.campaigns
);

const selectIsFetchStatusSuccess = createSelector(
  selectFetchStatus,
  status => status === "success"
);
const selectIsFetchStatusLoading = createSelector(
  selectFetchStatus,
  status => status === "loading"
);
const selectError = createSelector(
  selectSelf,
  (campaignsState) => campaignsState.error
);
const selectPagination = createSelector(selectSelf, state => state.pagination);

export const campaignsSelectors = {
  campaigns: selectCampaigns,
  totalCount: createSelector(selectPagination, pagination => pagination?.total_count ?? 0),
  totalPages: createSelector(selectPagination, pagination => pagination?.total_pages ?? 1),
  error: selectError,
  campaignsLoading: selectIsFetchStatusLoading,
  campaignsFetchedSuccess: selectIsFetchStatusSuccess,
  duplicateCampaignLoading: createSelector(
    selectSelf,
    campaignsState => campaignsState.duplicateCampaignStatus === "loading"
  ),
  duplicateCampaignSuccess: createSelector(
    selectSelf,
    campaignsState => campaignsState.duplicateCampaignStatus === "success"
  ),
  duplicateCampaignError: createSelector(
    selectSelf,
    campaignsState => campaignsState.duplicateCampaignStatus === "error"
  ),
  deleteCampaignLoading: createSelector(
    selectSelf,
    campaignsState => campaignsState.deleteCampaignStatus === "loading"
  ),
  deleteCampaignSuccess: createSelector(
    selectSelf,
    campaignsState => campaignsState.deleteCampaignStatus === "success"
  ),
  deleteCampaignError: createSelector(
    selectSelf,
    campaignsState => campaignsState.deleteCampaignStatus === "error"
  ),
  currentPage: createSelector(selectPagination, pagination => pagination?.page)
};
