import { Alert, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useInvoicesTable } from "./useInvoicesTable";
import { Table, Spinner, RegularButton } from "ui/components";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "core/store/entities/Billing/billing.actions";
import { billingInvoicesSelectors } from "core/store/entities/Billing/billing.selectors";
import { BorderedBlock } from "ui/components";
import { NO_BILLING_INFO_ERROR } from "core/constants";

export const InvoicesTable = () => {
  const dispatch = useDispatch();
  const hasMoreInvoices = useSelector(billingInvoicesSelectors.hasMore);
  const { headers, rows, isInvoicesLoading, invoicesErrors, noInvoicesText } = useInvoicesTable();
  const showError = invoicesErrors && invoicesErrors.detail !== NO_BILLING_INFO_ERROR;


  const handleMore = () => {
    dispatch(getInvoices());
  };

  return (
    <Grid container item xs mt={1}>
      <BorderedBlock sx={{ width: "100%" }}>
        <Stack spacing={2} alignItems="flex-start">
          <Typography variant="h5" component="h2" fontSize="1.125rem" lineHeight="1.75">
            Invoices
          </Typography>
          {showError && (
            <Alert variant="filled" severity="error">
              {invoicesErrors?.detail}
            </Alert>
          )}
          {isInvoicesLoading ? (
            <Spinner />
          ) : (
            <Table headers={headers} data={rows} emptyText={noInvoicesText} />
          )}
          <RegularButton onClick={handleMore} disabled={!hasMoreInvoices}>
            Show older invoices
          </RegularButton>
        </Stack>
      </BorderedBlock>
    </Grid>
  );
};
