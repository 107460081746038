import { Add } from "@mui/icons-material";
import { Button } from "ui/components";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { useDispatch } from "react-redux";

export const AddListButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showFormModal({ id: null, type: "list" }));
  };

  return (
    <Button startIcon={<Add />} onClick={handleClick}>
      New list
    </Button>
  );
};
