import { RootState } from "core/store/reducers";
import { createSelector } from "reselect";

const selectSelf = (state: RootState) => state.list;
const selectList = createSelector(selectSelf, listState => listState.list);
const selectListFetchStatus = createSelector(selectSelf, listState => listState.fetchListStatus);
const selectListError = createSelector(selectSelf, listState => listState.error);

export const listSelectors = {
  isListInStore: createSelector(selectSelf, listState => !!listState.list && !!listState.list.id),
  error: selectListError,
  list: selectList,
  listColor: createSelector(selectList, list => list?.color),
  listLabel: createSelector(selectList, list => list?.label),
  listFields: createSelector(selectList, list => list?.sorted_fields),
  listId: createSelector(selectList, list => list?.id),
  loading: createSelector(selectListFetchStatus, status => status === "loading"),
  success: createSelector(selectListFetchStatus, status => status === "success"),
  fetchError: createSelector(selectListFetchStatus, selectListError, (status, error) => status === "error" ? error : null),
  updateListStatus: createSelector(selectSelf, listState => listState.updateListStatus),
  editContactsFieldError: createSelector(selectSelf, listState => listState.editContactsFieldStatus === "error"),
  addNewContactsFieldError: createSelector(selectSelf, listState => listState.addNewContactsFieldStatus === "error"),
  removeContactsFieldError: createSelector(selectSelf, listState => listState.removeContactsFieldStatus === "error"),
};
