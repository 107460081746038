import React, { useRef } from "react";
import { Box, Collapse, List, ListItem, Stack, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { StyledNavItem, StyledSubNavItem } from "./styled";
import { useDefineMobileView } from "hooks";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { SHOW_NAVIGATION } from "core/constants";

export const NavItem = ({
  id,
  to,
  isActive,
  isDisabled,
  name,
  Icon,
  items
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const isMobile = useDefineMobileView();
  const location = useLocation();
  const hasSubmenu = !!items && items.length > 0;
  const active = isActive(location);

  const handleClick = () => {
    if (isMobile) {
      return dispatch(hideModal(SHOW_NAVIGATION));
    }
  }

  return (
    <Box>
      <StyledNavItem
        to={to}
        hasSubmenu={hasSubmenu}
        isActive={active}
        isDisabled={isDisabled}
        data-testid={`nav-link-${id}`}
        onClick={handleClick}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center">
            {Icon && <Icon ref={ref} style={{ textAlign: "center", fontSize: "0.875rem" }} />}
            {name && (
              <Typography
                fontWeight={active ? 600 : 460}
                fontSize="0.921875rem"
                lineHeight="1.25rem"
              >
                {name}
              </Typography>
            )}
          </Stack>
          {hasSubmenu && (
            <KeyboardArrowDown
              sx={{
                fontSize: "1rem",
                transform: `rotate(${active ? "0" : "-90"}deg)`,
                transition: "transform 0.3s ease"
              }}
            />
          )}
        </Stack>
        {hasSubmenu && (
          <Collapse in={active} unmountOnExit>
            <List sx={{ p: 0 }}>
              {items.map((item, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <StyledSubNavItem
                    to={item.to}
                    isActive={item.isActive(location) && !item.isDisabled}
                    isDisabled={item.isDisabled}
                    onClick={handleClick}
                  >
                    <Typography fontWeight={item.isActive(location) ? 600 : 460} color="inherit">
                      {item.name}
                    </Typography>
                  </StyledSubNavItem>
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </StyledNavItem>
    </Box>
  );
};
