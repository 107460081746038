import { setSelectedFile, setSelectedFileTextContent } from "core/store/entities/ImportFlow/importFlow.actions";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { useDispatch, useSelector } from "react-redux";

export const useFileBrowseUpload = () => {
  const dispatch = useDispatch();
  const file = useSelector(importFlowSelectors.selectedFile);

  const onFileChange = (file: File | null) => {
    dispatch(setSelectedFile(file));
  }

  const onFileClear = () => {
    dispatch(setSelectedFile(null));
  }

  return {
    file, onFileChange, onFileClear
  }
}