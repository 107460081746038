import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resendEmail } from "core/store/entities/User/user.actions";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { RegularButton } from "ui/components";

export const ResendEmail = () => {
  const userEmail = useSelector(userSelectors.userEmail);
  const dispatch = useDispatch();

  const handleResendEmail = () => {
    dispatch(resendEmail());
  };

  return (
    <Stack>
      <Typography variant="bodyM">
        {`Confirm the verification email sent to ${userEmail}`}
      </Typography>
      <Typography variant="bodyM">This email expires within 24 hours.</Typography>
      <Stack direction="row" spacing={1} alignItems="center" mt={1} justifyContent="flex-end">
        <RegularButton variant="outlined" onClick={handleResendEmail}>
          Resend email
        </RegularButton>
      </Stack>
    </Stack>
  );
};
