import { useContactsTableData } from "./useContactsTableData";
import { Table } from "ui/components";

type Props = {
  emptyText?: string;
};

export const ContactsTableDisplay = ({ emptyText }: Props) => {
  const { headers, rows } = useContactsTableData();
  return <Table data={rows} headers={headers} emptyText={emptyText} />;
};
