import type { ContactFieldBase, CsvObject, MappedColumn, TableRow } from "core/types";
import { CustomColumnsTableRowContent } from "../CustomColumnsTableRowContent";

export const getRowsData = (columns: Array<ContactFieldBase & MappedColumn>, rows: CsvObject[]) => {
  const data: TableRow[] =
    (rows ?? []).slice(0, 3).map((row, index) => {
      return {
        key: index,
        columns: columns.map(({ column_name }) => {
          return {
            content: (
              <CustomColumnsTableRowContent value={row[column_name]} columnName={column_name} />
            ),
            align: "left"
          };
        })
      };
    }) || [];

  return data;
};
