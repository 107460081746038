import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { fetchContacts } from "core/store/entities/Contacts/contacts.actions";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "ui/components";
import { usePageUrlParam } from "hooks/useUrlParams";

export const ContactsPagination = () => {
  const { page, onPageChange } = usePageUrlParam();
  const dispatch = useDispatch();
  const totalPages = useSelector(contactsSelectors.totalPages);

  const handlePageChange = (value: number) => {
    onPageChange("" + value);
    dispatch(fetchContacts());
  };

  return (
    <Pagination
      page={+(page ?? 1)}
      count={totalPages}
      onChange={(_, page) => {
        handlePageChange(page);
      }}
    />
  );
};
