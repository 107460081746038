import { FormControl, FormGroup, Typography } from "@mui/material";
import { Checkbox, type CheckboxProps } from "ui/components";
import { SelectOption } from "core/types";

type Props = {
  label: string;
  options: SelectOption<any>[];
} & CheckboxProps;

export const CheckboxGroup = ({ options, label, value, onChange, ...props }: Props) => {
  const handleChange = (id: string) => (e: any) => {
    if (e.target.checked) onChange([...new Set([...value, id])]);
    else {
      onChange(value.filter(valueId => valueId !== id));
    }
  };

  const isChecked = (id: string) => value.includes(id);
  return (
    <FormControl component="fieldset">
      <Typography component="legend">{label}</Typography>
      <FormGroup>
        {options.map(({ label, value: id }) => (
          <Checkbox
            key={id}
            id={id}
            label={label}
            onChange={handleChange(id)}
            checked={isChecked(id)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
