import usePagination, { UsePaginationItem } from "@mui/material/usePagination";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, PaginationProps } from "@mui/material";

import { StyledList, StyledNav, StyledPageButton } from "./styled";
import { RegularButton } from "ui/components";

const renderItem = ({ page, type, ...item }: UsePaginationItem, index: number) => {
  let children = null;

  if (type === "start-ellipsis" || type === "end-ellipsis") {
    children = <Box sx={{ p: theme => theme.remScale[8] }}>...</Box>;
  } else if (type === "page") {
    children = (
      <StyledPageButton type="button" {...item}>
        {page}
      </StyledPageButton>
    );
  }

  return <li key={index}>{children}</li>;
};

type Props = PaginationProps;

export const Pagination = ({ count, page: currentPage, onChange }: Props) => {
  const paginationItems = usePagination({
    count,
    onChange,
    page: currentPage
  });

  const prevButton = paginationItems.items.at(0);
  const nextButton = paginationItems.items.at(-1);
  const items = paginationItems.items.slice(1, -1);

  if (items.length <= 1) {
    return null;
  }

  return (
    <StyledNav>
      <StyledList>
        <li>
          <RegularButton type="button" startIcon={<ArrowBack />} {...prevButton}>
            Previous
          </RegularButton>
        </li>
        {items.map(renderItem)}
        <li>
          <RegularButton type="button" endIcon={<ArrowForward />} {...nextButton}>
            Next
          </RegularButton>
        </li>
      </StyledList>
    </StyledNav>
  );
};
