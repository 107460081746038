import { Snackbar as MuiSnackbar, Alert, AlertColor } from "@mui/material";
import { useState } from "react";

export const Snackbar = ({ severity, message }: { severity: AlertColor; message: string }) => {
  const [open, setOpen] = useState(true);
  return (
    <MuiSnackbar
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert severity={severity}>{message}</Alert>
    </MuiSnackbar>
  );
};
