import { Stack, styled } from "@mui/material";

export const StyledWrapper = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: 400,
  position: "relative",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "100vw"
  }
}));
export const StyledFooter = styled(Stack)(({ theme }) => ({
  borderTop: "1px solid #ECECEC",
  padding: theme.remScale[16],
  flexDirection: "row",
  gap: theme.remScale[20],
  alignItems: "center"
}));
