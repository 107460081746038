import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { sendMessage } from "core/store/entities/Messages/messages.actions";
import { useCustomerSupport } from "hooks";
import { messagesSelectors } from "core/store/entities/Messages/messages.selectors";

export const useCustomerSupportForm = initSubject => {
  const dispatch = useDispatch();
  const show = useSelector(messagesSelectors.showMessageModal);
  const themes = useSelector(messagesSelectors.themes);
  const errors = useSelector(messagesSelectors.modalErrors);
  const customerSupportSent = useSelector(messagesSelectors.customerSupportSent);
  const { control, handleSubmit, reset, setValue } = useForm();
  const { closeCustomerSupport } = useCustomerSupport();

  useEffect(() => {
    if (initSubject) {
      setValue("subject", initSubject.value);
    }
  }, [initSubject]);

  const handleResetForm = () => {
    reset({
      subject: "",
      text: ""
    });
  };

  useEffect(() => {
    if (customerSupportSent) {
      handleResetForm();
    }
  }, [customerSupportSent]);

  const onSubmit = data => {
    dispatch(sendMessage(data));
  };

  const handleClose = () => {
    closeCustomerSupport();
    handleResetForm();
  };

  return {
    show,
    themes,
    errors,
    control,
    onSubmit: handleSubmit(onSubmit),
    handleClose
  };
};
