import { ListApi } from "core/types";
import { listReducers } from "./list.reducers";

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { type Status } from "core/constants/status";

export type ListState = {
  list: ListApi | null;
  addNewContactsFieldStatus: Status;
  removeContactsFieldStatus: Status;
  editContactsFieldStatus: Status;
  fetchListStatus: Status;
  updateListStatus: Status;
  error?: unknown;
};

const initialState: ListState = {
  list: {
    id: "",
    color: "",
    label: "",
    description: "",
    sorted_fields: []
  },
  addNewContactsFieldStatus: "idle",
  editContactsFieldStatus: "idle",
  removeContactsFieldStatus: "idle",
  fetchListStatus: "idle",
  updateListStatus: "idle",
};

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: listReducers
});

const listActions = listSlice.actions;
const listReducer = listSlice.reducer;

export { listActions, listReducer };