import { fetchLists } from "core/store/entities/Lists/lists.actions";
import { listsSelectors } from "core/store/entities/Lists/lists.selectors";
import { usePageUrlParam } from "hooks/useUrlParams";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "ui/components";

export const ListsPagination = () => {
  const dispatch = useDispatch();
  const { page, onPageChange } = usePageUrlParam();
  const totalPages = useSelector(listsSelectors.totalPages);

  const handlePageChange = (value: number) => {
    onPageChange("" + value);
    dispatch(fetchLists());
  };

  return (
    <Pagination
      page={+(page ?? 1)}
      count={totalPages}
      onChange={(_, page) => {
        handlePageChange(page);
      }}
    />
  );
};
