import { CsvObject, CsvTableData } from "core/types";

// Read a CSV file (passed as a File object) and transforms it object to use in Table.
export async function transformCsvFileFromText(fileContent: string): Promise<CsvTableData> {
  const lines = fileContent.split('\n').filter(line => line.trim() !== '');
  if (lines.length < 2) {
    return {
      headers: [],
      rows: []
    };
  }

  const headers = lines[0].split(',').map(header => header.trim());
  const rows: CsvObject[] = lines.slice(1).map(line => {
    const regex = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/;
    const values = line.split(regex).map(value => value.trim());
    const obj: CsvObject = {};
    headers.forEach((header, index) => {
      const column_name = header.toLocaleLowerCase().split(" ").join("_");
      obj[column_name] = values[index] || '';
    });
    return obj;
  })

  return { headers, rows };
}