import { outlinedInputClasses, styled } from "@mui/material";
import { Input } from "../../Inputs";
import { Select } from "../../Selects";

export const StyledInput = styled(Input)(({ theme }) => ({
  [`.${outlinedInputClasses.input} `]: {
    ...theme.typography.body2,
    letterSpacing: 0.75,
    fontWeight: 360,
    height: "1em",
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: theme.remScale[6],
    "&:not(:focus-visible)": {
      borderColor: "rgba(184, 183, 181, 1)",
    }
  },
})) as Input;
export const StyledSelect = styled(Select)(({ theme }) => ({
  [`.${outlinedInputClasses.input} `]: {
    ...theme.typography.body2,
    letterSpacing: 0.75,
    fontWeight: 360,
    paddingBlock: 12
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "rgba(184, 183, 181, 1)",
    borderRadius: theme.remScale[6],
    "&:focus-visible": {
      borderColor: "rgba(0, 0, 0, 1)",
    }
  },
})) as Input;