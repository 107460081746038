import { PayloadAction } from "@reduxjs/toolkit";
import type { ModalFormEntity, ModalFormState } from "./modalForm.slice";

export const modalFormReducers = {
  showFormModal: (
    state: ModalFormState,
    { payload }: PayloadAction<{ id: string | null; type: ModalFormEntity }>
  ) => {
    state.entityId = payload.id;
    state.entityType = payload.type;
  },
  hideFormModal: () => { },
  resetFormModal: (state: ModalFormState) => {
    state.entityId = null;
    state.entityType = null;
  }
};
