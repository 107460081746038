import {
  Alert,
  Box,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { TextButton } from "ui/components";
import { Controller } from "react-hook-form";
import { StyledSelect, StyledInput } from "./styled";
import { ContactField } from "core/types";
import { useCustomColumnForm } from "./useCustomColumnForm";
import { Add, ErrorOutline } from "@mui/icons-material";
import { TrashBin } from "ui/assets/icons";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { useSelector } from "react-redux";

type Props = {
  formId: string;
  selectedColumn?: ContactField;
};

export const CustomColumnForm = ({ formId, selectedColumn }: Props) => {
  const editContactsFieldError = useSelector(listSelectors.editContactsFieldError);
  const addNewContactsFieldError = useSelector(listSelectors.addNewContactsFieldError);
  const listError = useSelector(listSelectors.error);
  const { control, errors, onSubmit, selectedType, choicesFieldArray, dataTypeOptions } =
    useCustomColumnForm(selectedColumn);
  const edit = !!selectedColumn?.id;

  if (listError) {
    console.error(listError);
  }

  const showError =
    (editContactsFieldError || addNewContactsFieldError) &&
    !!listError &&
    typeof listError === "string";

  return (
    <Stack gap={2.5} sx={{ pt: 1 }} component="form" onSubmit={onSubmit} id={formId}>
      {!edit && (
        <>
          <Controller
            name="data_type"
            control={control}
            rules={{
              validate: v => {
                if (!v || v === "empty_option") {
                  return "Make a selection";
                }
                return true;
              }
            }}
            render={({ field: { onChange, value } }) => (
              <Stack gap={1}>
                <InputLabel htmlFor="type">Type</InputLabel>
                <StyledSelect
                  value={value}
                  onChange={onChange}
                  label="Select type"
                  showEmptyOption
                  options={dataTypeOptions}
                  error={!!errors?.data_type?.message}
                  helperText={errors?.data_type?.message}
                />
              </Stack>
            )}
          />
        </>
      )}
      <Controller
        name="label"
        rules={{
          required: "This field is required"
        }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Stack gap={1}>
            <InputLabel htmlFor="name">Name</InputLabel>
            <StyledInput
              id="name"
              name="name"
              value={value}
              onChange={onChange}
              error={!!errors?.label?.message}
              helperText={errors?.label?.message}
              placeholder="Enter name for the field"
              InputProps={{
                endAdornment: !!errors?.label?.message && (
                  <ErrorOutline
                    sx={{ fontSize: "1.25em", color: theme => theme.palette.error.main }}
                  />
                )
              }}
            />
          </Stack>
        )}
      />
      {(selectedType === "choice" || selectedType === "multichoice") && (
        <Box>
          <FormLabel component="legend">
            <Typography fontWeight={560} color={theme => theme.palette.black}>
              {selectedType === "choice" ? "Add category:" : "Add option:"}
            </Typography>
          </FormLabel>
          <FormGroup>
            <Stack gap={2} sx={{ mt: 1 }}>
              {choicesFieldArray.fields.map((choicesField, index) => (
                <Controller
                  key={choicesField.id}
                  render={({ field }) => (
                    <Box>
                      <StyledInput
                        {...field}
                        name={field.name}
                        error={!!errors?.choices?.[index]?.message}
                        helperText={errors?.choices?.[index]?.message}
                        InputProps={{
                          endAdornment: !!errors?.choices?.[index]?.message && (
                            <ErrorOutline
                              sx={{
                                fontSize: "1.25em",
                                color: theme => theme.palette.error.main
                              }}
                            />
                          )
                        }}
                      />
                      <Tooltip
                        id={`${choicesField.id}-tooltip`}
                        title={`Remove ${field.value} category`}
                      >
                        <IconButton
                          aria-labelledby={`${choicesField.id}-tooltip`}
                          onClick={() =>
                            choicesFieldArray.fields.length > 0 && choicesFieldArray.remove(index)
                          }
                        >
                          <TrashBin />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  rules={{
                    required: "Cannot be empty"
                  }}
                  name={`choices.${index}`}
                  control={control}
                />
              ))}
              <TextButton
                sx={{ alignSelf: "baseline" }}
                startIcon={<Add />}
                onClick={() => choicesFieldArray.append("")}
              >
                New {selectedType === "choice" ? "category:" : "option:"}
              </TextButton>
            </Stack>
          </FormGroup>
        </Box>
      )}
      {showError &&
        (typeof listError === "object" ? (
          Object.values(listError).map(err => <Alert severity="error">{err}</Alert>)
        ) : (
          <Alert severity="error">Error submitting new data</Alert>
        ))}
    </Stack>
  );
};
