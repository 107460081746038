import React from "react";

import { Link } from "react-router-dom";

import { DASHBOARD_SCREEN } from "core/constants";
import { Box, List, ListItem } from "@mui/material";
import { Divider, Image, NavItem, ProfileMenu, RegularButton } from "ui/components";
import { ProgressBlock } from "../ProgressBlock";
import { useSelector } from "react-redux";
import { NavbarContainer } from "../styled";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { useUpgradePlan } from "hooks";

export const DesktopNavbar = ({ items }) => {
  const user = useSelector(userSelectors.user);
  const { openUpgradePlan, currentStep, isFreePlanUser } = useUpgradePlan();

  return (
    <NavbarContainer>
      <Box flex={1}>
        <Link to={DASHBOARD_SCREEN}>
          <Image
            name="inboxroadLogoSmall"
            customStyles={{
              marginTop: "1.75rem",
              marginBottom: "2.75rem",
              marginLeft: "0.5rem"
            }}
          />
        </Link>
        <List
          sx={{
            p: "0"
          }}
        >
          {items.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                p: 0,
                display: "block"
              }}
            >
              <NavItem
                id={item.id}
                to={item.to}
                name={item.name}
                Icon={item.Icon}
                onClick={item.onClick}
                isActive={item.isActive}
                isDisabled={item.isDisabled}
                items={item.items}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      {!user.isSetupFinished && <ProgressBlock />}
      {isFreePlanUser && (
        <RegularButton size="small" onClick={openUpgradePlan} disabled={currentStep <= 2}>
          Upgrade plan
        </RegularButton>
      )}
      <Divider
        orientation="horizontal"
        sx={{
          marginBlock: "1.25rem"
        }}
      />
      <ProfileMenu />
    </NavbarContainer>
  );
};
