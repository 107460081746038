import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { useSelector } from "react-redux";

type Props = {
  value: string;
  columnName: string;
};

export const CustomColumnsTableRowContent = ({ value, columnName }: Props) => {
  const doNotImport = useSelector(state =>
    importFlowSelectors.isNotImportedField(state, columnName)
  );
  return (
    <span style={{ marginInline: 8, textDecoration: doNotImport ? "line-through" : "none" }}>
      {value}
    </span>
  );
};
