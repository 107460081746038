import { Box, Grid, Stack, styled } from "@mui/material";

export const StyledWrapperGrid = styled(Grid)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.gray[75]
}))

export const StyledContent = styled(Stack)(() => ({
  height: "100%"
}))

export const StyledNavContainer = styled(Box)(({ theme }) => ({
  paddingInline: theme.remScale[24],
  paddingBlock: theme.remScale[22],
  backgroundColor: theme.palette.white.main,
  borderBottom: "1px solid #EAEAE6"
}))