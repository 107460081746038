import { rootReducer } from "./reducers";
import { enhancer, sagaMiddleware } from "./middlewares";
import { createReduxHistory } from "./utils/history";
import rootSaga from "./sagas";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  middleware: () => [sagaMiddleware],
  enhancers: () => [enhancer],
})

sagaMiddleware.run(rootSaga);

const history = createReduxHistory(store);

export { store, history };
