import { Divider, Stack } from "@mui/material";
import { ConnectItem } from "./ConnectItem";
import { CONNECT_API_SCREEN, CONNECT_SMTP_SCREEN } from "core/constants";

export const Connect = () => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      divider={
        <Divider
          orientation="vertical"
          sx={{ height: "auto !important", color: "rgba(0, 0, 0, 0.12)" }}
        />
      }
      spacing={8}
      pt={2}
      pb={4}
    >
      <ConnectItem
        icon="terminal"
        title="API"
        text="Connect through API"
        buttonText="Use the API"
        link={CONNECT_API_SCREEN}
      />
      <ConnectItem
        icon="smtp"
        title="SMTP"
        text="Connect through SMTP"
        buttonText="Use SMTP"
        link={CONNECT_SMTP_SCREEN}
      />
    </Stack>
  );
};
