import { Box, styled } from "@mui/material";
type StyledBoxProps = { color: string }

export const StyledBox = styled(Box)<StyledBoxProps>(({ color, theme }) => ({
  fontSize: theme.remScale[18],
  color: theme.palette.white.main,
  width: "100%",
  height: "100%",
  backgroundColor: color,
  borderRadius: "20%",
  overflow: "hidden"
}));
