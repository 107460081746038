import {
  Container,
  LineNumber,
  LinesNumberColumn,
  StyledCode,
  StyledCodeContainer,
  StyledCodeSpan,
  StyledPre
} from "./styled";
import { Highlight, themes } from "prism-react-renderer";
import { Box } from "@mui/material";
import { useFormEmbedContext } from "../../context/FormEmbedContextProvider";

type Props = {
  visible: boolean;
};

export const CodeTab = ({ visible }: Props) => {
  const { generatedCode } = useFormEmbedContext();
  return (
    <Container
      sx={{
        display: visible ? "block" : "none",
        fontSize: theme => theme.remScale[12.75]
      }}
    >
      <Box
        sx={{
          display: "flex"
        }}
      >
        <Highlight theme={themes.oneLight} code={generatedCode} language="html">
          {({ tokens, getLineProps, getTokenProps }) => (
            <StyledCodeContainer>
              <LinesNumberColumn>
                {tokens.map((_, i) => (
                  <LineNumber key={i + 1}>{i + 1}</LineNumber>
                ))}
              </LinesNumberColumn>
              <StyledPre>
                <StyledCode>
                  {tokens.map((line, i) => (
                    <Box key={i} {...getLineProps({ line })}>
                      {line.map((token, key) => (
                        <StyledCodeSpan
                          key={key}
                          {...getTokenProps({ token })}
                          style={{ color: "rgba(104, 103, 100, 1)" }}
                        />
                      ))}
                    </Box>
                  ))}
                </StyledCode>
              </StyledPre>
            </StyledCodeContainer>
          )}
        </Highlight>
      </Box>
    </Container>
  );
};
