import { MenuItemProps, TableCellProps } from "@mui/material";
import { type ReactNode } from "react";

export type CsvObject = { [key: string]: string };
export type CsvTableData = { headers: string[]; rows: CsvObject[] };

export type PaginationApi = {
  page: number;
  page_size: number;
  total_count: number;
  total_pages: number;
};

export type MappedColumn = {
  mapped_column?: string;
}

export type SelectOption<ValueType> = {
  label: ReactNode;
  value: ValueType;
};

export type ContactsTableAction = "edit" | "delete" | "unsubscribe" | "subscribe";

export type ListsTableAction = "edit" | "delete" | "duplicate" | "embed_form";

export type ContactFieldDataType =
  | "date"
  | "datetime"
  | "boolean"
  | "integer"
  | "float"
  | "email"
  | "character"
  | "choice"
  | "pattern"
  | "multichoice";

export type ContactsApi = {
  contacts: Contact[];
  pagination: PaginationApi;
};

export type ExportContactsProgress = {
  success: boolean;
  ready: boolean;
  progress_percentage: number;
  file_url: "string";
};

export type ContactStatus = "subscribed" | "unsubscribed" | "blocked";

export interface Contact {
  id: string;
  email: string;
  status: ContactStatus;
  [key: string]: any;
}

export type VmtaPool = {
  id: number;
  name: string;
};

export type ContactField = {
  choices: Record<string, string>;
  column_name: string;
  data_type: ContactFieldDataType;
  default: unknown | null;
  functional_default: boolean;
  id: string;
  label: string;
  length: number;
  pattern: string | null;
  read_only: boolean;
  required: boolean;
  unique: boolean;
  unused: boolean;
};

export type ContactFieldBase = Pick<ContactField, "id" | "column_name" | "label">;

export type ListRecordApi = Omit<ListApi, "sorted_fields"> & {
  created_at: string;
  subscribers_count: number;
  unsubscribes_count: number;
  bouncers_count: number;
};

export type ListApi = {
  id: string;
  color: string;
  label: string;
  description: string;
  sorted_fields: ContactField[];
};

export type ListUpdateFields = Pick<ListApi, "color" | "label" | "description">;

export type ListsRecordsResponse = {
  items: ListRecordApi[];
  count: number;
};

export type TableHeaderCell = { name: ReactNode; align: TableCellAlign };

export type TableColumn = {
  align: TableCellAlign;
  content: string | ReactNode;
};

export type TableRow = {
  key: string | number;
  columns: TableColumn[];
};

export type TableCellAlign = TableCellProps["align"];

export type MenuItem<ActionType extends string> = {
  "aria-label": string;
  icon?: ReactNode;
  id: ActionType;
  name: string;
  onClick?: () => void;
  divider?: boolean;
};

export type HasSubmenu<SubmenuType extends string> = { submenu: MenuItem<SubmenuType>[] };

export const CampaignStatusEnum = {
  sent: "sent",
  blocked: "blocked",
  cancelled: "cancelled",
  draft: "draft",
  scheduled: "scheduled"
} as const;
export type CampaignStatus = keyof typeof CampaignStatusEnum;
export const isCampaignStatus = (x: string | null | undefined): x is CampaignStatus => {
  if (x === null || x === undefined) return false;
  return CampaignStatusEnum[x as CampaignStatus] !== undefined;
};

export type CampaignApiRecord = {
  id: string;
  name: string;
  status: CampaignStatus;
  task_id: string;
  updated_at: string;
  clicks: string;
  delivers: number;
  opens: string;
  progress_percentage: number;
};

export type CampaignApi = Exclude<CampaignApiRecord, "updated_at"> & {
  "subject": string,
  "sender_id": number,
  "contacts_list_blueprint_id": string,
  "email_template_id": number,
  "schedule": string,
};



export const ColumnKeys = {
  name: "name",
  status: "status",
  deliveries: "deliveries",
  opens: "opens",
  clicks: "clicks",
  edited: "edited"
} as const;

export type ColumnKey = keyof typeof ColumnKeys;
