import { Grid, Typography } from "@mui/material";

export const BillingSectionTitle = ({ title, subTitle }) => {
  return (
    <Grid container item lg={3.9} flexDirection="column">
      <Typography variant="h6" component="h2" sx={{ color: theme => theme.palette.gray[600] }}>
        {title}
      </Typography>
      <Typography>{subTitle}</Typography>
    </Grid>
  );
};
