export const deepCloneFunction = function <T extends (...args: any[]) => any>(f: T): T {
  const that = f;
  const temp = function temporary(...args: Parameters<T>): ReturnType<T> {
    return that.apply(f, args);
  };
  for (const key in f) {
    if (Object.prototype.hasOwnProperty.call(f, key)) {
      (temp as any)[key] = (f as any)[key];
    }
  }
  return temp as T;
};