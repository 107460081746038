import { useDispatch } from "react-redux";
import { fetchLists } from "core/store/entities/Lists/lists.actions";
import { SearchInput } from "ui/components";
import { useSearchUrlParam } from "hooks/useUrlParams";

export const ListSearchInput = () => {
  const { searchTerm, onSearchChange } = useSearchUrlParam();
  const dispatch = useDispatch();

  const handleSearch = (value: string) => {
    onSearchChange(value);
    dispatch(fetchLists());
  };

  return (
    <SearchInput
      onSearch={handleSearch}
      value={searchTerm}
      fullWidth
      aria-label="Search list"
      placeholder="Search lists"
    />
  );
};
