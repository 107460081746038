import { Chip, styled } from "@mui/material";

export const StyledChip = styled(Chip)(({ theme, type }) => ({
  fontSize: "0,796875rem",
  fontWeight: 460,
  borderRadius: 4,
  backgroundColor: theme.palette[type][50],
  color: theme.palette[type][700],
  lineHeight: "1.125rem",
  padding: 0,
  "& > svg.MuiChip-deleteIcon": {
    color: theme.palette[type][700],
    fontSize: "1.5rem",
    paddingLeft: 8
  },
  "& > span": {
    paddingInline: 6
  }
}));
