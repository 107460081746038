import { Alert } from "@mui/material";
import { triggerUpdateExportContactsProgress } from "core/store/entities/Contacts/contacts.actions";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUploadProgress } from "ui/components/Progress/FileUploadProgress";

export const DownloadContactsProgress = () => {
  const listLabel = useSelector(listSelectors.listLabel);
  const exportInProgress = useSelector(contactsSelectors.exportInProgress);
  const canDownloadFile = useSelector(contactsSelectors.canDownloadFile);
  const exportProgress = useSelector(contactsSelectors.exportProgress);
  const exportContactsError = useSelector(contactsSelectors.exportContactsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!exportInProgress || canDownloadFile) return;
    const interval = setInterval(() => {
      dispatch(triggerUpdateExportContactsProgress());
    }, 2000);
    if (canDownloadFile) {
      clearInterval(interval);
      return;
    }
    return () => {
      clearInterval(interval);
    };
  }, [exportInProgress, canDownloadFile]);

  if (!exportInProgress && !canDownloadFile) {
    return null;
  }

  if (exportContactsError) {
    return <Alert severity="error">Failed to prepare file for export</Alert>;
  }
  return <FileUploadProgress progressValue={exportProgress ?? 0} fileName={`${listLabel}.csv`} />;
};
