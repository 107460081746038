import { all } from "redux-saga/effects";

import Statistics from "../entities/Statistics/statistics.saga";
import Messages from "../entities/Messages/messages.saga";
import Modal from "../entities/Modal/modal.saga";
import UpgradePlan from "../entities/UpgradePlan/upgradePlan.saga";
import UserSaga from "../entities/User/user.saga";
import DomainSaga from "../entities/Domain/domain.saga";
import Billing from "../entities/Billing/billing.saga";
import ListsSaga from "../entities/Lists/lists.saga";
import ListSaga from "../entities/List/list.saga";
import ModalFormSaga from "../entities/ModalForm/modalForm.saga";
import ContactSaga from "../entities/Contact/contact.saga";
import ContactsSaga from "../entities/Contacts/contacts.saga";
import ImportFlowSaga from "../entities/ImportFlow/importFlow.saga";
import CampaignsSaga from "../entities/Campaigns/campaigns.saga";

export default function* rootSaga() {
  yield all([
    Statistics(),
    Messages(),
    Modal(),
    UpgradePlan(),
    UserSaga(),
    DomainSaga(),
    Billing(),
    ListsSaga(),
    ListSaga(),
    ModalFormSaga(),
    ContactSaga(),
    ContactsSaga(),
    ImportFlowSaga(),
    CampaignsSaga()
  ]);
}
