import { put, call, takeLatest } from "redux-saga/effects";
import { CampaignsService } from "core/api/services/CampaignsService";
import { campaignsActions } from "./campaigns.slice";
import { CampaignApiRecord } from "core/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { CampaignService } from "core/api/services/CampaignService";

function* fetchCampaignsHandler() {
  try {
    const data: CampaignApiRecord[] = yield call(CampaignsService.fetchCampaigns);
    yield put(campaignsActions.fetchCampaignsSuccess(data));
  } catch (error) {
    yield put(campaignsActions.fetchCampaignsError(error));
  }
}
function* duplicateCampaignHandler({ payload }: PayloadAction<string>) {
  try {
    const campaignService = new CampaignService(payload);
    yield call(campaignService.duplicateCampaign);
    yield put(campaignsActions.duplicateCampaignSuccess());
  } catch (error) {
    yield put(campaignsActions.duplicateCampaignError(error));
  }
}
function* deleteCampaignHandler({ payload }: PayloadAction<string>) {
  try {
    const campaignService = new CampaignService(payload);
    yield call(campaignService.deleteCampaign);
    yield put(campaignsActions.deleteCampaignSuccess());
  } catch (error) {
    yield put(campaignsActions.deleteCampaignError(error));
  }
}
function* triggerFetchCampaignsHandler() {
  yield put(campaignsActions.fetchCampaigns());
}

export default function* campaignsSaga() {
  yield takeLatest(campaignsActions.fetchCampaigns, fetchCampaignsHandler);
  yield takeLatest(campaignsActions.duplicateCampaign, duplicateCampaignHandler);
  yield takeLatest(campaignsActions.deleteCampaign, deleteCampaignHandler);
  yield takeLatest(
    [campaignsActions.duplicateCampaignSuccess, campaignsActions.deleteCampaignSuccess],
    triggerFetchCampaignsHandler
  );
}
