import React, { Component } from "react";

import { numberWithDots } from "helpers";

import { Bar, defaults } from "react-chartjs-2";

import styles from "./deliveries-chart.module.scss";

defaults.global.legend.display = false;

export class DeliveriesChart extends Component {
  constructor(props) {
    super(props);
    this.legendRef = React.createRef();
    this.chartRef = React.createRef();

    window.updateDataset = (e, datasetIndex) => {
      var index = datasetIndex;
      var ci = this.chartRef.current.chartInstance;
      var meta = this.chartRef.current.chartInstance.getDatasetMeta(index);

      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
      this.legendRef.current.innerHTML = this.chartRef.current.chartInstance.generateLegend();
      ci.update();
    };

    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: "left"
      },
      legendCallback: chart => {
        let text = [];
        text.push("<ul>");
        for (let i = 0; i < chart.data.datasets.length; i++) {
          const meta = chart.getDatasetMeta(i);
          text.push(
            '<li class="' +
              (meta ? (meta.hidden ? "hidden-legend-label" : "") : "") +
              '" onclick="updateDataset(event, ' +
              chart.legend.legendItems[i].datasetIndex +
              ')">'
          );
          text.push(
            '<span style="background-color:' +
              chart.data.datasets[i].backgroundColor +
              '">' +
              "</span>" +
              chart.data.datasets[i].label
          );
          text.push("</li>");
        }
        text.push("</ul>");
        return text.join("");
      },
      scales: {
        xAxes: [
          {
            ticks: {
              padding: 30,
              fontFamily: "Geist",
              fontSize: 13,
              color: "#303b42"
            },
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              padding: 30,
              fontFamily: "Geist",
              fontWeight: 400,
              fontSize: 13,
              color: "#303b42",
              callback: function (value) {
                return numberWithDots(value);
              }
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: "#ebedef",
              color: "#ebedef"
            }
          }
        ]
      }
    };
  }

  componentDidMount() {
    this.legendRef.current.innerHTML = this.chartRef.current.chartInstance.generateLegend();
  }

  render() {
    const data = {
      labels: this.props.data.labels,
      datasets: [
        {
          label: "Deliveries",
          width: "20px",
          backgroundColor: "#3DAE79",
          hoverBackgroundColor: "#86d5a3",
          data: this.props.data.datasets ? this.props.data.datasets[1].data : null,
          maxBarThickness: "20",
          categoryPercentage: "0.6"
        },
        {
          label: "Bounces",
          backgroundColor: "#757575",
          hoverBackgroundColor: "#757575",
          data: this.props.data.datasets ? this.props.data.datasets[0].data : null,
          maxBarThickness: "20",
          categoryPercentage: "0.6"
        }
      ]
    };
    return (
      <div>
        <Bar data={data} options={this.options} ref={this.chartRef} height={300} />
        <div className={styles.legend} ref={this.legendRef}></div>
      </div>
    );
  }
}
