export const colors = [
  "#cc9ccb",
  "#F0F080",
  "#ECD12D",
  "#DADABC",
  "#7CB7DE",
  "#B6D8D5",
  "#065358",
  "#DBE3B4",
  "#C1CCEB",
  "#944C6D",
  "#F7819B",
  "#F9C8CB"
] as const;