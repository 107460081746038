import { Add } from "@mui/icons-material";
import { Button } from "ui/components";

export const AddCampaignButton = () => {
  const handleClick = () => {
    console.log("Add campaign");
  };

  return (
    <Button startIcon={<Add />} onClick={handleClick}>
      New campaign
    </Button>
  );
};
