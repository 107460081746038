import { useUpgradePlan } from "hooks";
import React from "react";
import { Alert, Grid, Stack, Typography } from "@mui/material";
import { Button, LinearProgress } from "ui/components";
import { NO_BILLING_INFO_ERROR } from "core/constants";
import { useCurrentBillingCycle } from "./useCurrentBillingCycle";

export const CurrentBillingCycle = () => {
  const {
    totalSend,
    totalAvailable,
    totalPercentage,
    subscriptionStart,
    subscriptionEnd,
    subscriptionErrors
  } = useCurrentBillingCycle();
  const { openUpgradePlan, currentStep, isFreePlanUser } = useUpgradePlan();
  const showError = subscriptionErrors && subscriptionErrors.detail !== NO_BILLING_INFO_ERROR;


  return (
    <>
      <Grid item xs={12} lg={3.9} flexDirection="column">
        <Typography
          variant="h6"
          component="h2"
          sx={{ color: theme => theme.palette.gray[600], minWidth: "fit-content" }}
        >
          Current billing cycle
        </Typography>
        {subscriptionStart && (
          <Typography
            variant="body2"
            fontWeight={360}
            sx={{
              color: theme => theme.palette.gray[350]
            }}
          >{`${subscriptionStart} → ${subscriptionEnd}`}</Typography>
        )}
      </Grid>
      <Grid container item lg={7.9} flexDirection="column" gap={1}>
        {showError && (
          <Grid container item>
            <Alert variant="filled" severity="error">
              {subscriptionErrors?.detail}
            </Alert>
          </Grid>
        )}
        <Stack sx={{ flexGrow: 1 }} gap={2.5}>
          {totalAvailable && (
            <Stack gap={1}>
              <LinearProgress value={totalPercentage} />
              <Typography
                variant="small"
                sx={{
                  color: theme => theme.palette.gray[350]
                }}
              >{`${totalSend} of ${totalAvailable} email used`}</Typography>
            </Stack>
          )}
          {isFreePlanUser && <Button
            size="small"
            onClick={openUpgradePlan}
            disabled={currentStep <= 2}
            sx={{ alignSelf: "baseline" }}
          >
            Upgrade plan
          </Button>}
        </Stack>
      </Grid>
    </>
  );
};
