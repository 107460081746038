import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useSelectFieldsForm } from "./useSelectFieldsForm";
import { ContactField } from "core/types";
import { generateEmbedForm } from "../helper/embedFormGenerator";
import { useSelector } from "react-redux";
import { contactSelectors } from "core/store/entities/Contact/contact.selectors";
import { useParams } from "react-router-dom";

type FormEmbedContextType = {
  control: any;
  generatedCode: string;
  currentSelectedFields: ContactField[];
  selectableListFields: ContactField[];
};

const FormEmbedContext = createContext<FormEmbedContextType>({
  control: null,
  generatedCode: "",
  currentSelectedFields: [],
  selectableListFields: []
});

type Props = {
  children: ReactNode;
};

export const FormEmbedContextProvider = ({ children }: Props) => {
  const { list_id } = useParams();
  const requiredEditableFields = useSelector(contactSelectors.contactRequiredEditableFields);
  const [generatedCode, setGeneratedCode] = useState<string>("");
  const { control, currentSelectedFields, selectableListFields } = useSelectFieldsForm();

  useEffect(() => {
    if (list_id) {
      const formCode = generateEmbedForm(
        [...requiredEditableFields, ...currentSelectedFields],
        list_id
      );
      setGeneratedCode(formCode);
    }
  }, [currentSelectedFields, requiredEditableFields, list_id]);

  return (
    <FormEmbedContext.Provider
      value={{
        generatedCode,
        control,
        currentSelectedFields,
        selectableListFields
      }}
    >
      {children}
    </FormEmbedContext.Provider>
  );
};

export const useFormEmbedContext = () => useContext(FormEmbedContext);
