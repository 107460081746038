import { SettingsOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { TextButton } from "ui/components";
import { showModal } from "core/store/entities/Modal/modal.actions";
import { CUSTOM_COLUMNS_MODAL } from "core/constants";

export const CustomColumnsButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showModal(CUSTOM_COLUMNS_MODAL));
  };
  return (
    <TextButton startIcon={<SettingsOutlined style={{ fontSize: "1rem" }} />} onClick={handleClick}>
      Columns
    </TextButton>
  );
};
