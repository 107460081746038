import { Skeleton, Stack } from "@mui/material";

type Props = {
  actionItems: any[];
};

export const ListActionsSkeleton = ({ actionItems }: Props) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        display: { xs: "none", lg: "flex" }
      }}
    >
      {actionItems.map(item => {
        return <Skeleton variant="rectangular" width={140} height={40} key={item.id} />;
      })}
    </Stack>
  );
};
