import { Button, Dialog, RegularButton } from "../../index";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideFeedbackPopup } from "core/store/entities/User/user.actions";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { FreePlanExceededText } from "./FreePlanExceededText";

const freePlanExceededKey = "free_plan_exceeded";

export const FeedbackPopup = () => {
  const open = useSelector(userSelectors.showFeedbackPopup);
  const [title, ...content] = useSelector(userSelectors.feedbackPopupContent);
  const onConfirm = useSelector(userSelectors.feedbackPopupOnConfirm);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideFeedbackPopup());
  };

  const handleConfirm = () => {
    onConfirm();
    dispatch(hideFeedbackPopup());
  }

  const showFreePlanExceeded = title === freePlanExceededKey;


  const renderedTitle = showFreePlanExceeded ? "Plan upgrade is needed" : content?.length === 0 ? "" : title
  const renderedContent = content?.length === 0 ? title : content.map((c, index) => <Typography key={index} variant="large">{c}</Typography>);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={renderedTitle}
      actionButtons={
        <>
          <RegularButton onClick={handleClose} autoFocus aria-label="Close this popup">
            Close
          </RegularButton>
          {onConfirm && <Button onClick={handleConfirm}>Confirm</Button>}
        </>
      }
    >
      <Stack gap={2.5}>
        {showFreePlanExceeded ? (
          <FreePlanExceededText />
        ) : (
          renderedContent
        )}
      </Stack>
    </Dialog>
  );
};
