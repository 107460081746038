import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useUpgradePlan } from "hooks";

export const FreePlanExceededText = () => {
  const { openUpgradePlan } = useUpgradePlan();
  const handleLinkClick = () => {
    openUpgradePlan();
  };
  return (
    <Typography variant="body2">
      You are currently on a free trial plan. To add more than one domain, please{" "}
      <Link to="" onClick={handleLinkClick} aria-label="Upgrade plan popup">
        upgrade to a paid plan
      </Link>
    </Typography>
  );
};
