import { PauseCircleOutline } from "@mui/icons-material";
import { duplicateCampaign } from "core/store/entities/Campaigns/campaigns.actions";
import { CampaignStatus, isCampaignStatus, MenuItem } from "core/types";
import { useDispatch } from "react-redux";
import { ContentCopy, Pencil, TrashBin } from "ui/assets/icons";

const menuActions = ["pause", "duplicate", "delete", "edit"] as const;
type CampaignMenuAction = typeof menuActions[number];

const campaignInProgressMenuItems = (
  name: string,
  handlers: Record<CampaignMenuAction, () => void>
): MenuItem<CampaignMenuAction>[] => [
  {
    name: "Pause",
    icon: <PauseCircleOutline sx={{ fontSize: "1em" }} />,
    id: "pause",
    "aria-label": `Pause ${name} campaign`,
    onClick: handlers.pause
  },
  {
    name: "Duplicate",
    icon: <ContentCopy style={{ fontSize: "1em" }} />,
    id: "duplicate",
    "aria-label": `Duplicate ${name} campaign`,
    onClick: handlers.duplicate
  }
];
const campaignMenuItems = (
  name: string,
  handlers: Record<CampaignMenuAction, () => void>
): Array<MenuItem<CampaignMenuAction>> => [
  {
    name: "Edit",
    id: "edit",
    "aria-label": `Edit ${name} campaign`,
    icon: <Pencil />,
    onClick: handlers.edit
  },
  {
    name: "Duplicate",
    icon: <ContentCopy style={{ fontSize: "1em" }} />,
    id: "duplicate",
    "aria-label": `Duplicate ${name} campaign`,
    onClick: handlers.duplicate
  },
  {
    name: "Delete",
    icon: <TrashBin />,
    id: "delete",
    "aria-label": `Delete ${name} campaign`,
    onClick: handlers.delete,
    divider: true
  }
];

export const useCampaignsMoreMenu = (id: string, name: string, status: CampaignStatus) => {
  const dispatch = useDispatch();
  const handlers: Record<CampaignMenuAction, () => void> = {
    delete: () => {},
    duplicate: () => {
      dispatch(duplicateCampaign(id));
    },
    edit: () => {},
    pause: () => {}
  };
  const campaignInProgress = !isCampaignStatus(status);
  if (campaignInProgress) {
    return campaignInProgressMenuItems(name, handlers);
  }
  return campaignMenuItems(name, handlers);
};
