import { useCallback } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import type { Contact, ContactField, ContactStatus, TableColumn, TableRow } from "core/types";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { MoreMenu } from "ui/components/Menus/MoreMenu";
import { useContactFields } from "hooks";
import { useContactsTableMenuItems } from "./useContactsTableMenuItems";
import { useUrlParams } from "hooks/useUrlParams";
import { listSelectors } from "core/store/entities/List/list.selectors";

const renderContent = (contact: Contact, field: ContactField) => {
  const value = contact[field.column_name];
  switch (field.data_type) {
    case "boolean":
      return value ? "Yes" : "No";
    case "choice":
      return field.choices[value];
    case "multichoice":
      return value?.map((v: string) => field.choices[v])?.join(", ");
    case "datetime":
    case "date":
      return dayjs(value).format("DD-MM-YYYY");
    default:
      return value;
  }
};

const messages = {
  error: "Oh no, something went wrong while trying to get contacts",
  shouldAddContacts: "Your list is ready—now let’s add some contacts! 🚀",
  notFound: "Could not find contacts"
};

export const useContactsTableData = () => {
  const { contactFields } = useContactFields(null);
  const contacts = useSelector(contactsSelectors.contacts);

  const { getItems } = useContactsTableMenuItems();

  const renderMoreMenu = useCallback(
    (menuFor: { id: string; name: string; status: ContactStatus }): TableColumn => ({
      align: "right",
      content: (
        <MoreMenu
          aria-label={`Open menu for ${menuFor.name}`}
          menuFor={menuFor}
          iconStyle={{ fontSize: theme => theme.remScale[20] }}
          items={getItems(menuFor)}
        />
      )
    }),
    []
  );

  const rows: TableRow[] =
    contacts?.map(contact => ({
      key: contact.id,
      columns: [
        ...(contactFields ?? []).map(field => ({
          content: renderContent(contact, field),
          align: "left" as const
        })),
        renderMoreMenu({ id: contact.id, name: contact.email, status: contact.status })
      ]
    })) || [];
  const headers = [
    ...(contactFields ?? []).map(field => ({ name: field.label, align: "left" })),
    { name: "", align: "left" }
  ];

  return {
    headers,
    rows
  };
};
