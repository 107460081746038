export const CREATE_ACCOUNT_SCREEN = "/create-account";
export const CREATE_FREE_ACCOUNT_SCREEN = "/create-account/free";
export const CREATE_PAID_ACCOUNT_SCREEN = "/create-account/paid";
export const LOGIN_SCREEN = "/login";
export const CONFIRM_EMAIL_SCREEN = "/confirm-email";
export const RESET_PASSWORD_SCREEN = "/reset-password";
export const CHOOSE_NEW_PASSWORD_SCREEN = "/choose-new-password";
export const DASHBOARD_SCREEN = "/dashboard";
export const DASHBOARD_STATISTICS_SCREEN = "/statistics";
export const DASHBOARD_MESSAGES_SCREEN = "/messages";
export const PAGE_NOT_FOUND_SCREEN = "/page-not-found";
export const ADMIN_VIEW_SCREEN = "/admin_view";
export const ROOT_SCREEN = "/";
export const LOG_FILES_SCREEN = "/log-files";
// Settings screens
export const DASHBOARD_BILLING_SCREEN = "/settings/billing";
export const SETTINGS_SCREEN = "/settings";
export const SETTINGS_LOG_FILES_SCREEN = "/settings/log-files";
export const DASHBOARD_SENDING_DOMAINS_SCREEN = "/settings/sending-domains";
export const SETUP_DOMAIN_SCREEN = "/settings/sending-domains/setup-domain";
export const CONNECT_API_SCREEN = "/settings/connect-api";
export const CONNECT_SMTP_SCREEN = "/settings/connect-smtp";
// Contacts screens
export const CONTACTS_SCREEN = "/contacts";
export const LISTS_SCREEN = "/contacts/lists";
// List screens
export const LIST_CONTACTS_IMPORT = "import-contacts";
export const LIST_CONTACTS_IMPORT_FIELDS_MAPPING = "fields-mapping";
export const LIST_EMBED_FORM = "embed-form";
// Campaigns screens
export const CAMPAIGNS_OVERVIEW_SCREEN = "/campaigns";

