const ENTITY = "domain";

export const domainSelectors = {
  domains: state => state[ENTITY].domains,
  domainsOptions: state => state[ENTITY].domainsOptions,
  dnsRecords: state => state[ENTITY].dnsRecords,
  isSendingDomainsLoading: state => state[ENTITY].isSendingDomainsLoading,
  errors: state => state[ENTITY].errors,
  vmtaPools: state => state[ENTITY].vmtaPools,
  defaultVmtaPool: state => state[ENTITY].vmtaPools?.[0],
  firstDomainId: state => state[ENTITY].domains?.[0]?.domain_id
};
