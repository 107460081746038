import { fetchCampaigns } from "core/store/entities/Campaigns/campaigns.actions";
import { CampaignStatus, CampaignStatusEnum, isCampaignStatus, MenuItem } from "core/types";
import { useStatusUrlParam, useUrlParams } from "hooks/useUrlParams";
import { useState } from "react";
import { useDispatch } from "react-redux";

const statusItems = [
  { name: "All", id: "all" as const, "aria-label": "All campaigns" },
  { name: "Sent", id: CampaignStatusEnum.sent, "aria-label": "Sent campaigns" },
  { name: "Blocked", id: CampaignStatusEnum.blocked, "aria-label": "Blocked campaigns" },
  { name: "Cancelled", id: CampaignStatusEnum.cancelled, "aria-label": "Cancelled campaigns" },
  { name: "Draft", id: CampaignStatusEnum.draft, "aria-label": "Draft campaigns" },
  { name: "Scheduled", id: CampaignStatusEnum.scheduled, "aria-label": "Scheduled campaigns" }
];

export const useFilteredByStatusButton = () => {
  const dispatch = useDispatch();
  const { onStatusChange, status } = useStatusUrlParam();
  const [selectedStatus, setSelectedStatus] = useState<CampaignStatus | "all">(
    isCampaignStatus(status) ? status : "all"
  );
  const handleMenuItemClick = (id: CampaignStatus | "all") => {
    setSelectedStatus(id);
    if (id === "all") {
      onStatusChange();
    } else {
      onStatusChange(id);
    }
    dispatch(fetchCampaigns());
  };

  const statusMenuItems: Array<MenuItem<CampaignStatus | "all">> = statusItems.map(item => ({
    ...item,
    onClick: () => handleMenuItemClick(item.id as CampaignStatus | "all")
  }));

  return {
    selectedStatus,
    statusMenuItems
  }
}