import { useSelector } from "react-redux";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { LIST_CONTACTS_IMPORT_FIELDS_MAPPING } from "core/constants";
import { StyledButton } from "./styled";
import { useNavigate } from "react-router-dom";

export const ContinueButton = () => {
  const navigate = useNavigate();
  const file = useSelector(importFlowSelectors.selectedFile);

  return (
    <StyledButton
      onClick={() => {
        navigate(LIST_CONTACTS_IMPORT_FIELDS_MAPPING);
      }}
      disabled={!file}
    >
      Continue
    </StyledButton>
  );
};
