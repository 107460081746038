import { Box, styled } from "@mui/material";
import { Button } from "ui/components";

export const StyledCheckboxGroupWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap"
}))

export const StyledCopyButton = styled(Button)(({ theme }) => ({
  alignSelf: "baseline",
  paddingInline: theme.remScale[22],
  paddingBlock: theme.remScale[14],
  fontSize: theme.remScale[15.75]
}))