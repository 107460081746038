import { CampaignApiRecord, CampaignStatus, ColumnKey, ColumnKeys } from "core/types";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjsLib from "dayjs";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridTreeNodeWithRender
} from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { theme } from "ui/theme";
import { StyledStatusCell } from "./styled";
import { deepCloneFunction } from "helpers/deepCloneFunction";
import { DuplicateCampaignButton } from "./components/DuplicateCampaignButton";
import { CampaignMoreMenu } from "./components/CampaignMoreMenu";
import { campaignsSelectors } from "core/store/entities/Campaigns/campaigns.selectors";
import { useSelector } from "react-redux";

dayjsLib.extend(relativeTime);
dayjsLib.extend(updateLocale);
const dayjs = deepCloneFunction(dayjsLib);

// avoid global override
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: function (number: number) {
      if (number >= 7) {
        return `${Math.floor(number / 7)}w`;
      }
      return `${number}d`;
    },
    M: "1M",
    MM: "%dM",
    y: "1y",
    yy: "%dy"
  }
});

type StatusPalette = {
  font: string;
  bg: string;
  border?: string;
};

const statusColorMap: Record<CampaignStatus, StatusPalette> = {
  sent: { font: theme.palette.success[700], bg: theme.palette.success[50] },
  blocked: { font: theme.palette.error[700], bg: theme.palette.error[50] },
  scheduled: { font: theme.palette.blue[700], bg: theme.palette.blue[50] },
  cancelled: { font: theme.palette.gray[700], bg: theme.palette.gray[50] },
  draft: {
    font: theme.palette.gray[600],
    bg: theme.palette.white.main,
    border: theme.palette.gray[225]
  }
};

const renderEditCell = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
  let fromNow = dayjs(params.value).fromNow();
  const diff = dayjs().diff(params.value, "week");
  if (diff >= 4) {
    fromNow = `${diff}w ago`;
  }
  return (
    <Typography variant="inherit" sx={{ color: theme.palette.gray[350] }}>
      {fromNow}
    </Typography>
  );
};
const renderStatusCell = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
  // values like '6% sent' should have status sent
  const campaignStatus = params.value.toLowerCase().split(" ").at(-1) as CampaignStatus;
  if (!campaignStatus) {
    console.warn("Invalid campaign status", params.value);
    return renderCell(params);
  }
  const { bg, font, border } = statusColorMap[campaignStatus] ?? {};
  return (
    <StyledStatusCell font={font} bg={bg} border={border}>
      {params.value}
    </StyledStatusCell>
  );
};
const renderCell = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
  <Typography variant="inherit">{params.value}</Typography>
);
const renderPercentageCell = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
) => (
  <Typography variant="inherit" fontWeight={560}>
    {params.value}%
  </Typography>
);
const renderNumberCell = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
  <Typography variant="inherit" fontWeight={560}>
    {params.value.toLocaleString("en-US")}
  </Typography>
);

const getCellRender = (field: ColumnKey) => {
  switch (field) {
    case "edited":
      return renderEditCell;
    case "status":
      return renderStatusCell;
    case "clicks":
    case "opens":
      return renderPercentageCell;
    case "deliveries":
      return renderNumberCell;
    default:
      return renderCell;
  }
};

const columns: Record<ColumnKey, string> = {
  name: "Name",
  status: "Status",
  deliveries: "Deliveries",
  opens: "Opens",
  clicks: "Clicks",
  edited: "Edited"
} as const;

export const useCampaignsTable = () => {
  const campaigns = useSelector(campaignsSelectors.campaigns);

  const rows: GridRowsProp = campaigns.map((campaign, index) => ({
    id: campaign.id + index,
    name: campaign.name + index,
    status: campaign.status,
    edited: campaign.updated_at,
    deliveries: campaign.delivers,
    opens: campaign.opens,
    clicks: campaign.clicks
  }));

  const gridColumns: GridColDef[] = Object.keys(ColumnKeys).map(key => {
    const columnKey = key as ColumnKey;
    return {
      field: columnKey,
      headerName: columns[columnKey],
      flex: columnKey === "name" ? 6 : 1,
      sortable: columnKey === "edited",
      editable: false,
      renderCell: getCellRender(columnKey)
    };
  });

  const buttonsColumns: GridColDef[] = [
    {
      field: "duplicate",
      headerName: "",
      width: 48,
      sortable: false,
      editable: false,
      renderCell: params => {
        return (
          <DuplicateCampaignButton
            campaignId={params.id.toString()}
            campaignName={params.row.name}
          />
        );
      }
    },
    {
      field: "more-menu",
      headerName: "",
      width: 48,
      sortable: false,
      editable: false,
      renderCell: params => {
        const campaignStatus = params.row.status?.toLowerCase() as CampaignStatus;
        return (
          <CampaignMoreMenu name={params.row.name} id={params.row.id} status={campaignStatus} />
        );
      }
    }
  ];

  return { rows, columns: [...gridColumns, ...buttonsColumns] };
};
