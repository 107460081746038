import { call, put, takeLatest } from "redux-saga/effects";

import { ListsService } from "core/api/services/ListsService";
import { listsActions } from "./lists.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { listActions } from "../List/list.slice";

function* fetchListsHandler() {
  try {
    const data = yield call(() => ListsService.fetchLists());
    yield put(listsActions.fetchListsSuccess(data));
  } catch (error) {
    yield put(listsActions.fetchListsFailed(error));
  }
}

function* deleteListHandler({ payload }: PayloadAction<string>) {
  try {
    yield call(() => ListsService.deleteList(payload));
    yield put(listsActions.deleteListSuccess());
  } catch (error) {
    yield put(listsActions.deleteListError(error));
  }
}

function* duplicateListHandler({ payload }: PayloadAction<string>) {
  try {
    yield call(() => ListsService.duplicateList(payload));
    yield put(listsActions.duplicateListSuccess());
  } catch (error) {
    yield put(listsActions.duplicateListError(error));
  }
}


function* goPreviousPageHandler() {

}

export default function* listsSaga() {
  yield takeLatest(
    [
      listActions.createListSuccess.type,
      listActions.updateListSuccess.type,
      listsActions.deleteListSuccess.type,
      listsActions.duplicateListSuccess.type,
      listsActions.fetchLists.type
    ],
    fetchListsHandler
  );

  yield takeLatest(listsActions.duplicateList.type, duplicateListHandler);
  yield takeLatest(listsActions.deleteList.type, deleteListHandler);
  yield takeLatest(listsActions.deleteListSuccess.type, goPreviousPageHandler);
}
