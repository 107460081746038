import { Box, Container, Stack, styled } from "@mui/material";
import { TextButton, Button } from "ui/components";

export const StyledContainer = styled(Container)(() => ({
  height: "100%"
}));

export const StyledWrapper = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center"
}));
export const StyledStackWrapper = styled(Stack)(() => ({ maxWidth: 480, width: "100%" }));

export const StyledButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  gap: theme.remScale[8]
}));

export const StyledTextButton = styled(TextButton)(({ theme }) => ({
  paddingInline: 0,
  minWidth: "fit-content",
  ".MuiButton-startIcon": {
    color: theme.palette.gray[350]
  }
}));

