import { SettingsOutlined } from "ui/assets/icons";
import { Add, Code } from "@mui/icons-material";
import { HasSubmenu, MenuItem } from "core/types";
import { useDispatch } from "react-redux";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { useNavigate, useLocation } from "react-router-dom";
import { LIST_EMBED_FORM, LIST_CONTACTS_IMPORT } from "core/constants";

type Action = "settings" | "embed_form" | "new_contact";
export type NewContactSubmenuAction = "import_csv" | "create_contact";
type NewContactMenuItem = MenuItem<"new_contact"> & HasSubmenu<NewContactSubmenuAction>;

export type ListActionsItem = MenuItem<Action> | NewContactMenuItem;

const actionsNameMap: Record<Action, string> = {
  settings: "List settings",
  embed_form: "Embed form",
  new_contact: "New contact"
};

const getAriaLabelForList = (listName: string) => (action: Action) => {
  return `${actionsNameMap[action]} for ${listName}`;
};

export const useListActionsItems = (listId: string, listName: string) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleOpenSettings = () => {
    dispatch(showFormModal({ id: listId, type: "list" }));
  };
  const handleOpenContactFormModal = () => {
    dispatch(showFormModal({ id: null, type: "contact" }));
  };
  const handleOpenEmbedForm = () => {
    navigate(`${pathname}/${LIST_EMBED_FORM}`);
  };
  const handleOpenImportContactsFlow = () => {
    navigate(`${pathname}/${LIST_CONTACTS_IMPORT}`, { state: { routedFromList: true } });
  };

  const getAriaLabelForItem = getAriaLabelForList(listName);

  const newContactSubmenu: MenuItem<NewContactSubmenuAction>[] = [
    {
      id: "import_csv",
      name: "Import CSV",
      onClick: handleOpenImportContactsFlow,
      "aria-label": "Import CSV"
    },
    {
      id: "create_contact",
      name: "Add an individual",
      onClick: handleOpenContactFormModal,
      "aria-label": "Create a contact"
    }
  ];

  const items: ListActionsItem[] = [
    {
      id: "settings",
      icon: <SettingsOutlined style={{ fontSize: "1rem" }} />,
      name: actionsNameMap.settings,
      onClick: handleOpenSettings,
      "aria-label": getAriaLabelForItem("settings")
    },
    {
      id: "embed_form",
      icon: <Code style={{ fontSize: "1rem" }} />,
      name: actionsNameMap.embed_form,
      onClick: handleOpenEmbedForm,
      "aria-label": getAriaLabelForItem("embed_form")
    },
    {
      id: "new_contact",
      icon: <Add style={{ fontSize: "1rem" }} />,
      name: actionsNameMap.new_contact,
      "aria-label": getAriaLabelForItem("new_contact"),
      submenu: newContactSubmenu,
      divider: true
    }
  ];

  return {
    items
  };
};
