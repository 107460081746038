import { SearchInput } from "ui/components";
import { useSearchUrlParam } from "hooks/useUrlParams";
import { fetchContacts } from "core/store/entities/Contacts/contacts.actions";
import { useDispatch } from "react-redux";

export const ContactsSearchInput = () => {
  const dispatch = useDispatch();
  const { searchTerm, onSearchChange } = useSearchUrlParam();

  const handleSearch = (value: string) => {
    onSearchChange(value);
    dispatch(fetchContacts());
  };

  return (
    <SearchInput
      onSearch={handleSearch}
      value={searchTerm}
      fullWidth
      aria-label="Search contacts"
      placeholder="Search contacts"
    />
  );
};
