import { Select, Spinner } from "ui/components";
import { useDispatch, useSelector } from "react-redux";
import { history } from "core/store";
import { DASHBOARD_STATISTICS_SCREEN } from "core/constants";
import { selectDomain } from "core/store/entities/Statistics/statistics.actions";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";
import { KeyboardArrowDown } from "@mui/icons-material";

//TODO: rework selecting domains functionality

export const DomainsSelect = () => {
  const dispatch = useDispatch();
  const statisticsDomainsOptions = useSelector(statisticsSelectors.domainsOptions);
  const domainDomainsOptions = useSelector(domainSelectors.domainsOptions);
  const domainsOptions = statisticsDomainsOptions.length
    ? statisticsDomainsOptions
    : domainDomainsOptions;
  const selectedDomainId = useSelector(statisticsSelectors.selectedDomainId);
  const isStatisticsSendingDomainsLoading = useSelector(
    statisticsSelectors.isDomainsOptionsLoading
  );
  const isSendingDomainsLoading = useSelector(domainSelectors.isSendingDomainsLoading);

  if (isSendingDomainsLoading || isStatisticsSendingDomainsLoading) return <Spinner />;

  const handleDomainChange = event => {
    history.push(`${DASHBOARD_STATISTICS_SCREEN}/${event.target.value}`);
    dispatch(selectDomain(event.target.value));
  };

  const selectedDomain =
    domainsOptions.filter(({ value }) => value === +selectedDomainId)[0] || domainsOptions[0];

  return (
    <Select
      options={domainsOptions}
      label="Selected domain"
      value={selectedDomain?.value}
      onChange={handleDomainChange}
      IconComponent={KeyboardArrowDown}
      sx={{
        width: { sm: 400 }
      }}
    />
  );
};
