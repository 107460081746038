import { status } from "core/constants/status";
import { ListState } from "./list.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContactField } from "core/types";

export const listReducers = {
  addNewContactsField: (state: ListState) => {
    state.addNewContactsFieldStatus = status.loading;
  },
  addNewContactsFieldSuccess: (state: ListState, _: PayloadAction<ContactField>) => {
    state.addNewContactsFieldStatus = status.success;
  },
  addNewContactsFieldFailed: (state: ListState, { payload }: PayloadAction<unknown>) => {
    state.addNewContactsFieldStatus = status.error;
    state.error = payload;

  },
  editContactsField: (state: ListState) => {
    state.editContactsFieldStatus = status.loading;
  },
  editContactsFieldSuccess: (state: ListState) => {
    state.editContactsFieldStatus = status.success;
  },
  editContactsFieldFailed: (state: ListState, { payload }: PayloadAction<unknown>) => {
    state.editContactsFieldStatus = status.error;
    state.error = payload;

  },
  removeContactsField: (state: ListState) => {
    state.removeContactsFieldStatus = status.loading;
  },
  removeContactsFieldSuccess: (state: ListState) => {
    state.removeContactsFieldStatus = status.success;
  },
  removeContactsFieldFailed: (state: ListState, { payload }: PayloadAction<unknown>) => {
    state.removeContactsFieldStatus = status.error;
    state.error = payload;

  },
  createList: state => {
    state.createListStatus = status.loading;
  },
  createListSuccess: (state) => {
    state.createListStatus = status.success;
  },
  createListFailed: (state, { payload }) => {
    state.createListStatus = status.error;
    state.error = payload.error;
  },
  fetchList: (state: ListState, _: PayloadAction<string>) => {
    state.fetchListStatus = status.loading;
  },
  fetchListSuccess: (state, { payload }) => {
    state.fetchListStatus = status.success;
    state.list = payload;
  },
  fetchListFailed: (state, { payload }) => {
    state.fetchListStatus = status.error;
    state.error = payload;
  },
  resetList: (state: ListState) => {
    delete state.error;
    state.list = null;
    state.fetchListStatus = status.idle;
    state.updateListStatus = status.idle;
    state.editContactsFieldStatus = status.idle;
    state.addNewContactsFieldStatus = status.idle;
    state.removeContactsFieldStatus = status.idle;
    state.editContactsFieldStatus = status.idle;
  },
  updateList: state => {
    state.updateListStatus = status.loading;
  },
  updateListSuccess: state => {
    state.updateListStatus = status.success;
  },
  updateListFailed: (state, { payload }) => {
    state.errors = payload;
    state.updateListStatus = status.error;
  }
};
