import { contactSelectors } from "core/store/entities/Contact/contact.selectors";
import { ContactField } from "core/types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

type FormGeneratorForm = {
  selectedFields: any[];
};

export const useSelectFieldsForm = () => {
  const optionalEditableFields = useSelector(contactSelectors.contactOptionalEditableFields);

  const filteredOptionalEditableFields = optionalEditableFields
    .filter(
      field => field.column_name !== "status"
    );

  const { control, watch } = useForm<FormGeneratorForm>({
    defaultValues: {
      selectedFields: filteredOptionalEditableFields ?? []
    }
  });

  const currentSelectedFields = watch("selectedFields")

  return {
    currentSelectedFields,
    control,
    selectableListFields: filteredOptionalEditableFields
  }
}