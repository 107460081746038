import { Box, styled, type Theme } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  backgroundColor: theme.palette.white.main,
  overflowX: "scroll",
  maxHeight: 260,
  overflowY: "hidden",
  padding: theme.remScale[8],
  paddingBottom: theme.remScale[32]
}))