import { styled } from "@mui/material";

type StyledProps = {
  backgroundColor: string;
};

export const ColoredBlock = styled("span")<StyledProps>(({ backgroundColor, theme }) => ({
  display: "inline-block",
  backgroundColor,
  borderRadius: `calc(min(25%, ${theme.remScale[12]}))`,
  border: "1px solid #00000025"
}));
