import React from "react";
import { Stack, Typography } from "@mui/material";
import { useDefineMobileView } from "hooks";
import { generalHeaderPadding } from "../Layout";
import { SupportAgentIcon } from "../Icons";
import { HamburgerButton } from "./HamburgerButton";

export const Header = ({ title, headerRight, headerLeft, headerVariant, selectComponent, sx }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        padding: generalHeaderPadding,
        backgroundColor: theme => theme.palette.white.main,
        ...sx
      }}
    >
      <Stack direction="row" spacing={1}>
        {headerLeft}
        <Typography variant={headerVariant ?? "h1"}>{title}</Typography>
        {selectComponent}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <SupportAgentIcon />
        {headerRight}
        <HamburgerButton />
      </Stack>
    </Stack>
  );
};
