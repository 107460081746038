import {
  FormHelperText,
  InputLabel,
  MenuItem,
  outlinedInputClasses,
  Select as SelectMUI,
  selectClasses,
  styled
} from "@mui/material";

export const StyledSelect = styled(SelectMUI)(({ theme, error }) => ({
  borderRadius: "0.375rem",
  paddingInline: "0.75rem",
  "& .MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    color: theme.palette.gray[600],
    fontSize: "1rem"
  },
  [`.${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette[error ? "error[700]" : "divider"]
  },
  [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette[error ? "error[700]" : "black"]
  },
  [`& .${selectClasses.select}`]: {
    padding: "10px"
  },
  [`& .${selectClasses.iconOutlined}`]: {
    fontSize: "1.125rem"
  }
}));

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  fontSize: "0.875rem",
  lineHeight: "0.875rem",
  marginLeft: 0,
  marginTop: 4
}));

export const HiddenInputLabel = styled(InputLabel)(() => ({
  position: "absolute",
  height: 1,
  width: 1,
  visibility: "hidden" // hide visually, but visible for screen readers
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  borderRadius: "0.375rem",
  marginInline: "0.25rem",
  opacity: 0.7,
  paddingBlock: "0.75rem",
  paddingInline: "0.625rem",
  "&.Mui-selected": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0, 5%)",
    opacity: 1,
  }
}));

export const IconContainer = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  marginRight: theme.remScale[8],
  height: "100%"
}))
