import { Alert, Box } from "@mui/material";
import { Dialog } from "ui/components";
import { ActionButtons } from "../ActionButtons";
import { useDownloadContactsModal } from "./useDownloadContactsModal";
import { DownloadContactsProgress } from "./components/DownloadContactsProgress";

export const DownloadContactsModal = () => {
  const { show, errors, onOk, onClose, totalCount, confirmTitle } = useDownloadContactsModal();

  return (
    <Dialog
      open={show}
      onClose={onClose}
      paperStyles={{ width: "500px" }}
      title="Export contacts"
      actionButtons={
        <ActionButtons
          onCancel={onClose}
          onConfirm={onOk}
          isConfirmSubmit={false}
          confirmTitle={confirmTitle}
        />
      }
    >
      Ready to export {totalCount} contacts to a .csv file?
      {errors && <Alert severity="error">Failed to export contacts.</Alert>}
      <Box sx={{ mt: 2 }}>
        <DownloadContactsProgress />
      </Box>
    </Dialog>
  );
};
