const prefix = "ibr";
const subscribeFormClass = `${prefix}-subscribeForm`;
export const scriptId = `${prefix}-script`;
export const classes = {
  input: `${subscribeFormClass}_input`,
  header: `${subscribeFormClass}_header`,
  fieldControl: `${subscribeFormClass}_fieldControl`,
  label: `${subscribeFormClass}_label`,
  labelWrapper: `${subscribeFormClass}_label-wrapper`,
  legend: `${subscribeFormClass}_legend`,
  requiredAsterisk: `${subscribeFormClass}_requiredAsterisk`,
  root: `${subscribeFormClass}_root`,
  wrapper: `${subscribeFormClass}_wrapper`,
  submitButton: `${prefix}_submitButton`,
  responseMessage: `${prefix}_responseMessage`,
  error: `${prefix}-error`,
  message: `${prefix}_message`,
  fullWidth: `${prefix}-fullWidth`,
  subscribeFormClass,
};