import { Button, styled } from "@mui/material";

export const StyledRegularButton = styled(Button)(({ theme }) => ({
  borderColor: "rgb(184, 183, 181)",
  borderRadius: "0.375rem",
  color: theme.palette.black,
  fontFamily: "Geist",
  fontSize: "0.890625rem",
  lineHeight: "1.25rem",
  letterSpacing: "0.12px",
  paddingBlock: "0.565em",
  paddingInline: "0.75rem",
  textTransform: "none",
  "svg.MuiSvgIcon-root": {
    fontSize: "1.25em"
  },
  "&:hover": {
    borderColor: "rgb(184, 183, 181)",
    backgroundColor: "rgb(184, 183, 181 / 10%)"
  }
}));
