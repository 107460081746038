import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { fetchList } from "core/store/entities/List/list.actions";
import { ListHeader, ContactsTable } from "./components";

export const List = () => {
  const dispatch = useDispatch();
  const { list_id } = useParams();

  useEffect(() => {
    if (list_id) {
      dispatch(fetchList(list_id));
    }
  }, [list_id]);

  return (
    <Stack>
      <ListHeader />
      <ContactsTable />
    </Stack>
  );
};
