import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { Dialog } from "ui/components";
import { useUpgradePlanConfirmation } from "./useUpgradePlanConfirmation";

export const UpgradePlanConfirmation = () => {
  const { show, handleClose } = useUpgradePlanConfirmation();

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      paperStyles={{ width: "620px", maxWidth: "none" }}
      title="Thank you for your upgrade request!"
    >
      <Box maxWidth="100%">
        <Stack sx={{ padding: { xs: "0 20px 84px", sm: "0 48px 84px" } }} spacing={2}>
          <Typography variant="bodyL">
            Please note that to proceed with the onboarding process, you need first to create a free
            account, configure your sending domain, and send a test email using a real email
            campaign email to the test email address provided in mail-tester.com, and share the
            result link with us.
          </Typography>
          <Typography variant="bodyL">
            After that, we can ensure all is configured properly to upgrade your account.
          </Typography>
          <Typography variant="bodyL"> Let us know if you need any help. </Typography>
        </Stack>
      </Box>
    </Dialog>
  );
};
