import { useParams } from "react-router-dom";
import { getRequest } from "core/api/base";
import { downloadFile } from "helpers/downloadFile";
import { useSelector } from "react-redux";
import { listSelectors } from "core/store/entities/List/list.selectors";


const contactsListsUrl = "/mailsystem/api/v1/contacts-lists";


export const useDownloadExampleButton = () => {
  const { list_id } = useParams();
  const listLabel = useSelector(listSelectors.listLabel);
  const listFilename = `${listLabel} example.csv`

  const onDownload = async () => {
    const downloadTemplateUrl = `/${contactsListsUrl}/${list_id}/make-import-template/`;
    const data = await getRequest({
      url: downloadTemplateUrl
    });
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, listFilename);
    window.URL.revokeObjectURL(url);
  }

  return onDownload;
}