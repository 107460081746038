import { Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import React from "react";
import { StyledChip } from "./styled";

export const Status = ({ item }) => {
  const chipType = {
    Pending: "warning",
    Failed: "error",
    Active: "success"
  };

  return (
    <Stack spacing={2} alignItems="flex-start">
      <StyledChip
        label={item.status}
        type={chipType[item.status]}
        deleteIcon={item.status === "Active" ? <Check /> : null}
        onDelete={() => {}}
      />
      {item.status_info && (
        <Typography
          minWidth={150}
          sx={{ maxWidth: { xs: "165px", md: "205px", lg: "36vw", xl: "100%" } }}
          variant="bodyS"
        >
          It may take up to 48 hours before DNS changes become active
        </Typography>
      )}
    </Stack>
  );
};
