import {
  call,
  put,
  select,
  takeLatest
} from "redux-saga/effects";

import { ListsService } from "core/api/services/ListsService";
import { listActions } from "./list.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContactField, ListUpdateFields } from "core/types";
import { listSelectors } from "./list.selectors";
import { ListContactsService } from "core/api/services/ListContactsService";
import { contactsActions } from "../Contacts/contacts.slice";

export let ListContactsServiceInstance: ListContactsService | null = null;

function* createListServiceHandler() {
  const listId = yield select(listSelectors.listId);
  ListContactsServiceInstance = listId ? new ListContactsService(listId) : null;
}

function* fetchListHandler({ payload }: PayloadAction<string>) {
  try {
    const list = yield call(() => ListsService.fetchList(payload));
    yield put(listActions.fetchListSuccess(list));
    yield put(contactsActions.fetchContacts());
  } catch (error) {
    yield put(listActions.fetchListFailed(error));
  }
}

function* updateListHandler({ payload }: PayloadAction<ListUpdateFields>) {
  try {
    const id = yield select(listSelectors.listId);
    yield call(() => ListsService.updateList(id, payload));
    yield put(listActions.updateListSuccess());
  } catch (error) {
    yield put(listActions.updateListFailed(error));
  }
}

function* createListHandler({ payload }: PayloadAction<ListUpdateFields>) {
  try {
    yield call(() => ListsService.createList(payload));
    yield put(listActions.createListSuccess());
  } catch (error) {
    yield put(listActions.createListFailed(error));
  }
}

function* addNewContactsFieldHandler({ payload }: PayloadAction<ContactField>) {
  try {
    const id = yield select(listSelectors.listId);
    const newContactField = yield call(ListsService.addNewContactsField, id, payload);
    yield put(listActions.addNewContactsFieldSuccess(newContactField));
  } catch (error) {
    yield put(listActions.addNewContactsFieldFailed(error));
  }
}

function* removeContactsFieldHandler({ payload }: PayloadAction<string>) {
  try {
    const id = yield select(listSelectors.listId);
    yield call(() => ListsService.removeContactsField(id, payload));
    yield put(listActions.removeContactsFieldSuccess());
  } catch (error) {
    yield put(listActions.removeContactsFieldFailed(error));
  }
}

function* editContactsFieldHandler({ payload }: PayloadAction<{ id: string, data: ContactField }>) {
  try {
    const id = yield select(listSelectors.listId);
    yield call(() => ListsService.editContactsField(id, payload.id, payload.data));
    yield put(listActions.editContactsFieldSuccess());
  } catch (error) {
    yield put(listActions.editContactsFieldFailed(error));
  }
}

function* triggerListRefetch() {
  const id = yield select(listSelectors.listId);
  if (!id) return;
  yield put(listActions.fetchList(id));
}

export default function* listSaga() {
  yield takeLatest(
    [
      listActions.updateListSuccess.type,
      listActions.addNewContactsFieldSuccess.type,
      listActions.removeContactsFieldSuccess.type,
      listActions.editContactsFieldSuccess.type
    ],
    triggerListRefetch
  );
  yield takeLatest(
    listActions.fetchList.type,
    fetchListHandler
  );
  yield takeLatest(listActions.updateList.type, updateListHandler);
  yield takeLatest(listActions.createList.type, createListHandler);
  yield takeLatest([listActions.fetchListSuccess, listActions.resetList], createListServiceHandler);
  yield takeLatest(listActions.addNewContactsField.type, addNewContactsFieldHandler);
  yield takeLatest(listActions.removeContactsField.type, removeContactsFieldHandler);
  yield takeLatest(listActions.editContactsField.type, editContactsFieldHandler);
}
