import { useSelector } from "react-redux";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { useSearchUrlParam } from "hooks/useUrlParams";
import { listSelectors } from "core/store/entities/List/list.selectors";

const messages = {
  error: "Oh no, something went wrong while trying to get contacts",
  shouldAddContacts: "Your list is ready—now let’s add some contacts! 🚀",
  notFound: "Could not find contacts"
};

export const useContactsTable = () => {
  const { searchTerm } = useSearchUrlParam();
  const loading = useSelector(contactsSelectors.fetchContactsLoading);
  const success = useSelector(contactsSelectors.fetchContactsSuccess);
  const listIsReady = useSelector(listSelectors.success);
  const totalCount = useSelector(contactsSelectors.totalCount);

  const error = useSelector(contactsSelectors.fetchContactsError);

  const noContactsFound = searchTerm && totalCount === 0;
  const shouldAddContacts = success && !searchTerm && totalCount === 0;

  const getStatusMessage = () => {
    switch (true) {
      case error:
        return messages.error;
      case shouldAddContacts:
        return messages.shouldAddContacts;
      case noContactsFound:
        return messages.notFound;
    }
  };

  return {
    error,
    loading: loading && !listIsReady,
    message: getStatusMessage()
  };
};
