import { Typography } from "@mui/material";
import { setShouldReplaceContacts } from "core/store/entities/ImportFlow/importFlow.actions";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "ui/components/Inputs/Checkbox";

export const ReplaceContactsCheckbox = () => {
  const dispatch = useDispatch();
  const shouldReplaceContacts = useSelector(importFlowSelectors.shouldReplaceContacts);
  const handleChange = (_: SyntheticEvent, checked: boolean) => {
    dispatch(setShouldReplaceContacts(checked));
  };
  return (
    <>
      <Checkbox
        checked={shouldReplaceContacts}
        onChange={handleChange}
        label="Overwrite existing contacts"
      />
    </>
  );
};
