import { useDispatch, useSelector } from "react-redux";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { DOWNLOAD_CONTACTS_MODAL } from "core/constants";
import { contactsSelectors } from "core/store/entities/Contacts/contacts.selectors";
import { exportContactsStart } from "core/store/entities/Contacts/contacts.actions";
import { Spinner } from "ui/components";

export const useDownloadContactsModal = () => {
  const dispatch = useDispatch();
  const canDownloadFile = useSelector(contactsSelectors.canDownloadFile);
  const csvFileUrl = useSelector(contactsSelectors.csvFileUrl);
  const totalCount = useSelector(contactsSelectors.totalCount);
  const exportInProgress = useSelector(contactsSelectors.exportInProgress);

  const show = useSelector(modalSelectors[DOWNLOAD_CONTACTS_MODAL]);
  const errors = useSelector(contactsSelectors.error);

  const confirmTitle = canDownloadFile ? (
    "Download .csv"
  ) : exportInProgress ? (
    <Spinner />
  ) : (
    "Confirm"
  );

  const handleOk = () => {
    if (!canDownloadFile && !exportInProgress) {
      dispatch(exportContactsStart());
    } else if (csvFileUrl) {
      window.location.href = csvFileUrl;
    }
  };

  const handleClose = () => {
    dispatch(hideModal(DOWNLOAD_CONTACTS_MODAL));
  };

  return {
    show,
    errors,
    onOk: handleOk,
    onClose: handleClose,
    totalCount,
    confirmTitle
  };
};
