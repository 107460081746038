import { KeyboardArrowDown } from "@mui/icons-material";

import { ButtonWithMenu, TextButton } from "ui/components";
import { useFilteredByStatusButton } from "./useFilteredByStatusButton";

export const FilteredByStatusButton = () => {
  const { statusMenuItems, selectedStatus } = useFilteredByStatusButton();
  return (
    <ButtonWithMenu
      endIcon={<KeyboardArrowDown />}
      ButtonNode={TextButton}
      menuItems={statusMenuItems}
      sx={{
        textTransform: "capitalize"
      }}
    >
      {selectedStatus === "all" ? "Status" : selectedStatus}
    </ButtonWithMenu>
  );
};
