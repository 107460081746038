import { contactsActions } from "./contacts.slice";

export const {
  fetchContacts,
  fetchContactsFailed,
  fetchContactsSuccess,

  deleteContacts,
  deleteContactsFailed,
  deleteContactsSuccess,

  exportContactsStart,
  exportContactsStartSuccess,
  exportContactsStartFailed,

  triggerUpdateExportContactsProgress,
  updateExportContactsProgress,
  updateExportContactsProgressFailed,

  resetContacts
} = contactsActions;
