import { Stack, Typography } from "@mui/material";
import React from "react";
import { FilterSelect } from "ui/components";
import { DomainsSelect } from "../DomainsSelect";
import { useDomainOverall } from "../DomainOverall/useDomainOverall";

export const SelectDomain = () => {
  const { filterOptions, filterDetailsValue, filterChangeHandler } = useDomainOverall();

  return (
    <Stack spacing={1.5}>
      <Typography variant="h4" component="h2">
        Select a domain
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <DomainsSelect />
        <FilterSelect
          options={filterOptions}
          value={filterDetailsValue}
          onChange={filterChangeHandler}
          sx={{
            alignSelf: "baseline"
          }}
        />
      </Stack>
    </Stack>
  );
};
