import { ADD_CUSTOM_COLUMN_MODAL } from "core/constants";
import { useDispatch, useSelector } from "react-redux";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";
import { hideFormModal } from "core/store/entities/ModalForm/modalForm.actions";

export const useCustomColumnModal = () => {
  const open = useSelector(modalSelectors[ADD_CUSTOM_COLUMN_MODAL]);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideFormModal());
  };

  return {
    open,
    onClose: handleClose
  }

}