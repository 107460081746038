import { type Palette } from "@mui/material";

export const paletteTheme = {
  white: { main: "#ffffff", 100: "#F3F3F1" },
  black: "#000000",
  error: {
    main: "#E25081",
    50: "#FEF3F2",
    200: "#FECDCA",
    700: "#B42318"
  },
  warning: {
    main: "#ed6c02",
    50: "#FFFAEB",
    700: "#B54708"
  },
  success: {
    main: "#2e7d32",
    50: "#ECFDF7",
    200: "#ABEFD6",
    700: "#06763A"
  },
  gray: {
    50: "#F5F5F5",
    75: "#F8F8F7",
    100: "#DBDBDB",
    150: "#EAEAE6",
    200: "#D6D6D6",
    225: "#DADAD7",
    250: "#B8B7B5",
    300: "rgba(255, 255, 255, 0.2)",
    350: "#686764",
    400: "#1f2937",
    500: "#7D7C78",
    600: "#3C3C3A",
    650: "#91908D",
    700: "#344054",
    800: "#424242",
    950: "#030712"
  },
  pink: {
    50: "#ECD5D6",
    100: "#F2E4E3",
    200: "#F8C9CC",
    300: "#F5B7BB",
    400: "#EE8197",
    500: "#E25081",
    600: "#512439"
  },
  purple: {
    50: "#512439",
    100: "#EEE3EB",
    200: "#ECCAE3",
    300: "#E7BBE4",
    400: "#D28ED7",
    500: "#D28ED7"
  },
  green: {
    25: "#ecfdf7",
    50: "#C9DEDB",
    100: "#DFECEB",
    200: "#BFE8E3",
    300: "#A4DFD4",
    400: "#64C9A7",
    500: "#3DAE79",
    550: "#06763a",
    600: "#273F31"
  },
  yellow: {
    50: "#ECECD5",
    100: "#F8F8C9",
    200: "#F0F08F",
    300: "#E9D559"
  },
  blue: {
    50: "#EFF8FF",
    100: "#E5E8F5",
    200: "#B2DDFF",
    300: "#BAD0FC",
    400: "#76B3F9",
    500: "#0B97F4",
    600: "#1D414E",
    700: "#175CD3"
  }
};

export type CustomPalette = typeof paletteTheme & Palette;
