import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { CircularProgress } from "ui/components";
import { useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const WelcomeBlock = () => {
  const user = useSelector(userSelectors.user);
  const theme = useTheme();

  return (
    <Grid item xs={12} lg={8} xl={7.9} pb={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <Typography>
          {`Welcome ${user.firstName}! Here's a checklist to start sending. Please note that we have placed you on a trial account before upgrading your account. This free plan includes 1,000 emails to test our service.`}
        </Typography>
        <CircularProgress
          value={user.progress}
          bgColor={theme.palette.gray[100]}
          fillColor="black"
          textColor="black"
        />
      </Stack>
    </Grid>
  );
};
