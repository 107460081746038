import { Drawer } from "@mui/material";
import { useNavItems } from "./useNavItems";
import { useDefineMobileView, useSidebar } from "hooks";
import { DesktopNavbar } from "./DesktopNavbar";
import { SHOW_NAVIGATION } from "core/constants";

export const Navbar = () => {
  const isMobileView = useDefineMobileView();
  const { items } = useNavItems();
  const { isExpanded, onExpand } = useSidebar(SHOW_NAVIGATION);
  const children = <DesktopNavbar items={items} />

  if (isMobileView) {
    return (
      <>
        <Drawer anchor="left" open={isExpanded} onClose={() => onExpand(false)}>
          {children}
        </Drawer>
      </>
    );
  }

  return (children);
};
