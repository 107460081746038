import { Box, Grid } from "@mui/material";
import React from "react";
import { DownloadLogsText } from "./DownloadLogsText";
import { BounceLogsImportanceText } from "./BounceLogsImportanceText";
import { DownloadLogs } from "./DownloadLogs";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../core/store/entities/User/user.selectors";
import { Header } from "../../components";
import { generalContentPadding } from "ui/components/Layout";

export const LogFiles = () => {
  const isEmailUser = useSelector(userSelectors.isEmailUser);
  let children = (
    <>
      <Grid item container columnGap={6}>
        <Grid item container xs={10} sm={8} md={6} direction="column">
          <DownloadLogsText />
          <DownloadLogs />
        </Grid>
        <Grid item xs={12} lg={5}>
          <BounceLogsImportanceText />
        </Grid>
      </Grid>
    </>
  );
  if (isEmailUser) return <Grid container>{children}</Grid>;
  return (
    <Box>
      <Header title="Log files" />
      <Grid container sx={{ padding: generalContentPadding }}>
        {children}
      </Grid>
    </Box>
  );
};
