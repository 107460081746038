import { ChangePasswordForm, Dialog } from "ui/components";
import React from "react";
import { useChangePasswordModal } from "./useChangePasswordModal";

export const ChangePasswordModal = () => {
  const { open, onClose } = useChangePasswordModal();
  return (
    <Dialog open={open} onClose={onClose} paperStyles={{ width: "476px" }} title="Change password">
      <ChangePasswordForm onClose={onClose} />
    </Dialog>
  );
};
