import { InputAdornment, type TextFieldProps } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledInput } from "./styled";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";

type Props = {
  onSearch: (value: string) => void;
} & TextFieldProps;

export const SearchInput = ({ onSearch, value, ...props }: Props) => {
  const [inputValue, setInputValue] = useState(value);

  const searchDebounced = useDebouncedCallback((value: string) => {
    onSearch(value);
  }, 500);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    searchDebounced(newValue);
  };

  return (
    <StyledInput
      onChange={handleInputChange}
      value={inputValue}
      InputProps={{
        type: "search",
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      {...(props as TextFieldProps)}
    />
  );
};
