import React from "react";
import { Grid } from "@mui/material";
import { StepsContainer } from "./StepsContainer";
import { IntegrationsContainer } from "./IntegrationsContainer";

export const ConnectSMTP = () => {
  return (
    <>
      <Grid container columnSpacing={3}>
        <StepsContainer />
        <IntegrationsContainer />
      </Grid>
    </>
  );
};
