import { Box, styled, Typography } from "@mui/material";

export const DiffStatusContainer = styled(Typography)(({ theme, fixedWidth, inverted, up }) => ({
  alignItems: "center",
  borderRadius: "1rem",
  display: "flex",
  gap: "0.25rem",
  paddingBlock: "0.25rem",
  paddingInline: "0.5rem",
  width: "fit-content",
  ...(fixedWidth && {
    justifyContent: "space-between",
    width: "70px"
  }),
  ...(inverted
    ? {
        ...(up
          ? {
              color: theme.palette.gray[300]
            }
          : {
              color: theme.palette.green[550],
              backgroundColor: theme.palette.green[25]
            })
      }
    : {
        ...(up
          ? {
              color: theme.palette.green[550],
              backgroundColor: theme.palette.green[25]
            }
          : {
              color: theme.palette.gray[300]
            })
      })
}));

export const DiffIcon = styled(Box)(() => ({
  width: "25px",
  minWidth: "25px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left center",
  marginRight: "3px"
}));
