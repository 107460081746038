import { useRef } from "react";

const columnWidth = 240;

export const useScrollTable = () => {
  const tableRef = useRef<any>(null);
  const scrollRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft += columnWidth;
    }
  };
  const scrollLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = Math.max(0, tableRef.current.scrollLeft - columnWidth);
    }
  };
  return {
    tableRef,
    scrollRight,
    scrollLeft
  }

}