import React, { useEffect } from "react";
import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material";

import GoogleTagManager from "tracking/googleTagManager";
import { store } from "core/store";
import { theme } from "ui/theme";
import { RouterConfig } from "ui/navigation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CustomColumnFormModal } from "ui/components/Modals/CustomColumnFormModal";

function App() {
  useEffect(() => {
    GoogleTagManager.init();

    // Move the iframe with the Pipedrive chat placeholder
    // to the background to prevent it from covering other elements

    const intervalId = setInterval(() => {
      const iframe = document.getElementById("LeadboosterContainer");

      if (iframe) {
        iframe.style.setProperty("position", "relative", "important");
        iframe.style.setProperty("z-index", "-1", "important");
      }

      if (iframe) {
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <RouterConfig />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
