import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Dialog, Input, Select } from "ui/components";
import { Controller } from "react-hook-form";
import { useCustomerSupportForm } from "./useCustomerSupportForm";
import { messagesSelectors } from "core/store/entities/Messages/messages.selectors";
import { ActionButtons } from "../ActionButtons";
import { useSelector } from "react-redux";

export const CustomerSupport = () => {
  const initSubject = useSelector(messagesSelectors.subject);
  const { show, themes, errors, control, onSubmit, handleClose } =
    useCustomerSupportForm(initSubject);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      paperStyles={{ width: "500px" }}
      title="Choose your subject"
      actionButtons={
        <ActionButtons
          onCancel={handleClose}
          onConfirm={onSubmit}
          confirmTitle="Send message"
          isConfirmSubmit
        />
      }
    >
      <Box component="form" maxWidth="100%">
        <Stack spacing={1}>
          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <Select
                autoFocus={Boolean(initSubject) === false}
                name="subject"
                fullWidth
                options={themes}
                label="Subject"
                placeholder="Select subject"
                error={errors.subject}
                helperText={errors.subject?.[0]}
                {...field}
              />
            )}
          />
        </Stack>
        <Divider sx={{ opacity: "16%", my: 2.5 }} />
        <Stack>
          <Typography mb={2} variant="large">
            Send us a message
          </Typography>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <Input
                autoFocus={Boolean(initSubject)}
                multiline
                name="text"
                rows={6}
                label="Your message"
                placeholder="Your message"
                error={errors.text}
                helperText={errors.text?.[0]}
                {...field}
              />
            )}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};
