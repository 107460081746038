import {
  ADD_CREDIT_CARD_MODAL,
  CHANGE_PASSWORD_MODAL,
  EDIT_BILLING_INFO_MODAL,
  EDIT_PERSONAL_INFO_MODAL,
  SHOW_PAID_PLAN_BLOCK,
  SHOW_LIST_FORM,
  SHOW_NAVIGATION,
  CONTACT_FORM_MODAL,
  CUSTOM_COLUMNS_MODAL,
  ADD_CUSTOM_COLUMN_MODAL,
  DOWNLOAD_CONTACTS_MODAL
} from "core/constants";

const ENTITY = "modal";

export const modalSelectors = {
  [EDIT_BILLING_INFO_MODAL]: state => state[ENTITY][EDIT_BILLING_INFO_MODAL],
  [EDIT_PERSONAL_INFO_MODAL]: state => state[ENTITY][EDIT_PERSONAL_INFO_MODAL],
  [CHANGE_PASSWORD_MODAL]: state => state[ENTITY][CHANGE_PASSWORD_MODAL],
  [ADD_CREDIT_CARD_MODAL]: state => state[ENTITY][ADD_CREDIT_CARD_MODAL],
  [SHOW_PAID_PLAN_BLOCK]: state => state[ENTITY][SHOW_PAID_PLAN_BLOCK],
  [SHOW_LIST_FORM]: state => state[ENTITY][SHOW_LIST_FORM],
  [CONTACT_FORM_MODAL]: state => state[ENTITY][CONTACT_FORM_MODAL],
  [SHOW_NAVIGATION]: state => state[ENTITY][SHOW_NAVIGATION],
  [CUSTOM_COLUMNS_MODAL]: state => state[ENTITY][CUSTOM_COLUMNS_MODAL],
  [ADD_CUSTOM_COLUMN_MODAL]: state => state[ENTITY][ADD_CUSTOM_COLUMN_MODAL],
  [DOWNLOAD_CONTACTS_MODAL]: state => state[ENTITY][DOWNLOAD_CONTACTS_MODAL]
};
