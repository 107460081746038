import { Typography } from "@mui/material";
import { campaignsSelectors } from "core/store/entities/Campaigns/campaigns.selectors";
import { useSelector } from "react-redux";

export const CampaignsCount = () => {
  const totalCount = useSelector(campaignsSelectors.totalCount);
  return (
    <Typography fontWeight={360}>
      {totalCount} campaign{totalCount > 1 && "s"}
    </Typography>
  );
};
