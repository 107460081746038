import { Stack } from "@mui/material";
import { useDefineMobileView } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { SlideButtons } from "./SlideButtons";
import { Slide } from "./styled";

const gap = 16;

const Carousel = ({ items }) => {
  const isMobile = useDefineMobileView();
  const displayItemsCount = isMobile ? 1 : 2;
  const [currentSlide, setCurrentSlide] = useState(0);
  const wrapperRef = useRef(null);
  const [width, setWidth] = useState(0);
  const itemWidth = width / displayItemsCount - gap / 2;
  const slideWidth = itemWidth + gap;
  const lastIndex = items.length - displayItemsCount;

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
      }
    });

    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        resizeObserver.unobserve(wrapperRef.current);
      }
    };
  }, []);

  const handleNextSlide = () => {
    const nextIndex = Math.min(currentSlide + 1, lastIndex);
    const offset = nextIndex * slideWidth;
    wrapperRef.current.scrollLeft = offset;
    setCurrentSlide(nextIndex);
  };

  const handlePrevSlide = () => {
    const prevIndex = Math.max(currentSlide - 1, 0);
    const offset = prevIndex * slideWidth;
    wrapperRef.current.scrollLeft = offset;
    setCurrentSlide(prevIndex);
  };

  return (
    <>
      {items.length > 1 && (
        <SlideButtons
          onPreviousClick={handlePrevSlide}
          onNextClick={handleNextSlide}
          previousButtonDisabled={currentSlide > 0}
          nextButtonDisabled={currentSlide < lastIndex}
        />
      )}
      <Stack
        alignItems="center"
        direction="row"
        gap={gap / 8}
        sx={{
          position: "relative",
          width: "100%"
        }}
      >
        <Stack
          ref={wrapperRef}
          sx={{ width: "100%", overflowX: "hidden", scrollBehavior: "smooth" }}
        >
          <Stack direction="row" flexWrap="nowrap" gap={gap / 8}>
            {items.map((item, index) => (
              <Slide key={index} itemWidth={itemWidth}>
                {item}
              </Slide>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Carousel;
