import { Menu, MenuItem, styled } from "@mui/material";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.remScale[6],
    borderRadius: theme.remScale[8],
    border: "1px solid #EAEAE6",
    minWidth: 160,
    boxShadow: "0px 12px 16px -4px rgba(28, 27, 23, 0.08)"
  },
  "& .MuiList-root": {
    paddingBlock: theme.remScale[4],
    paddingInline: theme.remScale[6]
  }
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.gray[350],
  borderRadius: theme.remScale[6],
  padding: theme.remScale[10],
  cursor: "pointer",
  "& > *": {
    marginRight: "10px"
  },
  "&:hover": {
    color: theme.palette.black,
    backgroundColor: "#00000010"
  }
}));
