import { HasSubmenu, MenuItem } from "core/types";
import { Button, Menu } from "ui/components";
import { type MouseEvent, useState } from "react";
import { type ButtonProps } from "@mui/material";

type Props<ActionType extends string, SubmenuType extends string> = MenuItem<ActionType> &
  HasSubmenu<SubmenuType> &
  ButtonProps;
export const SubmenuButton = <ActionType extends string, SubmenuType extends string>({
  "aria-label": ariaLabel,
  id,
  icon,
  name,
  submenu,
  ...buttonProps
}: Props<ActionType, SubmenuType>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        startIcon={icon}
        key={id}
        onClick={handleClick}
        aria-label={ariaLabel}
        {...buttonProps}
      >
        {name}
      </Button>
      <Menu open={open} items={submenu} onClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};
