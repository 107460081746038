import { PayloadAction } from "@reduxjs/toolkit";
import { ImportFlowState } from "./importFlow.slice";
import { ContactFieldBase, MappedColumn } from "core/types";

export const importFlowReducers = {
  setSelectedFile: (state: ImportFlowState, { payload }: PayloadAction<File | null>) => {
    state.selectedFile = payload;
    if (payload === null) {
      delete state.selectedFileTextContent;
    }
  },
  setSelectedFileTextContent: (state: ImportFlowState, { payload }: PayloadAction<string>) => {
    state.selectedFileTextContent = payload;
  },
  setShouldReplaceContacts: (state: ImportFlowState, { payload }: PayloadAction<boolean>) => {
    state.shouldReplaceContacts = payload;
  },
  setSelectedColumnName: (state: ImportFlowState, { payload }: PayloadAction<string>) => {
    state.selectedColumnName = payload;
  },
  setInitFieldsMap: (
    state: ImportFlowState,
    { payload }: PayloadAction<Array<ContactFieldBase & MappedColumn>>
  ) => {
    if (state.addedNewFields) return;
    state.initColumnsFieldsMap = Object.fromEntries(
      payload.map((contactField) => [contactField.column_name, contactField])
    );
    state.columnsFieldsMap = Object.fromEntries(
      payload.map(({ mapped_column, column_name }) => [column_name, mapped_column])
    );
  },
  addColumnsFieldsMapping: (
    state: ImportFlowState,
    { payload }: PayloadAction<{ columnName: string; mappedColumn?: string }>
  ) => {
    state.columnsFieldsMap[payload.columnName] = payload.mappedColumn
  },
  resetColumnsFieldsMaps: (state: ImportFlowState) => {
    state.initColumnsFieldsMap = {};
    state.columnsFieldsMap = {};
    state.addedNewFields = false;
    delete state.selectedColumnName;
  }
};
