import { Box, Container, styled } from "@mui/material";

export const Wrapper = styled(Box)(() => ({
  flex: 1,
  maxHeight: "100%",
  height: "100%",
  overflowY: "auto"
}))

export const StyledContainer = styled(Container)(({ theme }) => ({
  paddingBlock: theme.remScale[112],
  [theme.breakpoints.down("sm")]: {
    paddingBlock: theme.remScale[80],
  }
}))

export const TabsWindowWrapper = styled(Box)(() => ({
  maxHeight: "100%",
  height: "100%",
  overflow: "hidden",
  flex: 2
}))

export const FieldsSelectorWrapper = styled(Box)(({ theme }) => ({
  paddingInline: theme.remScale[56],
  flex: 1,
  [theme.breakpoints.down("md")]: {
    paddingInline: 0
  }
}))