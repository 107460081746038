import { Menu } from "../Menu";
import { type MouseEvent, useState } from "react";
import type { HasSubmenu, MenuItem } from "core/types";
import { StyledMenuItem } from "./styled";

type Props<MenuItemType extends string, SubmenuItemType extends string> = {
  item: MenuItem<MenuItemType> & HasSubmenu<SubmenuItemType>;
  onClose: () => void;
};

export const Submenu = <MenuItemType extends string, SubmenuItemType extends string>({
  item,
  onClose
}: Props<MenuItemType, SubmenuItemType>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <>
      <StyledMenuItem onClick={handleClick}>
        {item.icon}
        {item.name}
      </StyledMenuItem>
      <Menu items={item.submenu} open={open} onClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};
