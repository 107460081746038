import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";

import { listSelectors } from "core/store/entities/List/list.selectors";
import { fetchList } from "core/store/entities/List/list.actions";
import { FullscreenSpinner } from "ui/components";
import { TabsWindow } from "./components/TabsWindow";
import { FieldsSelectorWrapper, StyledContainer, TabsWindowWrapper, Wrapper } from "./styled";
import { FieldsSelector } from "./components/FieldsSelector";
import { useFormEmbed } from "./useFormEmbed";
import { FormEmbedContextProvider } from "./context/FormEmbedContextProvider";

export const FormEmbed = () => {
  const { listParamId, isListInStore } = useFormEmbed();
  const dispatch = useDispatch();
  const fetchedSuccessful = useSelector(listSelectors.success);
  const error = useSelector(listSelectors.error);

  useEffect(() => {
    if (!isListInStore) {
      dispatch(fetchList(listParamId));
    }
  }, [listParamId, isListInStore]);

  if (error) {
    console.error({ error });
  }

  if (!isListInStore || !fetchedSuccessful) {
    return <FullscreenSpinner />;
  }

  return (
    <FormEmbedContextProvider>
      <Wrapper>
        <StyledContainer>
          <Stack gap={7} flexDirection={{ xs: "column-reverse", md: "row" }}>
            <TabsWindowWrapper>
              <TabsWindow />
            </TabsWindowWrapper>
            <FieldsSelectorWrapper>
              <FieldsSelector />
            </FieldsSelectorWrapper>
          </Stack>
        </StyledContainer>
      </Wrapper>
    </FormEmbedContextProvider>
  );
};
