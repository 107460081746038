import { Add, NotInterested } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { CheckCircle } from "ui/assets/icons";
import { theme } from "ui/theme";
import { DashedCircleIcon } from "./styled";
import { ContactField } from "core/types";
import { useDispatch, useSelector } from "react-redux";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { setSelectedColumnName } from "core/store/entities/ImportFlow/importFlow.actions";
import { contactSelectors } from "core/store/entities/Contact/contact.selectors";
import { StyledTextButton } from "ui/screens/ContactsFileUpload/styled";
import { doNotImportKey } from "core/store/entities/ImportFlow/importFlow.selectors";

const reservedColumns = ["email", "status", "ip", "created"];
const doNotImportOption = () => ({
  label: (
    <Typography component="span" variant="body2">
      Do not import
    </Typography>
  ),
  value: doNotImportKey,
  icon: <NotInterested />
});
const addFieldNameOption = (onClick?: () => void) => ({
  label: (
    <StyledTextButton
      onClick={onClick}
      startIcon={<Add sx={{ color: theme.palette.black }} />}
      sx={{ fontWeight: 560, p: 0, fontSize: theme.remScale[15.75] }}
    >
      Add new field
    </StyledTextButton>
  ),
  value: "",
  divider: true
});
const renderOption = (label: string, column_name: string, divider?: boolean) => ({
  label: (
    <Typography fontWeight={360} component="span" variant="body2">
      {label}
    </Typography>
  ),
  value: column_name,
  disabled: reservedColumns.includes(column_name),
  divider
});
const renderSelectedOption = (label?: string) => (
  <>
    <CheckCircle
      style={{
        color: theme.palette.success[700],
        marginRight: "8px",
        fontSize: "20px"
      }}
    />
    <Typography fontWeight={460} component="span" variant="body2">
      {label}
    </Typography>
  </>
);

const renderAddFieldName = () => {
  return (
    <>
      <DashedCircleIcon borderColor={theme.palette.error[700]} />
      <Typography
        component="span"
        variant="inherit"
        sx={{ ml: 1, color: theme.palette.error[700] }}
      >
        Add field name
      </Typography>
    </>
  );
};
const renderDoNotImport = () => {
  return (
    <>
      <NotInterested
        style={{
          color: theme.palette.gray[500],
          marginRight: "8px",
          fontSize: "20px"
        }}
      />
      <Typography
        component="span"
        variant="inherit"
        style={{
          color: theme.palette.gray[500]
        }}
      >
        Do not import
      </Typography>
    </>
  );
};

const fieldsOptions = (contactFields: ContactField[], onAddFieldName: () => void) => [
  doNotImportOption(),
  ...(contactFields ?? []).map(({ label, column_name }, index) =>
    renderOption(label, column_name, index === 0)
  ),
  addFieldNameOption(onAddFieldName)
];

export const useRenderCustomColumnSelect = (columnName: string) => {
  const dispatch = useDispatch();
  const contactFields = useSelector(contactSelectors.contactFields);

  const onAddFieldName = () => {
    dispatch(setSelectedColumnName(columnName));
    dispatch(showFormModal({ id: null, type: "customColumn" }));
  };
  const options = fieldsOptions(contactFields ?? [], onAddFieldName);
  const renderSelectedValue = (value?: string) => {
    if (!value) {
      return renderAddFieldName();
    }
    if (value === doNotImportKey) {
      return renderDoNotImport();
    }
    const label = contactFields?.find(field => field.column_name === value)?.label;
    return renderSelectedOption(label);
  };

  return { renderSelectedValue, options };
};
