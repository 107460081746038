import { Stack, Typography } from "@mui/material";
import { EditButton } from "ui/components";

const DUMMY_DATA = [];

export const BillingInfoBlock = ({ data = DUMMY_DATA, divider, spacing, handleEdit }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      overflow="auto"
      gap={1}
      sx={{
        color: "gray.600"
      }}
    >
      <Stack flex={1} spacing={spacing} gap={{ xs: 2, sm: 0.5 }} divider={divider}>
        {data.map((item, index) => (
          <Stack key={index} direction={{ xs: "column", sm: "row" }}>
            <Typography
              flex={1}
              fontWeight={360}
              paddingRight="10px"
              sx={{
                color: theme => theme.palette.gray[600]
              }}
            >
              {item.title}
            </Typography>
            <Stack flex={5}>
              {item.values.map((value, index) => (
                <Typography key={index} fontWeight={560}>
                  {value}
                </Typography>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <EditButton onClick={handleEdit} />
    </Stack>
  );
};
