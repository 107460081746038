import { RootState } from "core/store/reducers";
import { createSelector } from "reselect";

const selectSelf = (state: RootState) => state.lists;
const selectLists = createSelector(selectSelf, listsState => listsState.lists);
const selectPagination = createSelector(selectSelf, listsState => listsState.pagination);

export const listsSelectors = {
  errors: createSelector(selectSelf, listsState => listsState.errors),
  lists: selectLists,
  listsCount: createSelector(selectPagination, pagination => pagination?.total_count ?? 0),
  listsLoading: createSelector(selectSelf, listsState => listsState.listsLoading),
  listsLoadingFailed: createSelector(selectSelf, listsState => listsState.listsLoadingFailed),
  totalPages: createSelector(selectPagination, pagination => pagination?.total_pages ?? 1),
  updateListSuccess: createSelector(selectSelf, listsState => listsState.updateListSuccess)
};
