// hook to refetch list when any list page gets refreshed or id in path gets changed

import { fetchList } from "core/store/entities/List/list.actions";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useListRefetch = () => {
  const dispatch = useDispatch();
  const { list_id } = useParams();
  const fetchedSuccessful = useSelector(listSelectors.success);
  const error = useSelector(listSelectors.fetchError);
  const loading = useSelector(listSelectors.loading);
  const isLoading = loading && !fetchedSuccessful;

  useEffect(() => {
    if (!!list_id) {
      dispatch(fetchList(list_id));
    }
  }, [list_id]);

  if (error) {
    console.error(error);
    return { error, isLoading: false };
  }

  return { isLoading, error: null }
}