import { Stack, Typography, Box, Tooltip } from "@mui/material";
import { TrashBin } from "ui/assets/icons";
import { Image, LinearProgress } from "ui/components";
import { SecondaryText, StyledIconButton, Wrapper } from "./styled";
import { formatBytes } from "./formatBytes";
import { Error, ErrorOutline } from "@mui/icons-material";

type Props = {
  progressValue: number;
  fileSize?: number;
  fileName: string;
  onDelete?: () => void;
  errorState?: boolean;
};

export const FileUploadProgress = ({
  errorState,
  progressValue,
  fileSize,
  fileName,
  onDelete
}: Props) => {
  return (
    <Wrapper direction="row" gap={1.75}>
      <Image name="csvFileIcon" width={40} height={40} />
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography>
            {fileName}{" "}
            {errorState && (
              <Tooltip title="Error while reading file" placement="top">
                <ErrorOutline
                  sx={{ color: theme => theme.palette.error.main, fontSize: "inherit" }}
                />
              </Tooltip>
            )}
          </Typography>

          {!!onDelete && (
            <Tooltip title="Cancel file processing" id="cancel-export-tooltip">
              <StyledIconButton aria-labelledby="cancel-export-tooltip" onClick={onDelete}>
                <TrashBin />
              </StyledIconButton>
            </Tooltip>
          )}
        </Stack>
        {fileSize && <SecondaryText fontWeight={360}>{formatBytes(fileSize)}</SecondaryText>}
        <Stack direction="row" alignItems="center">
          <LinearProgress value={progressValue} />
          <Box sx={{ ml: 1.5, width: 34 }}>
            <SecondaryText fontSize={theme => theme.remScale[12.75]}>{`${Math.round(
              progressValue
            )}%`}</SecondaryText>
          </Box>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
