import { importFlowActions } from "./importFlow.slice";

export const {
  setSelectedFile,
  setSelectedFileTextContent,
  setShouldReplaceContacts,
  setSelectedColumnName,
  addColumnsFieldsMapping,
  setInitFieldsMap,
  resetColumnsFieldsMaps
} = importFlowActions;
