import { useDispatch } from "react-redux";
import { StyledDuplicateIcon } from "./style";
import { duplicateCampaign } from "core/store/entities/Campaigns/campaigns.actions";
import { DuplicateIcon } from "ui/components/Icons/DuplicateIcon";
import { theme } from "ui/theme";

type Props = {
  campaignId: string;
  campaignName: string;
};

export const DuplicateCampaignButton = ({ campaignId, campaignName }: Props) => {
  const dispatch = useDispatch();
  const handleDuplicate = () => {
    dispatch(duplicateCampaign(campaignId));
  };
  return (
    <StyledDuplicateIcon
      onClick={handleDuplicate}
      aria-label={`Duplicate ${campaignName} Campaign`}
      iconStyle={{
        fontSize: theme.remScale[12.75]
      }}
    />
  );
};
