import { Button, styled } from "@mui/material";

export const StyledTextButton = styled(Button)(({ theme, disabled, underline, type }) => ({
  ...theme.typography.body1,
  color: theme.palette.black,
  display: "flex",
  alignItems: "center",
  textDecorationSkipInk: "none",
  textTransform: "none",
  borderRadius: 0,
  paddingBlock: theme.remScale[10],
  paddingInline: theme.remScale[14],
  "& span": {
    marginRight: theme.remScale[6]
  },
  "&:hover": {
    backgroundColor: "transparent"
  },
  ...(disabled && {
    opacity: 0.25
  }),
  ...(underline && {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent"
    }
  }),
  ...(type === "primary" && {
    color: theme.palette.blue[500],
    "& span": {
      color: theme.palette.blue[500]
    }
  })
}));
