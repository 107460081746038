export const typographyTheme = {
  h1: {
    fontFamily: "Beatrice !important",
    lineHeight: "2.5rem",
    letterSpacing: "-1px",
    fontSize: "2.25rem",
    fontWeight: 600,
    "@media (max-width:950px)": {
      fontSize: "2rem"
    },
    "@media (max-width:650px)": {
      fontSize: "1.5rem"
    }
  },
  h2: {
    fontFamily: "Beatrice !important",
    lineHeight: "2rem",
    letterSpacing: "-1.25%",
    fontSize: "1.5rem",
    fontWeight: 560
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 560
  },
  h4: {
    lineHeight: "1.5rem",
    fontSize: "1rem",
    fontWeight: 700
  },
  h5: {
    fontSize: "1.078125rem",
    letterSpacing: -0.3,
    fontWeight: 560
  },
  h6: {
    fontSize: "0.984375rem",
    fontWeight: 560
  },
  body1: {
    fontSize: "0.890625rem", // 14.25px
    lineHeight: "1.25rem",
    letterSpacing: "0.12px",
    fontWeight: 460
  },
  body2: {
    fontSize: "0.984375rem" // 15.75px
  },
  small: {
    fontSize: "0.75rem", // 12px
    lineHeight: "1.125rem" // 18px
  },
  large: {
    fontSize: "1.125rem", // 18px
    lineHeight: "1.125rem" // 18px
  },
  headlineXL: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "132px",
    letterSpacing: -1.2,
    fontSize: 120,
    fontWeight: 600
  },
  headlineL: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "78px",
    letterSpacing: -1.2,
    fontSize: 60,
    fontWeight: 600
  },
  headlineM: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "42px",
    letterSpacing: -0.3,
    fontSize: 30,
    fontWeight: 600
  },
  regularHeadlineM: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "42px",
    letterSpacing: -0.3,
    fontSize: 30,
    fontWeight: 400
  },
  headlineS: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "33px",
    letterSpacing: -0.25,
    fontSize: 20,
    fontWeight: 600
  },
  regularHeadlineS: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "33px",
    letterSpacing: -0.25,
    fontSize: 20,
    fontWeight: 400
  },
  headlineXS: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: 600
  },
  regularHeadlineXS: {
    fontFamily: "Beatrice",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: 400
  },
  bodyXL: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "32px",
    letterSpacing: 0.1,
    fontSize: 20,
    fontWeight: 400
  },
  bodyXLBold: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "40px",
    letterSpacing: 0.1,
    fontSize: 20,
    fontWeight: 500
  },
  bodyL: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 18,
    fontWeight: 400
  },
  bodyLBolder: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 18,
    fontWeight: 500
  },
  bodyLBold: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 18,
    fontWeight: 700
  },
  bodyM: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "25px",
    letterSpacing: 0.2,
    fontSize: "1rem",
    fontWeight: 400
  },
  bodyMBold: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "25px",
    letterSpacing: 0.2,
    fontSize: 16,
    fontWeight: 500,
    wordBreak: "break-word"
  },
  bodyS: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "22px",
    letterSpacing: 0.2,
    fontSize: 14,
    fontWeight: 400
  },
  bodySBold: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "22px",
    letterSpacing: 0.2,
    fontSize: 14,
    fontWeight: 500
  },
  bodyXS: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "19px",
    letterSpacing: 0.2,
    fontSize: 12,
    fontWeight: 400
  },
  menuItem: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "17px",
    letterSpacing: 0.1,
    fontSize: 17,
    fontWeight: 400
  },
  navLinkSmall: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: 400
  },
  sectionHeaderM: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "42px",
    letterSpacing: -0.8,
    fontSize: "38px",
    fontWeight: 400
  },
  sectionHeaderS: {
    fontFamily: "Geist",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: 0.1,
    fontSize: 20,
    fontWeight: 500
  },
  hint: {
    fontFamily: "Helvetica Neue",
    fontSize: "14px",
    lineHeight: "27px",
    fontWeight: 400,
    color: "#979797"
  }
};
