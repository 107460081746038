import { Table } from "ui/components";
import { useListsTable } from "./useListsTable";
import { fetchLists } from "core/store/entities/Lists/lists.actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const ListsTable = () => {
  const dispatch = useDispatch();
  const { headers, rows } = useListsTable();
  useEffect(() => {
    dispatch(fetchLists());
  }, []);

  return (
    <>
      <Table
        data={rows}
        headers={headers}
        wrapperStyles={{
          color: theme => theme.palette.gray[350]
        }}
      />
    </>
  );
};
