import { contactActions } from "./contact.slice";

export const {
  createContact,
  createContactFailed,
  createContactSuccess,

  updateContact,
  updateContactFailed,
  updateContactSuccess,

  resetContact
} = contactActions;
