import { useMemo } from "react";
import {
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  SETTINGS_LOG_FILES_SCREEN
} from "core/constants";
import { useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { getSettingsRoutes, SettingRoute } from "../../../../navigation/routes/settings";
import { disabledSettingsRoutesChecks } from "../../../../../helpers/disabledSettingsRoutesChecks";

const getSettingRouteDisabledCheck = (user: any) => (routeName: SettingRoute) => {
  return disabledSettingsRoutesChecks[routeName](user);
}

const getTabsItems = (isEmailUser: boolean) => [
  {
    id: "billing",
    name: "Account and billing",
    to: DASHBOARD_BILLING_SCREEN
  },
  {
    id: "sending-domains",
    name: "Sending domains",
    to: DASHBOARD_SENDING_DOMAINS_SCREEN
  },
  {
    id: "smtp-setup",
    name: "SMTP user",
    to: CONNECT_SMTP_SCREEN
  },
  {
    id: "api-setup",
    name: "API key",
    to: CONNECT_API_SCREEN
  },
  isEmailUser && {
    id: "log-files",
    name: "Log files",
    to: SETTINGS_LOG_FILES_SCREEN
  }
].filter(Boolean);

export const useTabsItems = () => {
  const user = useSelector(userSelectors.user);
  const isEmailUser: boolean = useSelector(userSelectors.isEmailUser);
  const items = useMemo(() => getTabsItems(isEmailUser), [isEmailUser]);
  const routes = useMemo(() => getSettingsRoutes(isEmailUser), [isEmailUser]);

  const getIsTabDisabled = getSettingRouteDisabledCheck(user)

  return {
    items,
    routes,
    getIsTabDisabled
  }
}