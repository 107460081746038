import { Skeleton, Stack } from "@mui/material";
import { Header } from "ui/components";
import { ListActionsSkeleton } from "./ListActions/ListActionsSkeleton";

export const ListHeaderSkeleton = () => {
  return (
    <>
      <Header
        sx={{
          alignItems: "flex-start"
        }}
        headerRight={<ListActionsSkeleton actionItems={[0, 1, 2]} />}
        title={
          <Stack
            direction={{ xs: "row", lg: "column" }}
            flexWrap="wrap"
            gap={{ xs: 1.5, md: 3.5, lg: 5 }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                width: { xs: 50, md: 80, lg: 100 },
                height: { xs: 40, md: 60, lg: 80 }
              }}
            />
            <Stack direction="row" alignItems="center">
              <Skeleton width={350} sx={{ fontSize: theme => theme.typography.h1.fontSize }} />
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
