import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid, Typography } from "@mui/material";
import { colors } from "./colors";
import { ColorSwatch } from "./ColorSwatch";

type Props = RadioGroupProps;

export const ColorSwatchPick = (props: Props) => {
  return (
    <FormControl>
      <Typography id="select-list-color-label" component={FormLabel}>
        Select color
      </Typography>
      <RadioGroup
        {...props}
        aria-labelledby="select-list-color-label"
        defaultValue={colors[0]}
        sx={{ pt: ".75rem" }}
      >
        <Grid container spacing={2}>
          {colors.map((color, index) => (
            <Grid item xs={12 / 4} key={index} sx={{ height: 72 }}>
              <Radio
                value={color}
                inputProps={{
                  "aria-label": "color"
                }}
                icon={<ColorSwatch color={color} />}
                checkedIcon={<ColorSwatch color={color} selected />}
                sx={{
                  width: "100%",
                  height: "100%",
                  p: 0
                }}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
