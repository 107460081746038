import { Box, Link, Typography } from "@mui/material";
import { ColoredBlock } from "../ColoredBlock";
import { MoreMenu } from "ui/components";
import { useDispatch, useSelector } from "react-redux";
import { listsSelectors } from "core/store/entities/Lists/lists.selectors";
import type { ListRecordApi, TableColumn, TableHeaderCell, TableRow } from "core/types";
import { LISTS_SCREEN } from "core/constants";
import { formatDate } from "helpers/date";
import { Link as RouterLink } from "react-router-dom";
import { useListsTableMenuItems } from "./useListsTableMenuItems";
import { useCallback } from "react";
import { resetContacts } from "core/store/entities/Contacts/contacts.actions";

type HeaderKey = keyof Omit<ListRecordApi, "id" | "description" | "color">;

const orderedHeaderKeys: Array<HeaderKey> = [
  "label",
  "subscribers_count",
  "unsubscribes_count",
  "bouncers_count",
  "created_at"
];

const headerCells: Record<HeaderKey, TableHeaderCell> = {
  label: {
    name: "Name",
    align: "left"
  },
  created_at: {
    name: "Created",
    align: "left"
  },
  subscribers_count: {
    name: "Subscribers",
    align: "left"
  },
  unsubscribes_count: {
    name: "Unsubscribers",
    align: "left"
  },
  bouncers_count: {
    name: "Bouncers",
    align: "left"
  }
};

const headerKeyColumnMap = (
  list: ListRecordApi,
  onListClick?: () => void
): Record<HeaderKey, TableColumn> => ({
  label: {
    align: "left",
    content: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1
        }}
      >
        <ColoredBlock
          backgroundColor={list.color}
          sx={{
            width: 20,
            height: 20
          }}
        />
        <Link
          variant="body1"
          component={RouterLink}
          sx={{ color: theme => theme.palette.black, "&:not(:hover)": { textDecoration: "none" } }}
          to={`${LISTS_SCREEN}/${list.id}`}
          onClick={onListClick}
        >
          {list.label}
        </Link>
      </Box>
    )
  },
  created_at: {
    align: "left",
    content: <Typography color="inherit">{formatDate(list.created_at, "YYYY-MM-DD")}</Typography>
  },
  subscribers_count: {
    align: "left",
    content: <Typography>{list.subscribers_count}</Typography>
  },
  unsubscribes_count: {
    align: "left",
    content: <Typography>{list.unsubscribes_count}</Typography>
  },
  bouncers_count: {
    align: "left",
    content: <Typography>{list.bouncers_count}</Typography>
  }
});

export const useListsTable = () => {
  const dispatch = useDispatch();
  const { getItems } = useListsTableMenuItems();
  const lists: ListRecordApi[] = useSelector(listsSelectors.lists);
  const generateColumns = (list: ListRecordApi): TableColumn[] => [
    ...orderedHeaderKeys.map(
      (key: HeaderKey) =>
        headerKeyColumnMap(list, () => {
          dispatch(resetContacts());
        })[key]
    )
  ];

  const generateRow = (list: ListRecordApi, moreMenu: TableColumn): TableRow => ({
    key: list.id,
    columns: generateColumns(list).concat(moreMenu ?? [])
  });

  const renderMoreMenu = useCallback(
    (menuFor: { id: string; name: string }): TableColumn => ({
      align: "right",
      content: (
        <MoreMenu
          aria-label={`Open context menu for ${menuFor.name} list`}
          menuFor={menuFor}
          iconStyle={{ fontSize: theme => theme.remScale[20] }}
          items={getItems(menuFor)}
        />
      )
    }),
    [getItems]
  );

  const rows: TableRow[] = lists?.map(list =>
    generateRow(list, renderMoreMenu({ id: list.id, name: list.label }))
  );
  const headers = [
    ...orderedHeaderKeys.map(headerKey => headerCells[headerKey]),
    { name: "", align: "left" }
  ];
  return {
    headers,
    rows
  };
};
