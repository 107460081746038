import { call, put, takeLatest } from "redux-saga/effects";

import { contactActions } from "./contact.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ListContactsServiceInstance } from "../List/list.saga";

function* createContactHandler({ payload }: PayloadAction<object>) {
  try {
    if (ListContactsServiceInstance) {
      yield call(ListContactsServiceInstance.createContact, payload);
      yield put(contactActions.createContactSuccess());
    }
  } catch (error) {
    yield put(contactActions.createContactFailed(error));
  }
}
function* updateContactHandler({ payload }: PayloadAction<{ id: string, data: object }>) {
  try {
    if (ListContactsServiceInstance) {
      yield call(ListContactsServiceInstance.editContact, payload.id, payload.data);
      yield put(contactActions.updateContactSuccess());
    }
  } catch (error) {
    yield put(contactActions.updateContactFailed(error));
  }
}

export default function* contactSaga() {
  yield takeLatest(contactActions.createContact.type, createContactHandler);
  yield takeLatest(contactActions.updateContact.type, updateContactHandler);
}
