import { useDefineMobileView } from "hooks";
import { MenuIcon } from "../Icons";
import { SHOW_NAVIGATION } from "core/constants";
import { showModal } from "core/store/entities/Modal/modal.actions";
import { useDispatch } from "react-redux";

export const HamburgerButton = () => {
  const dispatch = useDispatch();
  const isMobile = useDefineMobileView();

  if (!isMobile) return <></>;

  return <MenuIcon sx={{ color: "black" }} onClick={() => dispatch(showModal(SHOW_NAVIGATION))} />;
};
