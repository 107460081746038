import { contactSelectors } from "core/store/entities/Contact/contact.selectors";
import { importFlowSelectors } from "core/store/entities/ImportFlow/importFlow.selectors";
import { ContactFieldBase, MappedColumn } from "core/types";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const generateColumnNameFromLabel = (label: string) =>
  label.toLocaleLowerCase().split(" ").join("_");

export const useColumnsTableColumnsMap = (headerLabels: string[]) => {
  const contactFields = useSelector(contactSelectors.contactFields);

  const listColumns = Object.fromEntries(
    (contactFields ?? []).map(({ column_name, id, label }) => [
      column_name,
      {
        column_name,
        id,
        label,
        mapped_column: column_name
      }
    ])
  );

  const headerLabelsToColumns: Record<string, ContactFieldBase> = Object.fromEntries(
    headerLabels.map(label => {
      const columnName = generateColumnNameFromLabel(label);
      return [
        columnName,
        {
          column_name: columnName,
          id: uuidv4().toString(),
          label,
          mapped_column: listColumns[columnName]?.column_name
        }
      ];
    })
  );

  const finalColumns: Array<ContactFieldBase & MappedColumn> = Object.keys({
    ...headerLabelsToColumns,
    ...listColumns
  }).map(key => ({
    ...(headerLabelsToColumns[key] ?? listColumns[key])
  }));

  return finalColumns;
};
