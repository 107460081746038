export const remScaleTheme = {
  2: "0.125rem",
  4: "0.25rem",
  6: "0.375rem",
  8: "0.5rem",
  9: "0.5625rem",
  10: "0.625rem",
  12: "0.75rem",
  12.75: "0.796875rem",
  14: "0.875rem",
  14.25: "0.890625rem",
  15.75: "0.984375rem",
  16: "1rem",
  17: "1.0625rem",
  17.25: "1.078125rem",
  18: "1.125rem",
  18.75: "1.171875rem",
  20: "1.25rem",
  22: "1.375rem",
  24: "1.5rem",
  28: "1.75rem",
  30: "1.875rem",
  32: "2rem",
  38: "2.25rem",
  40: "2.5rem",
  48: "3rem",
  56: "3.5rem",
  80: "5rem",
  112: "7rem"
};

export type RemScale = typeof remScaleTheme;
