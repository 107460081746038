import { TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { TableHeaderCell } from "../../styled";
import React from "react";

export const TableHeader = ({ headers = [] }) => {
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => (
          <TableHeaderCell key={index} align={header.align}>
            {typeof header.name === "string" ? (
              <Typography variant="h6" component="p" fontSize={theme.remScale[12.75]}>
                {header.name}
              </Typography>
            ) : (
              header.name
            )}
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
