import { Stack, Step, Typography } from "@mui/material";
import {
  StepConnector,
  StepContent,
  StepIconComponent,
  StepLabel,
  StepperContainer
} from "./styled";
import { Divider } from "ui/components";

export const Stepper = ({ steps, firstStepDivider }) => {
  return (
    <StepperContainer
      orientation="vertical"
      connector={<StepConnector />}
      firstStepDivider={firstStepDivider}
    >
      {steps.map((step, index) => (
        <Step key={index} active>
          <StepLabel StepIconComponent={() => <StepIconComponent>{index + 1}</StepIconComponent>}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="large">{step.title}</Typography>
              <Typography variant="body2">{step.subTitle}</Typography>
            </Stack>
          </StepLabel>
          <StepContent>
            {firstStepDivider && index === 0 ? (
              <Divider orientation="horizontal" />
            ) : (
              <>{step.content}</>
            )}
          </StepContent>
        </Step>
      ))}
    </StepperContainer>
  );
};
