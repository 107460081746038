import { CheckCircle } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { StyledBox } from "./styled";
import { colors } from "../colors";

type Props = {
  color: typeof colors[number];
  selected?: boolean;
};

export const ColorSwatch = ({ color, selected }: Props) => {
  return (
    <StyledBox color={color}>
      {selected && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%"
          }}
        >
          <CheckCircle sx={{ fontSize: "inherit" }} />
        </Stack>
      )}
    </StyledBox>
  );
};
