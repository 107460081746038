import { Navigate } from "react-router-dom";
import {
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  DASHBOARD_STATISTICS_SCREEN,
  LOG_FILES_SCREEN,
  SETTINGS_SCREEN,
  SETUP_DOMAIN_SCREEN,
  LISTS_SCREEN,
  SETTINGS_LOG_FILES_SCREEN,
  LIST_EMBED_FORM,
  LIST_CONTACTS_IMPORT,
  LIST_CONTACTS_IMPORT_FIELDS_MAPPING,
  CAMPAIGNS_OVERVIEW_SCREEN
} from "core/constants";
import {
  Billing,
  ConnectAPI,
  ConnectSMTP,
  Dashboard,
  AppLayout,
  SettingsLayout,
  LogFiles,
  SendingDomains,
  SetupDomain,
  StatisticsDetails,
  Lists,
  List,
  FormEmbed,
  ContactsFieldMapping,
  ContactsFileUpload
} from "ui/screens";
import { NestedLayout } from "ui/components/Layout/NestedLayout";
import { Campaigns } from "ui/screens/Campaigns";

export const getPrivateRoutes = (isEmailUser: boolean) =>
  [
    isEmailUser && {
      path: `${LISTS_SCREEN}/:list_id/*`,
      element: <NestedLayout />,
      routes: [
        { path: LIST_EMBED_FORM, element: <FormEmbed /> },
        {
          path: LIST_CONTACTS_IMPORT,
          routes: [
            { index: true, element: <ContactsFileUpload /> },
            { path: LIST_CONTACTS_IMPORT_FIELDS_MAPPING, element: <ContactsFieldMapping /> }
          ]
        }
      ]
    },
    {
      path: "/",
      element: <AppLayout />,
      routes: [
        {
          index: true,
          element: <Navigate to={DASHBOARD_SCREEN} />
        },
        {
          path: SETTINGS_SCREEN,
          element: <SettingsLayout />,
          routes: [
            {
              path: DASHBOARD_SENDING_DOMAINS_SCREEN,
              element: <SendingDomains />
            },
            { path: `${SETUP_DOMAIN_SCREEN}/:domainId`, element: <SetupDomain /> },
            {
              path: DASHBOARD_BILLING_SCREEN,
              element: <Billing />
            },
            {
              path: CONNECT_API_SCREEN,
              element: <ConnectAPI />
            },
            {
              path: CONNECT_SMTP_SCREEN,
              element: <ConnectSMTP />
            },
            isEmailUser && {
              path: SETTINGS_LOG_FILES_SCREEN,
              element: <LogFiles />
            }
          ].filter(Boolean)
        },
        isEmailUser && {
          path: LISTS_SCREEN,
          element: <Lists />
        },
        isEmailUser && {
          exact: true,
          path: `${LISTS_SCREEN}/:list_id`,
          element: <List />
        },
        isEmailUser && {
          path: CAMPAIGNS_OVERVIEW_SCREEN,
          element: <Campaigns />
        },
        {
          path: DASHBOARD_SCREEN,
          element: <Dashboard />
        },
        {
          path: `${DASHBOARD_STATISTICS_SCREEN}/:domainId`,
          element: <StatisticsDetails />
        },
        {
          path: DASHBOARD_STATISTICS_SCREEN,
          element: <Dashboard />
        },
        !isEmailUser && {
          path: LOG_FILES_SCREEN,
          element: <LogFiles />
        }
      ].filter(Boolean)
    }
  ].filter(Boolean);
