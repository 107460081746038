import { campaignsSelectors } from "core/store/entities/Campaigns/campaigns.selectors";
import { useDispatch, useSelector } from "react-redux";
import { usePageUrlParam } from "hooks/useUrlParams";
import { fetchCampaigns } from "core/store/entities/Campaigns/campaigns.actions";

export const useCampaignsPagination = () => {
  const dispatch = useDispatch();
  const { page, onPageChange } = usePageUrlParam();
  const serverPage = useSelector(campaignsSelectors.currentPage);
  const totalPages = useSelector(campaignsSelectors.totalPages);

  const handlePageChange = (page: number) => {
    const goToPage = Math.min(serverPage ?? 1, page)
    if (goToPage <= 1) {
      onPageChange();
    } else {
      onPageChange("" + goToPage);
    }
    dispatch(fetchCampaigns());
  }

  return {
    page: +(page ?? 1),
    onChange: handlePageChange,
    totalPages
  }
}