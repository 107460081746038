import { outlinedInputClasses, styled } from "@mui/material";
import { Input } from "../../Inputs";

export const StyledInput = styled(Input)(({ theme }) => ({
  [`.${outlinedInputClasses.input} `]: {
    ...theme.typography.body2,
    letterSpacing: 0.75,
    fontWeight: 360,
    height: "1em",
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: theme.remScale[6],
    "&:not(:focus-visible)": {
      borderColor: "rgba(184, 183, 181, 1)",
    }
  },
}));