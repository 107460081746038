import { BillingSectionTitle } from "../BillingSectionTitle";
import { Alert, Grid } from "@mui/material";
import { EditBillingInformationModal } from "../BillingInformation/EditBillingInformationModal";
import { AddNewCreditCard } from "./AddNewCreditCard";
import { PaymentCard } from "./PaymentCard";
import { useSelector } from "react-redux";
import React from "react";
import { billingPaymentMethodsSelectors } from "core/store/entities/Billing/billing.selectors";
import Carousel from "ui/components/Carousel";
import { NO_BILLING_INFO_ERROR } from "core/constants";

// const DUMMY_CARDS = [
//   {
//     id: "dlkwevkjlqevjev",
//     name: "John Doe",
//     expirationDate: "07/22",
//     cardLastDigits: "9072",
//     brand: "Mastercard",
//     isSelected: true
//   },
//   {
//     id: "lqkengwegn",
//     name: "John Doe",
//     expirationDate: "07/22",
//     cardLastDigits: "7290",
//     brand: "Visa",
//     isSelected: false
//   }
// ];

export const PaymentMethods = () => {
  const paymentCards = useSelector(billingPaymentMethodsSelectors.data);
  const paymentMethodsErrors = useSelector(billingPaymentMethodsSelectors.error);
  const paymentMethodToDelete = useSelector(billingPaymentMethodsSelectors.paymentMethodToDelete);
  const showError = paymentMethodsErrors && paymentMethodsErrors.detail !== NO_BILLING_INFO_ERROR;

  return (
    <>
      <BillingSectionTitle title="Payment method" />
      <Grid container item lg={7.9} direction="row" gap={2}>
        {showError && (
          <Grid container item>
            <Alert variant="filled" severity="error">
              {paymentMethodsErrors?.detail}
            </Alert>
          </Grid>
        )}
        <Carousel
          items={[
            ...paymentCards.map(card => (
              <PaymentCard
                brand={card.brand}
                cardLastDigits={card.cardLastDigits}
                expirationDate={card.expirationDate}
                id={card.id}
                isDeleteDisabled={paymentCards.length === 1 || card.isSelected}
                isLoading={card.id === paymentMethodToDelete}
                isSelected={card.isSelected}
                key={card.id}
                name={card.name}
              />
            )),
            <AddNewCreditCard key="add-card" />
          ]}
        />
      </Grid>
      <EditBillingInformationModal />
    </>
  );
};
