import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import React from "react";
import {
  FeedbackPopup,
  FullscreenSpinner,
  Navbar,
  CustomerSupport,
  UpgradePlan,
  UpgradePlanConfirmation,
  ListFormModal,
  ContactFormModal,
  CustomColumnsModal,
  CustomColumnFormModal,
  DownloadContactsModal
} from "../../.";
import { useUserInitialization } from "hooks";

export const generalHeaderPadding = {
  xs: "8px 10px 8px 10px",
  sm: "28px 25px",
  md: "28px 40px 20px 40px",
  lg: "40px 40px 20px 40px"
};

export const generalContentPadding = {
  xs: "28px 10px 100px 10px",
  sm: "28px 25px",
  md: "20px 40px 28px 40px",
  lg: "20px 40px 40px 40px"
};

export const AppLayout = () => {
  const { user } = useUserInitialization();

  if (!user) return <FullscreenSpinner />;

  return (
    <Stack minHeight="100vh" flexDirection="row">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxHeight: "100vh",
          overflow: "auto"
        }}
      >
        <Outlet />
      </Box>
      <CustomerSupport />
      <UpgradePlan />
      <UpgradePlanConfirmation />
      <FeedbackPopup />
      <ListFormModal />
      <ContactFormModal />
      <CustomColumnsModal />
      <DownloadContactsModal />
      <CustomColumnFormModal />
    </Stack>
  );
};
