import { Grid, Box } from "@mui/material";
import { ContactsCount } from "../ContactsCount";
import { generalContentPadding } from "ui/components/Layout";
import { ContactsSearchInput } from "../ContactsSearchInput";
import { ContactsPagination } from "../ContactsPagination";
import { ContactsTableDisplay } from "../ContactsTableDisplay";
import { CustomColumnsButton } from "../CustomColumnsButton";
import { ExportButton } from "../ExportButton";
import { useContactsTable } from "./useContactsTable";

export const ContactsTable = () => {
  const { message } = useContactsTable();
  return (
    <Grid container rowSpacing="2.5rem" sx={{ padding: generalContentPadding }}>
      <Grid item xs={12}>
        <ContactsCount />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" gap={2}>
          <ContactsSearchInput />
          <CustomColumnsButton />
          <ExportButton />
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <ContactsTableDisplay emptyText={message} />
      </Grid>
      <Grid item container xs={12}>
        <ContactsPagination />
      </Grid>
    </Grid>
  );
};
