import { useDispatch, useSelector } from "react-redux";
import { CHANGE_PASSWORD_MODAL } from "core/constants";
import { hideModal, showModal } from "core/store/entities/Modal/modal.actions";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";

export const useChangePasswordModal = () => {
  const dispatch = useDispatch();
  const isChangePasswordModalOpen = useSelector(modalSelectors[CHANGE_PASSWORD_MODAL]);

  const onClose = () => {
    dispatch(hideModal(CHANGE_PASSWORD_MODAL));
  };
  const onShow = () => {
    dispatch(showModal(CHANGE_PASSWORD_MODAL));
  };

  return {
    onClose,
    onShow,
    open: isChangePasswordModalOpen
  };
};
