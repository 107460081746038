import { call, put, takeLatest, select } from "redux-saga/effects";
import * as userActions from "./user.actions";
import { UserService } from "core/api";
import { push } from "redux-first-history";
import {
  ADMIN_TOKEN,
  CREATE_ACCOUNT_CURRENT_STEP,
  CREATE_ACCOUNT_EMAIL,
  CREATE_ACCOUNT_TOKEN,
  DASHBOARD_SCREEN,
  GENERAL_TOKEN,
  LOGIN_SCREEN,
  LOGOUT_TOKEN,
  PROFILES,
  PROFILE_TOKEN,
  SELECTED_PROFILE,
  CHANGE_PASSWORD_MODAL,
  EDIT_PERSONAL_INFO_MODAL
} from "core/constants";
import { UserMapper } from "core/api";
import { showFeedbackPopup } from "./user.actions";
import { hideModal } from "../Modal/modal.actions";
import { setLoginData } from "./user.utils";
import { userSelectors } from "./user.selectors";
import { domainActions } from "../Domain/domain.slice";

function* createCustomerObjectHandler({ payload }) {
  try {
    const customerData = yield call(UserService.createCustomerObject, payload);
    yield put(userActions.createCustomerObjectSuccess({ email: payload.email }));
    localStorage.setItem(CREATE_ACCOUNT_TOKEN, customerData.token);
    localStorage.setItem(CREATE_ACCOUNT_EMAIL, payload.email);
    localStorage.setItem(CREATE_ACCOUNT_CURRENT_STEP, "2");
  } catch (error) {
    yield put(
      userActions.createCustomerObjectFailed(UserMapper.customerObjectErrorsToState(error))
    );
  }
}

function* createCustomerProfileHandler({ payload }) {
  try {
    yield call(UserService.createCustomerProfile, UserMapper.customerProfileToApi(payload));
    yield put(userActions.createCustomerProfileSuccess());
    localStorage.clear();
    yield put(userActions.login({ username: payload.email, password: payload.password }));
  } catch (error) {
    yield put(
      userActions.createCustomerProfileFailed(UserMapper.customerProfileErrorsToState(error))
    );
  }
}

function* fetchMailingSystemsHandler() {
  try {
    const mailingSystems = yield call(UserService.fetchMailingSystems);

    // TODO: temporary solution, will be transferred to backend later
    const mailingSystemsResults = mailingSystems?.results || [];
    const sortPriority = { Custom: 1, Other: 2, default: 0 };
    const sortedMailingSystems = mailingSystemsResults.sort(
      (currentMailSystem, nextMailSystem) =>
        (sortPriority[currentMailSystem.name] || sortPriority.default) -
        sortPriority[nextMailSystem.name] || sortPriority.default
    );

    const mailingSystemsOptions =
      sortedMailingSystems?.map(mailingSystem => ({
        label: mailingSystem.name,
        value: mailingSystem.id
      })) || [];
    yield put(userActions.fetchMailingSystemsSuccess(mailingSystemsOptions));
  } catch (error) {
    yield put(userActions.fetchMailingSystemsFailed(error));
  }
}

function* loginHandler({ payload }) {
  try {
    const loginData = yield call(UserService.login, payload);
    const customerProfiles = setLoginData(loginData);

    yield put(userActions.loginSuccess(customerProfiles));
    yield put(push(DASHBOARD_SCREEN));
  } catch (error) {
    yield put(userActions.loginFailed(UserMapper.loginErrorsToState(error)));
  }
}

function* loginAsAdminHandler({ payload }) {
  const adminToken = payload;
  try {
    const login = yield call(UserService.loginAsAdmin, adminToken);

    const { customer_profiles = [], token } = login;
    const { customerprofile_token = "" } = customer_profiles[0] || "";

    if (customer_profiles.length) {
      localStorage.setItem(PROFILES, JSON.stringify(customer_profiles));
      localStorage.setItem(
        SELECTED_PROFILE,
        customer_profiles[0] ? JSON.stringify(customer_profiles[0]) : ""
      );
      localStorage.setItem(LOGOUT_TOKEN, token);
      localStorage.setItem(GENERAL_TOKEN, token);
      if (customer_profiles.length) {
        localStorage.setItem(PROFILE_TOKEN, customerprofile_token);
      }
      localStorage.setItem(ADMIN_TOKEN, adminToken);
      yield put(userActions.loginAsAdminSuccess(customer_profiles));
      yield put(push(DASHBOARD_SCREEN));
    } else {
      yield put(
        userActions.loginAsAdminFailed(
          "Activated customer profiles not found. Try to check the activation field if this user owns some profile."
        )
      );
    }
  } catch (error) {
    const errorMessage = error?.non_field_errors?.[0] || "Login as admin has failed.";
    yield put(userActions.loginAsAdminFailed(errorMessage));
  }
}

function* confirmEmailHandler({ payload }) {
  try {
    const loginData = yield call(UserService.confirmEmail, payload);
    const customerProfiles = setLoginData(loginData);
    yield put(userActions.loginSuccess(customerProfiles));
    yield put(push(DASHBOARD_SCREEN));
  } catch (error) {
    yield put(
      userActions.confirmEmailFailed("The email has not been confirmed. Please, try again.")
    );
  }
}

function* resendEmailHandler() {
  try {
    yield call(UserService.resendConfirmEmail);
    yield put(userActions.resendEmailSuccess());
    yield put(userActions.showFeedbackPopup(["Email has been sent."]));
  } catch (error) {
    yield put(userActions.resendEmailFailed());
    yield put(userActions.showFeedbackPopup(["Email has not been sent.", "Please try again."]));
  }
}

function* getUserHandler() {
  try {
    const userData = yield call(UserService.getUser);
    yield put(userActions.getUserSuccess(UserMapper.userDataToState(userData)));
  } catch (error) {
    yield put(userActions.getUserFailed(error));
  }
}

function* editUserHandler({ payload }) {
  try {
    const userData = UserMapper.editUserDataToRequest(payload);
    const editedUserData = yield call(UserService.editAccount, userData);
    yield put(userActions.editUserSuccess(UserMapper.editUserDataToState(editedUserData)));
    yield put(showFeedbackPopup(["Your personal information has been updated!"]));
    yield put(hideModal(EDIT_PERSONAL_INFO_MODAL));
  } catch (error) {
    yield put(userActions.editUserFailed(UserMapper.editUserErrorsToState(error)));
  }
}

function* resetPasswordHandler({ payload }) {
  try {
    yield call(UserService.resetPassword, payload);
    yield put(userActions.resetPasswordSuccess());
    yield put(push(LOGIN_SCREEN));
  } catch (error) {
    yield put(userActions.resetPasswordFailed(UserMapper.resetPasswordErrorsToState(error)));
  }
}

function* changePasswordOnResetHandler({ payload }) {
  try {
    const passwordData = UserMapper.changePasswordToApi(payload);
    yield call(UserService.changePasswordOnReset, passwordData);
    yield put(userActions.changePasswordOnResetSuccess());
    yield put(push(LOGIN_SCREEN));
  } catch (error) {
    yield put(
      userActions.changePasswordOnResetFailed(UserMapper.changePasswordErrorsToState(error))
    );
  }
}

function* changePasswordHandler({ payload }) {
  try {
    const passwordData = UserMapper.changePasswordToApi(payload);
    yield call(UserService.changePassword, passwordData);
    yield put(userActions.changePasswordSuccess());
    yield put(hideModal(CHANGE_PASSWORD_MODAL));
    yield put(showFeedbackPopup(["Your password has been changed."]));
  } catch (error) {
    yield put(userActions.changePasswordFailed(UserMapper.changePasswordErrorsToState(error)));
  }
}

function* getSmtpInfoHandler() {
  try {
    const smtpInfo = yield call(UserService.getSmtpInfo);
    yield put(userActions.getSmtpInfoSuccess(UserMapper.smtpInfoToState(smtpInfo)));
    yield put(userActions.getUser());
  } catch (error) {
    yield put(userActions.getSmtpInfoFailed(error));
  }
}

function* getReturnPathsHandler() {
  try {
    const returnPaths = yield call(UserService.getReturnPaths);
    yield put(
      userActions.getReturnPathsSuccess(UserMapper.returnPathsToState(returnPaths.results))
    );
  } catch (error) {
    yield put(userActions.getReturnPathsFailed(error));
  }
}

function* getWebInfoHandler() {
  try {
    const email = yield select(userSelectors.userEmail);
    const webInfo = yield call(UserService.getWebInfo, email);
    yield put(userActions.getWebInfoSuccess(UserMapper.webInfoToState(webInfo)));
    yield put(userActions.getUser());
  } catch (error) {
    yield put(userActions.getWebInfoFailed(error));
  }
}

function* logoutHandler() {
  try {
    yield call(UserService.logout);
    localStorage.clear();
    yield put(userActions.logoutSuccess());
    yield put(push(LOGIN_SCREEN));
  } catch (error) {
    yield put(userActions.logoutFailed(error));
  }
}

function* logoutAsAdminHandler() {
  const adminToken = yield select(userSelectors.adminToken);
  try {
    yield call(UserService.logoutAsAdmin, adminToken);
    localStorage.clear();
    yield put(userActions.logoutSuccess());
    yield put(push(LOGIN_SCREEN));
  } catch (error) {
    yield put(userActions.logoutFailed(error));
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.createCustomerObject.type, createCustomerObjectHandler);
  yield takeLatest(userActions.createCustomerProfile.type, createCustomerProfileHandler);
  yield takeLatest(userActions.fetchMailingSystems.type, fetchMailingSystemsHandler);
  yield takeLatest(userActions.login.type, loginHandler);
  yield takeLatest(userActions.loginAsAdmin.type, loginAsAdminHandler);
  yield takeLatest(userActions.logoutAsAdmin.type, logoutAsAdminHandler);
  yield takeLatest(userActions.confirmEmail.type, confirmEmailHandler);
  yield takeLatest(userActions.resendEmail.type, resendEmailHandler);
  yield takeLatest(
    [
      userActions.getUser.type,
      domainActions.deleteSendingDomainSuccess.type,
      domainActions.addSendingDomainSuccess.type
    ],
    getUserHandler
  );
  yield takeLatest(userActions.editUser.type, editUserHandler);
  yield takeLatest(userActions.resetPassword.type, resetPasswordHandler);
  yield takeLatest(userActions.changePasswordOnReset.type, changePasswordOnResetHandler);
  yield takeLatest(userActions.changePassword.type, changePasswordHandler);
  yield takeLatest(userActions.getSmtpInfo.type, getSmtpInfoHandler);
  yield takeLatest(userActions.getReturnPaths.type, getReturnPathsHandler);
  yield takeLatest(userActions.getWebInfo.type, getWebInfoHandler);
  yield takeLatest(userActions.logout.type, logoutHandler);
}
