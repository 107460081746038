import { status } from "core/constants/status";
import { ContactState } from "./contact.slice";
import { PayloadAction } from "@reduxjs/toolkit";

export const contactReducers = {
  createContact: (state: ContactState) => {
    state.createContactStatus = status.loading;
  },
  createContactSuccess: (state: ContactState) => {
    state.createContactStatus = status.success;
  },
  createContactFailed: (state: ContactState, { payload }: PayloadAction<unknown>) => {
    state.createContactStatus = status.error;
    state.error = payload;
  },
  updateContact: (state: ContactState) => {
    state.updateContactStatus = status.loading;
  },
  updateContactSuccess: (state: ContactState) => {
    state.updateContactStatus = status.success;
  },
  updateContactFailed: (state: ContactState, { payload }: PayloadAction<unknown>) => {
    state.updateContactStatus = status.error;
    state.error = payload;
  },
  resetContact: (state: ContactState) => {
    state.createContactStatus = status.idle;
    state.error = null;
    state.contact = null;
  }
};
