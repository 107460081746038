import { Stack, styled } from "@mui/material";
import { Button } from "ui/components";

export const StyledWrapperStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.white[100],
  border: "2px dashed",
  borderColor: theme.palette.gray[225],
  borderRadius: theme.remScale[6],
  paddingBlock: theme.remScale[24],
  color: theme.palette.gray[500]
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  color: theme.palette.black,
  "&:hover": {
    opacity: 0.5,
    backgroundColor: theme.palette.white.main
  }
}))