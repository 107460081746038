import { type ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pencil, TrashBin } from "ui/assets/icons";
import { deleteList, duplicateList } from "core/store/entities/Lists/lists.actions";
import { Code, ContentCopy } from "@mui/icons-material";
import { showFormModal } from "core/store/entities/ModalForm/modalForm.actions";
import { showFeedbackPopup } from "core/store/entities/User/user.actions";
import { ListsTableAction, MenuItem } from "core/types";
import { useNavigate } from "react-router-dom";
import { LIST_EMBED_FORM } from "core/constants";
import { listsSelectors } from "core/store/entities/Lists/lists.selectors";
import { useUrlParams } from "hooks/useUrlParams";
import { urlPageParamGoPrevious } from "helpers/urlPageParamGoPrevious";

const actionsNameMap: Record<ListsTableAction, string> = {
  edit: "Edit",
  delete: "Delete",
  duplicate: "Duplicate",
  embed_form: "Embed form"
};

const getAriaLabel = (labelFor: string, action: ListsTableAction) => {
  return `${actionsNameMap[action]} ${labelFor}`;
};

const getMenuItem =
  (triggeredByName: string) =>
  (
    action: ListsTableAction,
    onClick: () => void,
    icon?: ReactNode,
    divider?: boolean
  ): MenuItem<ListsTableAction> => ({
    id: action,
    icon,
    name: actionsNameMap[action],
    onClick,
    "aria-label": getAriaLabel(triggeredByName, action),
    divider
  });

export const useListsTableMenuItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalPages = useSelector(listsSelectors.totalPages);
  const getMenuHandlers = (triggeredBy: { id: string; name: string }) => ({
    handleDelete: () => {
      dispatch(
        showFeedbackPopup({
          content: [
            `Delete ${triggeredBy.name}?`,
            `Are you sure you want to permanently delete ${triggeredBy.name}?`
          ],
          onConfirm: () => {
            dispatch(deleteList(triggeredBy.id));
            urlPageParamGoPrevious(totalPages);
          }
        })
      );
    },
    handleDuplicate: () => {
      dispatch(duplicateList(triggeredBy.id));
    },
    handleEdit: () => {
      dispatch(showFormModal({ id: triggeredBy.id, type: "list" }));
    },
    handleEmbedForm: () => {
      navigate(`${triggeredBy.id}/${LIST_EMBED_FORM}`);
    }
  });

  const getItems = useCallback((triggeredBy: { id: string; name: string }) => {
    const menuItemGenerator = getMenuItem(triggeredBy.name);
    const menuHandlers = getMenuHandlers(triggeredBy);
    return [
      menuItemGenerator("edit", menuHandlers.handleEdit, <Pencil style={{ fontSize: "1rem" }} />),
      menuItemGenerator(
        "duplicate",
        menuHandlers.handleDuplicate,
        <ContentCopy style={{ fontSize: "1rem" }} />
      ),
      menuItemGenerator(
        "embed_form",
        menuHandlers.handleEmbedForm,
        <Code style={{ fontSize: "1rem" }} />
      ),
      menuItemGenerator(
        "delete",
        menuHandlers.handleDelete,
        <TrashBin style={{ fontSize: "1rem" }} />,
        true
      )
    ];
  }, []);
  return {
    getItems
  };
};
