import React, { useMemo } from "react";
import { Box, Grid, ListSubheader, MenuItem, Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { Controller } from "react-hook-form";

import { Button, Dialog, Image, Input, Select, RegularButton } from "ui/components";
import { useUpgradePlanForm } from "./useUpgradePlanForm";
import { StyledCustomPlanBox, StyledUpgradePlanBox } from "./styled";

export const UpgradePlan = () => {
  const {
    show,
    control,
    formState,
    upgradePlans,
    customPlans,
    countries,
    topCountries,
    sendUpgradePlanError,
    onSubmit,
    handleSubmit,
    handleClose,
    watch,
    setValue,
    resetField
  } = useUpgradePlanForm();
  const selectedPlan = watch("selectedPlan");
  const selectedCustomPlan = watch("selectedCustomPlan");

  const isCustomPlanSelected = useMemo(
    () => selectedCustomPlan && selectedCustomPlan === selectedPlan,
    [selectedPlan, selectedCustomPlan]
  );

  const handleSelectPlan = ({ title }) => {
    const value = selectedPlan === title ? "" : title;
    setValue("selectedPlan", value);
  };

  const handleSelectCustomPlan = () => {
    const value = selectedCustomPlan === selectedPlan ? "" : selectedCustomPlan;
    setValue("selectedPlan", value);
  };

  const handleChangeCustomPlan = ({ target }) => {
    if (selectedCustomPlan === selectedPlan && target?.value !== selectedCustomPlan)
      resetField("selectedPlan");

    setValue("selectedCustomPlan", target?.value);
  };

  return (
    <Dialog open={show} onClose={handleClose} paperStyles={{ width: "620px", maxWidth: "none" }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} maxWidth="100%">
        <Stack sx={{ padding: { xs: "0 20px 42px", sm: "0 48px 42px" } }}>
          <Stack spacing={2} mb={2}>
            <Typography variant="headlineM">Upgrade your plan</Typography>
            <Typography variant="bodyL">1. Select a plan</Typography>
          </Stack>
          <Grid container spacing={2}>
            {upgradePlans.map((item, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <StyledUpgradePlanBox
                  sx={{
                    marginRight: { xs: "0", sm: index % 2 ? "0" : "16px" },
                    mb: 2
                  }}
                  isSelected={selectedPlan === item.title}
                  isError={formState?.errors?.selectedPlan?.message && !selectedPlan}
                >
                  <Stack spacing={0.5}>
                    <Typography variant="headlineS">{item.title}</Typography>
                    <Stack>
                      <Typography variant="bodyM">{`${item.number_of_emails} emails / mo`}</Typography>
                      <Typography variant="bodyM">{`${item.IP_amount} ${
                        item.IP_amount > 1 ? "IP's" : "IP"
                      } included`}</Typography>
                    </Stack>
                    <Typography variant="headlineS">
                      {`€ ${item.cost} `}
                      <Typography variant="bodyM">/mo</Typography>
                    </Typography>
                  </Stack>
                  <Controller
                    name="selectedPlan"
                    control={control}
                    render={({ field: { value } }) => (
                      <Button
                        sx={{ mt: 2.5, height: 52, width: 105 }}
                        onClick={() => handleSelectPlan(item)}
                        id="selectedPlan"
                        bgColor={value === item.title ? "primary" : "default"}
                      >
                        {value === item.title ? <Check fontSize="medium" /> : "Select"}
                      </Button>
                    )}
                  />
                  <Box sx={{ position: "absolute", right: 26, bottom: 22 }}>
                    <Image name={item.icon} width={64} maxHeight={124} />
                  </Box>
                </StyledUpgradePlanBox>
              </Grid>
            ))}
          </Grid>
          <Stack>
            <StyledCustomPlanBox
              isSelected={isCustomPlanSelected}
              isError={formState?.errors?.selectedPlan?.message && !selectedPlan}
            >
              <Stack>
                <Typography variant="headlineS">Custom</Typography>
                <Typography variant="headlineS" alignSelf="flex-end" mt="-15px">
                  € --
                  <Typography variant="bodyM">/mo</Typography>
                </Typography>
              </Stack>
              <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }} mt={2}>
                <Controller
                  name="selectedCustomPlan"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="selectedCustomPlan"
                      label="Selected desired emails / mo"
                      name="selectedCustomPlan"
                      options={customPlans}
                      error={!!formState?.errors?.selectedCustomPlan?.message}
                      helperText={formState?.errors?.selectedCustomPlan?.message}
                      fullWidth
                      {...field}
                      onChange={handleChangeCustomPlan}
                    />
                  )}
                />
                <Button
                  sx={{ ml: { xs: 0, sm: 2.5 }, mt: { xs: 1, sm: 0 }, height: 52, minWidth: 105 }}
                  onClick={handleSelectCustomPlan}
                  id="selectedPlan"
                  bgColor={isCustomPlanSelected ? "primary" : "default"}
                >
                  {isCustomPlanSelected ? <Check fontSize="medium" /> : "Select"}
                </Button>
              </Stack>
            </StyledCustomPlanBox>
          </Stack>
          {formState?.errors?.selectedPlan?.message && !selectedPlan && (
            <Typography variant="bodyM" mt={2} color={theme => theme.palette.error.main}>
              Please select one of the plans
            </Typography>
          )}
          <Stack mt={3} mb={3}>
            <Typography mb={2}>2. Billing Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="username"
                      label="Name"
                      name="username"
                      error={!!formState?.errors?.username?.message}
                      helperText={formState?.errors?.username?.message}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      id="email"
                      label="Your business email"
                      name="email"
                      autoComplete="email"
                      error={formState?.errors?.email?.message}
                      helperText={formState?.errors?.email?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="country"
                      label="Country"
                      name="country"
                      options={[]}
                      error={!!formState?.errors?.country?.message}
                      helperText={formState?.errors?.country?.message}
                      MenuProps={{ sx: { height: 225 } }}
                      fullWidth
                      {...field}
                    >
                      <ListSubheader>Top countries</ListSubheader>
                      {topCountries.map(({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                      <ListSubheader>Countries</ListSubheader>
                      {countries.map(({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack gap={2}>
            <Button type="submit">Request upgrade</Button>
            <RegularButton type="button" onClick={handleClose}>
              Close modal
            </RegularButton>
          </Stack>
          {sendUpgradePlanError && (
            <Typography variant="bodyM" mt={2} color={theme => theme.palette.error.main}>
              Sorry, something went wrong. Please try again.
            </Typography>
          )}
          <Typography variant="bodyXS" mt={2}>
            We typically process your request within 24 hours. You wil receive an email to pay
            securely via Stripe.
          </Typography>
        </Stack>
      </Box>
    </Dialog>
  );
};
