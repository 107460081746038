import { modalReducers } from "./modal.reducers";
import {
  ADD_CREDIT_CARD_MODAL,
  CHANGE_PASSWORD_MODAL,
  EDIT_BILLING_INFO_MODAL,
  EDIT_PERSONAL_INFO_MODAL,
  SHOW_PAID_PLAN_BLOCK,
  CUSTOM_COLUMNS_MODAL,
  ADD_CUSTOM_COLUMN_MODAL,
  DOWNLOAD_CONTACTS_MODAL
} from "core/constants";

const { createSlice } = require("@reduxjs/toolkit");

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    [EDIT_BILLING_INFO_MODAL]: false,
    [EDIT_PERSONAL_INFO_MODAL]: false,
    [CHANGE_PASSWORD_MODAL]: false,
    [ADD_CREDIT_CARD_MODAL]: false,
    [SHOW_PAID_PLAN_BLOCK]: false,
    [CUSTOM_COLUMNS_MODAL]: false,
    [ADD_CUSTOM_COLUMN_MODAL]: false,
    [DOWNLOAD_CONTACTS_MODAL]: false
  },
  reducers: modalReducers
});

const modalActions = modalSlice.actions;
const modalReducer = modalSlice.reducer;

export { modalActions, modalReducer };
