import { userReducers } from "./user.reducers";
import { CREATE_ACCOUNT_CURRENT_STEP, CREATE_ACCOUNT_EMAIL } from "core/constants";

const { createSlice } = require("@reduxjs/toolkit");

const userSlice = createSlice({
  name: "user",
  initialState: {
    createAccountStep: +localStorage.getItem(CREATE_ACCOUNT_CURRENT_STEP) || 1,
    email: localStorage.getItem(CREATE_ACCOUNT_EMAIL) || "",
    isMailingSystemsLoading: false,
    mailingSystemsOptions: [],
    profiles: [],
    user: null,
    selectedProfile: {},
    isLoginAsAdminLoading: false,
    isLoginLoading: false,
    isAdminView: false,
    adminToken: null,
    errors: [],
    confirmEmailMessage: "",
    showFeedbackPopup: false,
    feedbackPopupContent: [],
    feedbackPopupOnConfirm: null,
    smtpInfo: [],
    isSmtpInfoLoading: false,
    returnPaths: [],
    isReturnPathsLoading: false,
    webInfo: {},
    isWebInfoLoading: false,
    errorMessage: "",
    isSignUpSubmitting: false
  },
  reducers: userReducers
});

const userActions = userSlice.actions;
const userReducer = userSlice.reducer;

export { userActions, userReducer };
