import { Button, styled } from "@mui/material";
import { MoreIconProps } from ".";

type StyledButtonProps = MoreIconProps;

export const StyledButton = styled(Button)<StyledButtonProps>(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.gray[50] : undefined,
  borderRadius: theme.remScale[6],
  color: "inherit",
  minWidth: 0,
  padding: theme.remScale[14]
}));
