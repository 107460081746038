import { TextFieldProps, SelectProps } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { ContactFieldDataType } from "core/types";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { StyledInput, StyledSelect } from "ui/components/Forms/ContactForm/styled";
import { Checkbox } from "ui/components/Inputs/Checkbox";
import { CheckboxGroup } from "ui/components/Inputs/CheckboxGroup";
import { isChecked } from "./isChecked";

export const fieldTypeInputMap: Record<ContactFieldDataType, ((props: any) => ReactNode) | null> = {
  boolean: (props: any) => (
    <Checkbox
      label={props.label}
      checked={isChecked(props.default) || isChecked(props.value) || props.checked}
      {...props}
    />
  ),
  character: (props: TextFieldProps) => <StyledInput {...props} />,
  choice: (props: SelectProps) => <StyledSelect {...props} />,
  multichoice: (props: SelectProps) => <CheckboxGroup {...props} />,
  date: (props: any) => <DatePicker {...props} value={dayjs(props.value)} format="DD-MM-YYYY" />,
  datetime: (props: any) => <DateTimePicker {...props} value={dayjs(props.value)} />,
  email: (props: TextFieldProps) => <StyledInput {...props} type="email" />,
  float: (props: TextFieldProps) => <StyledInput {...props} type="number" />,
  integer: (props: TextFieldProps) => <StyledInput {...props} type="number" />,
  pattern: (props: TextFieldProps) => <StyledInput {...props} />
};
