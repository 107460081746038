import React, { useEffect } from "react";
import { Header } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { Alert, Grid } from "@mui/material";
import { useStatisticsDetails } from "./useStatisticsDetails";
import { DomainOverall } from "./DomainOverall";
import { DeliveriesBounces } from "./DeliveriesBounces";
import { ISPTable } from "./ISPTable";
import { SelectDomain } from "./SelectDomain";

export const StatisticsDetails = () => {
  const { error, handleFetchDomainList } = useStatisticsDetails();
  useEffect(() => {
    handleFetchDomainList();
  }, []);
  return (
    <>
      <Header title="Delivery stats" />
      <Grid container gap={2.5} sx={{ padding: generalContentPadding }}>
        {!!error && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <SelectDomain />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 4 }}>
          <DomainOverall />
        </Grid>
        <Grid item xs={12}>
          <DeliveriesBounces />
        </Grid>
        <Grid item xs={12}>
          <ISPTable />
        </Grid>
      </Grid>
    </>
  );
};
