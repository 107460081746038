import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";

import { Overall } from "./Statistics/Overall";
import { Domains } from "./Statistics/Domains";
import { Header, Image } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { SetupFlow } from "./SetupFlow";
import { SHOW_PAID_PLAN_BLOCK } from "core/constants";
import { PaidPlanBlock } from "./PaidPlanBlock";
import { WelcomeBlock } from "./WelcomeBlock";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";

export const Dashboard = () => {
  const user = useSelector(userSelectors.user);
  const showPaidPlanBlock = useSelector(modalSelectors[SHOW_PAID_PLAN_BLOCK]);

  return (
    <Box>
      <Header title={
        <>Hi,
          <Typography variant="inherit" component="span" sx={{ textTransform: "capitalize" }}>
            {" "}
            {user.firstName}!
          </Typography>
        </>} />
      <Grid container sx={{ padding: generalContentPadding }}>
        {showPaidPlanBlock && <PaidPlanBlock />}
        {user.showSetupFlow && (
          <>
            <WelcomeBlock />
            <Grid container item>
              <SetupFlow />
              <Grid item xs={4.0}>
                <Image
                  name="onboardingVisual"
                  width={358}
                  height={362}
                  customStyles={{ marginBottom: "10px", marginLeft: "12px" }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {user.showStats && (
          <>
            <Overall />
            <Domains />
          </>
        )}
      </Grid>
    </Box>
  );
};
