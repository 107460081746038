import { useSelector } from "react-redux";
import { Button, Dialog, RegularButton } from "ui/components";
import { modalFormSelectors } from "core/store/entities/ModalForm/modalForm.selectors";
import { useCustomColumnModal } from "./useCustomColumnModal";
import { CustomColumnForm } from "./components/CustomColumnForm";
import { StyledActionButtons } from "./styled";
import { ContactFieldDataType } from "core/types";

export type CustomColumnFormType = {
  label: string;
  data_type: ContactFieldDataType | "empty_option";
  choices: Array<any>;
};

const formId = "custom_column_form";

export const CustomColumnFormModal = () => {
  const { open, onClose } = useCustomColumnModal();
  const selectedContactField = useSelector(modalFormSelectors.selectedContactField);
  const edit = !!selectedContactField?.id;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      paperStyles={{ width: "400px" }}
      title={edit ? `Rename ${selectedContactField.label} column` : "Add a new custom field"}
      subtitle={edit ? "Change name" : "Select a field type and name"}
      actionButtons={
        <StyledActionButtons>
          <RegularButton onClick={onClose} autoFocus aria-label="Close this popup" fullWidth>
            Cancel
          </RegularButton>
          <Button type="submit" form={formId} fullWidth>
            Save
          </Button>
        </StyledActionButtons>
      }
    >
      <CustomColumnForm selectedColumn={selectedContactField} formId={formId} />
    </Dialog>
  );
};
