import { Box, Grid } from "@mui/material";
import { Header } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { ListsTable } from "./ListsTable";
import { ListsPagination } from "./ListsPagination";
import { ListsCount } from "./ListsCount";
import { AddListButton } from "./AddListButton";
import { ListSearchInput } from "./ListSearchInput";
export const Lists = () => {
  return (
    <Box>
      <Header title="My Lists" headerRight={<AddListButton />} />
      <Grid container rowSpacing="2.5rem" sx={{ padding: generalContentPadding }}>
        <Grid item xs={12}>
          <ListsCount />
        </Grid>
        <Grid item xs={12} spacing={2}>
          <Box display="flex" gap={2}>
            <ListSearchInput />
          </Box>
        </Grid>
        <Grid item container xs={12}>
          <ListsTable />
        </Grid>
        <Grid item container xs={12}>
          <ListsPagination />
        </Grid>
      </Grid>
    </Box>
  );
};
