import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { Header } from "ui/components";
import { ColoredBlock } from "../../../Lists/ColoredBlock";
import { listSelectors } from "core/store/entities/List/list.selectors";
import { ListActionsMobile } from "./ListActionsMobile";
import { ListActionsItem, useListActionsItems } from "./useListActionsItems";
import { ListActions } from "./ListActions";
import { ListHeaderSkeleton } from "./ListHeaderSkeleton";

export const ListHeader = () => {
  const listLoading = useSelector(listSelectors.loading);
  const listLabel = useSelector(listSelectors.listLabel);
  const listColor = useSelector(listSelectors.listColor);
  const listId = useSelector(listSelectors.listId);
  const { items: actionItems } = useListActionsItems(listId, listLabel);

  if (listLoading) {
    return <ListHeaderSkeleton />;
  }

  return (
    <>
      <Header
        sx={{
          alignItems: "flex-start"
        }}
        headerRight={<ListActions actionItems={actionItems} />}
        title={
          <Stack
            direction={{ xs: "row", lg: "column" }}
            flexWrap="wrap"
            gap={{ xs: 1.5, md: 3.5, lg: 5 }}
          >
            <ColoredBlock
              backgroundColor={listColor}
              sx={{
                width: { xs: 50, md: 80, lg: 100 },
                height: { xs: 40, md: 60, lg: 80 }
              }}
            />
            <Stack direction="row" alignItems="center">
              <Typography variant="inherit">{listLabel}</Typography>
              <ListActionsMobile
                actionItems={actionItems}
                menuFor={{
                  id: listId || "",
                  name: listLabel || ""
                }}
              />
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
