import { Box, Typography } from "@mui/material";
import { AttachFile } from "ui/assets/icons";
import { StyledButton, StyledWrapperStack } from "./styled";
import { handleFileDrop } from "helpers/handleFileDrop";
import { FileReadProgress } from "../FileReadProgress";
import { useFileReader } from "./useFileReader";
import { useFileBrowseUpload } from "./useFileBrowseUpload";

export const FileBrowseUploader = () => {
  const { file, onFileChange, onFileClear } = useFileBrowseUpload();
  const { readFile, readingError, readingProgress } = useFileReader();

  // use this key to reset progress
  const readProgressKey = file ? file.name + " " + file.lastModified + " " + file.size : "";

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && (e.target.files?.length ?? 0 > 0)) {
      const uploadedFile = e.target.files[0];
      onFileChange(uploadedFile);
      if (uploadedFile) {
        readFile(uploadedFile);
      }
    }
  };
  return (
    <>
      <StyledWrapperStack
        justifyContent="center"
        alignItems="center"
        gap={2}
        onDrop={handleFileDrop(onFileChange)}
        onDragOver={e => {
          e.preventDefault();
        }}
      >
        <AttachFile
          style={{
            fontSize: 24
          }}
        />
        <Typography fontWeight={360}>Drop your .csv file here to upload...</Typography>
        <StyledButton component="label" htmlFor="browse-file-input">
          Browse files
        </StyledButton>
        <input
          type="file"
          id="browse-file-input"
          hidden
          accept=".csv"
          onChange={handleFileChange}
          onClick={event => {
            event.target.value = null;
          }}
        />
      </StyledWrapperStack>
      <Box sx={{ textAlign: "left", width: "100%" }}>
        {file && (
          <FileReadProgress
            key={readProgressKey}
            fileName={file.name}
            fileSize={file.size}
            onDelete={onFileClear}
            progress={readingProgress}
            error={readingError}
          />
        )}
      </Box>
    </>
  );
};
