import { Stack, Typography } from "@mui/material";
import React from "react";
import { useDomainOverall } from "./useDomainOverall";
import { OverallBlock } from "ui/components";
import { useSelector } from "react-redux";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";
import { OverallBlockSkeleton } from "ui/components/Blocks/OverallBlock/Skeleton";

export const DomainOverall = () => {
  const selectedDomain = useSelector(statisticsSelectors.selectedDomain);
  const {
    totalSent,
    totalSentDiff,
    percentDelivered,
    percentDeliveredDiff,
    percentBounced,
    totalBouncedDiff,
    isLoading
  } = useDomainOverall();
  return (
    <Stack spacing={4}>
      <Typography variant="h2">{selectedDomain?.[0]?.label}</Typography>
      {isLoading ? (
        <OverallBlockSkeleton />
      ) : (
        <OverallBlock
          totalSent={totalSent}
          totalSentDiff={totalSentDiff}
          percentDelivered={percentDelivered}
          percentDeliveredDiff={percentDeliveredDiff}
          percentBounced={percentBounced}
          totalBouncedDiff={totalBouncedDiff}
        />
      )}
    </Stack>
  );
};
