import {
  ADD_DOMAIN_STEP,
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  DASHBOARD_STATISTICS_SCREEN,
  TOTAL_STEPS,
  DASHBOARD_SCREEN,
  LOG_FILES_SCREEN,
  LISTS_SCREEN,
  SETUP_DOMAIN_SCREEN,
  CAMPAIGNS_OVERVIEW_SCREEN,
  CONTACTS_SCREEN,
  SETTINGS_LOG_FILES_SCREEN
} from "core/constants";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getSettingsRoutes } from "../../navigation/routes/settings";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { BarChart, BounceLogs, Home, Settings, UserSolid } from "ui/assets/icons";
import { Email } from "@mui/icons-material";

const commonItems = () => [
  {
    id: "dashboard",
    name: "Dashboard",
    to: DASHBOARD_SCREEN,
    Icon: Home,
    isActive: (location) => location.pathname === DASHBOARD_SCREEN
  }
];

const contactsMenu = (user) => ({
  id: "contacts",
  name: "Contacts",
  to: LISTS_SCREEN,
  Icon: UserSolid,
  isActive: (location) => location.pathname.startsWith(CONTACTS_SCREEN),
  isDisabled: user.currentStep < TOTAL_STEPS,
  items: [
    {
      id: "my-lists",
      name: "My Lists",
      to: LISTS_SCREEN,
      isActive: (location) => location.pathname.startsWith(LISTS_SCREEN),
      isDisabled: user.currentStep < TOTAL_STEPS
    }
  ]
});

const campaignsMenu = (user) => ({
  id: "campaigns",
  name: "Campaigns",
  to: CAMPAIGNS_OVERVIEW_SCREEN,
  Icon: Email,
  isActive: (location) => location.pathname.startsWith(CAMPAIGNS_OVERVIEW_SCREEN),
  isDisabled: user.currentStep < TOTAL_STEPS,
  items: [
    {
      id: "all-campaigns",
      name: "All Campaigns",
      to: CAMPAIGNS_OVERVIEW_SCREEN,
      isActive: (location) => location.pathname.startsWith(CAMPAIGNS_OVERVIEW_SCREEN),
      isDisabled: user.currentStep < TOTAL_STEPS
    }
  ]
}
)
const settingsMenu = (user, isEmailUser) => ({
  id: "settings",
  name: "Settings",
  to: DASHBOARD_BILLING_SCREEN,
  Icon: Settings,
  isActive: (location) => {
    const settingsRoutes = getSettingsRoutes(isEmailUser);
    return settingsRoutes.includes(location.pathname) || location.pathname.startsWith(SETUP_DOMAIN_SCREEN);
  },
  isDisabled: user.currentStep < ADD_DOMAIN_STEP,
  items: [
    {
      id: "billing",
      name: "Account and billing",
      to: DASHBOARD_BILLING_SCREEN,
      isActive: (location) => location.pathname === DASHBOARD_BILLING_SCREEN,
      isDisabled: user.currentStep < ADD_DOMAIN_STEP
    },
    {
      id: "sending-domains",
      name: "Sending domains",
      to: DASHBOARD_SENDING_DOMAINS_SCREEN,
      isActive: (location) => location.pathname.startsWith(DASHBOARD_SENDING_DOMAINS_SCREEN),
      isDisabled: !user.hasDomain
    },
    {
      id: "smtp-setup",
      name: "SMTP user",
      to: CONNECT_SMTP_SCREEN,
      isActive: (location) => location.pathname === CONNECT_SMTP_SCREEN,
      isDisabled: user.currentStep < TOTAL_STEPS
    },
    {
      id: "api-setup",
      name: "API key",
      to: CONNECT_API_SCREEN,
      isActive: (location) => location.pathname === CONNECT_API_SCREEN,
      isDisabled: user.currentStep < TOTAL_STEPS
    },
    isEmailUser && {
      id: "log-files",
      name: "Log files",
      to: SETTINGS_LOG_FILES_SCREEN,
      isActive: (location) => location.pathname === SETTINGS_LOG_FILES_SCREEN,
      isDisabled: user.currentStep <= TOTAL_STEPS
    }
  ].filter(Boolean)
});

const getItems = (user, isEmailUser) =>
  [
    ...commonItems(),
    isEmailUser && contactsMenu(user),
    isEmailUser && campaignsMenu(user),
    {
      id: "statistics",
      name: isEmailUser ? "Delivery stats" : "Statistics",
      to: `${DASHBOARD_STATISTICS_SCREEN}/1`,
      Icon: BarChart,
      isActive: (location) => location.pathname.startsWith(DASHBOARD_STATISTICS_SCREEN),
      isDisabled: user.currentStep <= TOTAL_STEPS
    },
    !isEmailUser && {
      id: "log-files",
      name: "Log files",
      to: LOG_FILES_SCREEN,
      Icon: BounceLogs,
      isActive: (location) => location.pathname === LOG_FILES_SCREEN,
      isDisabled: user.currentStep <= TOTAL_STEPS
    },
    settingsMenu(user, isEmailUser)
  ].filter(Boolean);

export const useNavItems = () => {
  const user = useSelector(userSelectors.user);
  const isEmailUser = useSelector(userSelectors.isEmailUser);

  const items = useMemo(
    () => getItems(user, isEmailUser),
    [user, isEmailUser]
  );

  return {
    items
  };
};
