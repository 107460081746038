import { Grid, Stack, Typography } from "@mui/material";
import DomainTable from "../DomainTable";
import { Spinner } from "ui/components";
import React from "react";
import { useSelector } from "react-redux";
import { DomainRequest } from "../DomainRequest";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const Domains = () => {
  const isStatsPerDomainTableLoading = useSelector(
    statisticsSelectors.isStatsPerDomainTableLoading
  );

  return (
    <Grid item xs={12} mt={6}>
      <Stack mb={2}>
        <Typography variant="h5" component="h2">
          Domains
        </Typography>
      </Stack>
      <DomainTable />
      {isStatsPerDomainTableLoading ? <Spinner /> : null}
      <DomainRequest />
    </Grid>
  );
};
