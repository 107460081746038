import { put, select, take, takeLatest } from "redux-saga/effects";

import { modalFormActions, ModalFormEntity } from "./modalForm.slice";
import { listActions } from "../List/list.slice";
import * as modalActions from "../Modal/modal.actions";
import { SHOW_LIST_FORM, CONTACT_FORM_MODAL, ADD_CUSTOM_COLUMN_MODAL } from "core/constants";
import { modalFormSelectors } from "./modalForm.selectors";
import { contactActions } from "../Contact/contact.slice";
import { importFlowActions } from "../ImportFlow/importFlow.slice";

const modalEntityMap: Record<ModalFormEntity, string> = {
  list: SHOW_LIST_FORM,
  contact: CONTACT_FORM_MODAL,
  customColumn: ADD_CUSTOM_COLUMN_MODAL
} as const;

function* showListFormModalHandler() {
  try {
    const listId = yield select(modalFormSelectors.entityId);
    const listFetched = yield select(modalFormSelectors.listFetched);
    const edit = !!listId;

    if (!edit) {
      yield put(listActions.resetList());
    }

    if (!listFetched && edit) {
      yield put(listActions.fetchList(listId));
      yield take(listActions.fetchListSuccess.type);
    }
    yield put(modalActions.showModal(SHOW_LIST_FORM));
  } catch (error) {
    console.error(error);
  }
}

function* showContactFormModalHandler() {
  try {
    const contactId = yield select(modalFormSelectors.entityId);
    const edit = !!contactId;

    if (!edit) {
      yield put(contactActions.resetContact());
    }

    yield put(modalActions.showModal(CONTACT_FORM_MODAL));
  } catch (error) {
    yield put(modalActions.hideModal(CONTACT_FORM_MODAL));
  }
}
function* showCustomFieldFormModalHandler() {
  try {
    yield put(modalActions.showModal(ADD_CUSTOM_COLUMN_MODAL));
  } catch (error) {
    yield put(modalActions.hideModal(ADD_CUSTOM_COLUMN_MODAL));
  }
}

function* showFormModalHandler() {
  try {
    const entityType: ModalFormEntity = yield select(modalFormSelectors.entityType);
    switch (entityType) {
      case "list":
        yield showListFormModalHandler();
        break;
      case "contact":
        yield showContactFormModalHandler();
        break;
      case "customColumn":
        yield showCustomFieldFormModalHandler();
        break;
    }
  } catch (error) {
    console.error(error);
    yield put(modalActions.hideModal(SHOW_LIST_FORM));
  }
}

function* hideFormModalHandler() {
  try {
    const entityType: ModalFormEntity = yield select(modalFormSelectors.entityType);
    yield put(modalActions.hideModal(modalEntityMap[entityType]));
  } catch (e) {
    console.error(e);
  }
}

export default function* modalFormSaga() {
  yield takeLatest(
    [
      listActions.createListSuccess,
      listActions.updateListSuccess,
      listActions.addNewContactsFieldSuccess,
      listActions.editContactsFieldSuccess,
      contactActions.createContactSuccess,
      contactActions.updateContactSuccess,
      modalFormActions.hideFormModal
    ],
    hideFormModalHandler
  );
  yield takeLatest(modalFormActions.showFormModal, showFormModalHandler);
}
