import React from "react";

import { formatPercentage } from "helpers/percentage";

import { DiffStatusContainer } from "./styled";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export const DiffStatus = ({ diff, fixedWidth = false, inverted }) => {
  const up = diff >= 0;
  const sign = diff !== 0 ? (diff > 0 ? "+" : "-") : "";
  const ArrowIcon = up ? ArrowUpward : ArrowDownward;
  return (
    <DiffStatusContainer fixedWidth={fixedWidth} inverted={inverted} up={up}>
      <ArrowIcon sx={{ fontSize: "inherit", color: "inherit" }} />
      {sign}
      {formatPercentage(Math.abs(diff))}%
    </DiffStatusContainer>
  );
};
