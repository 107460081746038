import { contactsReducers } from "./contacts.reducers";

import { createSlice } from "@reduxjs/toolkit";
import { status, type Status } from "core/constants/status";
import { Contact, PaginationApi } from "core/types";
import { listActions } from "../List/list.slice";
import { modalActions } from "../Modal/modal.slice";

import { DOWNLOAD_CONTACTS_MODAL } from "core/constants";

export type ContactsState = {
  contacts: Contact[] | null;
  fetchContactsStatus: Status;
  deleteContactsStatus: Status;
  exportContactsStatus: Status;
  exportContactsStarted?: boolean;
  error?: unknown;
  pagination: PaginationApi | null;
  searchTerm: string;
  exportProgress?: number;
  csvFileUrl?: string;
};

const initialState: ContactsState = {
  contacts: null,
  fetchContactsStatus: "idle",
  deleteContactsStatus: "idle",
  exportContactsStatus: "idle",
  pagination: null,
  searchTerm: "",
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: contactsReducers,
  extraReducers: builder => {
    builder
      .addCase(listActions.resetList, state => {
        state.contacts = null;
        state.fetchContactsStatus = status.idle;
        state.deleteContactsStatus = status.idle;
        state.pagination = null;
        state.searchTerm = "";
        state.exportContactsStatus = status.idle;
        state.exportContactsStarted = false;
        delete state.exportProgress;
        delete state.csvFileUrl;
        delete state.error;
      })
      .addCase(modalActions.hideModal, (state, { payload }) => {
        if (payload === DOWNLOAD_CONTACTS_MODAL) {
          contactsReducers.exportContactsCancel(state);
        }
      })
  }
});

const contactsActions = contactsSlice.actions;
const contactsReducer = contactsSlice.reducer;

export { contactsActions, contactsReducer };
