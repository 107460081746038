import { Button, styled } from "@mui/material";

export const StyledIconButton = styled(Button)(({ theme }) => ({
  minWidth: "fit-content",
  cursor: "pointer",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.gray[250],
  borderRadius: "0.375rem",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.black,
  padding: theme.remScale[9],
  "&:hover": {
    opacity: 0.9,
    borderColor: theme.palette.gray[250]
  }
}));
