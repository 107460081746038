import { Box, Container, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "ui/components";


export const ListLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.remScale[8],
  borderRadius: theme.remScale[6],
  backgroundColor: theme.palette.white.main,
  padding: theme.remScale[6],
  paddingRight: theme.remScale[8],
  border: "1px solid #DADAD7",
  alignSelf: "baseline",
  minWidth: "fit-content"
}))
export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.black,
  "&:hover": {
    textDecoration: "underline",
  }
}))