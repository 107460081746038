import { Grid } from "@mui/material";
import { RegularButton } from "../Buttons";

export const SlideButtons = ({
  onPreviousClick,
  onNextClick,
  previousButtonDisabled,
  nextButtonDisabled
}) => {
  return (
    <Grid container justifyContent="flex-end" gap={2}>
      <Grid item>
        <RegularButton
          onClick={onPreviousClick}
          disabled={!previousButtonDisabled}
          aria-label="Previous card"
        >
          Previous
        </RegularButton>
      </Grid>
      <Grid item>
        <RegularButton onClick={onNextClick} aria-label="Next card" disabled={!nextButtonDisabled}>
          Next
        </RegularButton>
      </Grid>
    </Grid>
  );
};
