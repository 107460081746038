import { IconButton, Stack, styled, Typography } from "@mui/material";

export const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  border: `1px solid ${theme.palette.gray[225]}`,
  borderRadius: theme.remScale[6],
  padding: theme.remScale[16],
  width: "100%"
}))
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: theme.remScale[14],
  color: theme.palette.black,
  margin: -8 // cancel out icon padding
}))
export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray[350]
}))